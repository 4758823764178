import { useQuery } from "react-query";
import { getTimecodes } from "../../api/browseassets";
import { TimecodeTag } from "../../types/types";

export function useTimecodes({
  ccid,
  level,
  tag,
}: {
  ccid: string | undefined;
  level: "titles" | "series";
  tag: TimecodeTag;
}) {
  return useQuery({
    enabled: Boolean(ccid),
    queryKey: ["getTimecodes", ccid, tag],
    queryFn: () => {
      return getTimecodes({ level, ccid: ccid as string, tag });
    },
  });
}
