import * as React from "react";
import { Alert, Card, CardContent, Skeleton, Typography } from "@mui/material";
import { useTasks } from "../hooks";
import { LevelType, Task } from "../types/types";
import { TaskSection } from "./TaskSection";

interface TaskListTileProps {
  ccid: string | undefined;
  level: LevelType;
}

export function TaskListTile({ ccid, level }: TaskListTileProps) {
  const { data: taskData, isLoading } = useTasks(ccid);

  if (isLoading) {
    return (
      <TaskListTileContainer>
        <Skeleton variant="rounded" animation="wave" height={800} />
      </TaskListTileContainer>
    );
  }

  if (!taskData) {
    return (
      <TaskListTileContainer>
        <Alert severity="error">
          There was an error retrieving the tasks for this production
        </Alert>
      </TaskListTileContainer>
    );
  }

  return (
    <TaskListTileContainer>
      <TaskSections tasks={taskData.tasks} level={level} />
    </TaskListTileContainer>
  );
}

function TaskListTileContainer({ children }: { children: React.ReactNode }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" paddingBottom="16px">
          Production Tasks
        </Typography>

        {children}
      </CardContent>
    </Card>
  );
}

function TaskSections({
  tasks,
  level,
}: {
  tasks: Array<Task>;

  level: LevelType;
}) {
  return (
    <>
      <TaskSection
        tasks={tasks}
        level={level}
        productionStage="preProduction"
      />
      <TaskSection tasks={tasks} level={level} productionStage="inProduction" />
      <TaskSection
        tasks={tasks}
        level={level}
        productionStage="postProduction"
      />
    </>
  );
}
