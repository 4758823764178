import {
  Autocomplete,
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Divider,
  Typography,
  LinearProgress,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormHeader, ModalWrapper } from "../../components";
import {
  useMetadataDropdownNew,
  useRiskNew,
  useRiskScoreNew,
  useRiskSummaryNew,
  useSubmitRiskNew,
  useUpdateRiskNew,
  UseUpdateRiskArgsNew,
  useUpdateRiskFormDataWithPrevious,
  useGetUserSpotForms,
} from "../../hooks";
import {
  Form,
  Formik,
  useFormikContext,
  setNestedObjectValues,
  FormikErrors,
  FormikTouched,
} from "formik";
import { getInitialNotificationFormValuesV3 } from "./getInitialNotificationFormValues";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";
import { RiskContact } from "./RiskContact";
import { ProductionNotificationFormV3, RiskData } from "../../types/types";
import { getLatestDate } from "./notificationForm.helper";
import dayjs from "dayjs";
import { useQueryClient } from "react-query";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { basicInfoSchemaV3 } from "./yupSchemas/basicInfoSchemaV3";
import { BasicInfoPageV3 } from "./NotificationFormPages/BasicInfoPageV3";
import { healthAndSafetySchemaV3 } from "./yupSchemas/healthAndSafetySchemaV3";
import { insuranceSchemaV3 } from "./yupSchemas/insuranceSchemaV3";
import { securitySchemaV3 } from "./yupSchemas/securitySchemaV3";
import { HealthAndSafetyPageV3 } from "./NotificationFormPages/HealthAndSafetyPageV3";
import { InsurancePageV3 } from "./NotificationFormPages/InsurancePageV3";
import { SecurityPageV3 } from "./NotificationFormPages/SecurityPageV3";
import { CyberSecurityPageV3 } from "./NotificationFormPages/CyberSecurityPageV3";
import { procurementSchemaV3 } from "./yupSchemas/procurementSchemaV3";
import { cyberSecuritySchemaV3 } from "./yupSchemas/cyberSecuritySchemaV3";
import { sustainabilitySchemaV3 } from "./yupSchemas/sustainabilitySchemaV3";
import { locationsAndTravelSchemaV3 } from "./yupSchemas/locationsAndTravelSchemaV3";
import { participantWelfareSchemaV3 } from "./yupSchemas/participantWelfareSchemaV3";
import { dataPrivacySchemaV3 } from "./yupSchemas/dataPrivacySchemaV3";
import { ProcurementPageV3 } from "./NotificationFormPages/ProcurementPageV3";
import { LocationsAndTravelPageV3 } from "./NotificationFormPages/LocationsAndTravelPageV3";
import { ParticipantWelfarePageV3 } from "./NotificationFormPages/ParticipantWelfarePageV3";
import { DataPrivacyPageV3 } from "./NotificationFormPages/DataPrivacyPageV3";
import { SustainabilityPageV3 } from "./NotificationFormPages/SustainabilityPageV3";
import { NotificationFormError } from "./NotificationFormError";
import { NotificationFormSteps } from "./NotificationFormSteps";
import { RiskScoreDialogNew } from "./RiskScoreDialog";
import { ModalService } from "../../services";
export interface FormStep {
  id:
    | "basicInfo"
    | "healthAndSafety"
    | "dutyOfCare"
    | "security"
    | "cyberSecurity"
    | "sustainability"
    | "dataPrivacy"
    | "insurance"
    | "compliance"
    | "procurement"
    | "locationsAndTravel"
    | "participantWelfare"
    | "dataPrivacy";
  title: string;
}

const notificationFormSchema = Yup.object({
  basicInfo: basicInfoSchemaV3,
  healthAndSafety: healthAndSafetySchemaV3,
  insurance: insuranceSchemaV3,
  security: securitySchemaV3,
  procurement: procurementSchemaV3,
  cyberSecurity: cyberSecuritySchemaV3,
  sustainability: sustainabilitySchemaV3,
  locationsAndTravel: locationsAndTravelSchemaV3,
  participantWelfare: participantWelfareSchemaV3,
  dataPrivacy: dataPrivacySchemaV3,
});

export type NotificationFormSubmitValuesV3 = Yup.InferType<
  typeof notificationFormSchema
>;

const STEPS: Array<FormStep> = [
  { id: "basicInfo", title: "Production" },
  { id: "locationsAndTravel", title: "Locations & Travel" },
  { id: "participantWelfare", title: "Participant Welfare" },
  { id: "insurance", title: "Insurance" },
  { id: "procurement", title: "Procurement" },
  { id: "healthAndSafety", title: "Health & Safety" },
  { id: "dataPrivacy", title: "Data Privacy" },
  { id: "cyberSecurity", title: "Cyber Security" },
  { id: "sustainability", title: "Sustainability" },
  { id: "security", title: "Security" },
];

export function NotificationFormV3() {
  const { seriesCcid, titleCcid } = useParams<string>();
  const [isDeclarationOpen, setIsDeclarationOpen] = useState(false);
  const [errorsDisplayed, setErrorsDisplayed] = useState(false);
  const [selectedImportCcid, setSelectedImportCcid] = useState("");
  const [isUsePreviousDialogOpen, setIsUsePreviousDialogOpen] = useState(false);
  const [autosaveSpinnerDisplayed, setaAutosaveSpinnerDisplayed] =
    useState(false);

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const currentStep = STEPS[currentStepIndex];
  const stepNumber = currentStepIndex + 1;

  const ccid = titleCcid || seriesCcid;

  const { data: dropdownMetadata, isLoading: dropdownMetadataLoading } =
    useMetadataDropdownNew();

  const { data: userSpotForms, isLoading: loadingUserSpotForms } =
    useGetUserSpotForms(seriesCcid as string);
  const { data: summary } = useRiskSummaryNew({ ccid });
  const {
    data: formData,
    isLoading: formDataLoading,
    error: riskError,
  } = useRiskNew({
    ccid,
    version: "v3",
  });
  const { data: riskScore, isLoading: riskScoreLoading } = useRiskScoreNew({
    ccid,
    version: "v3",
  });

  const queryClient = useQueryClient();

  const { mutate: updateForm, isLoading: isLoadingAutosave } = useUpdateRiskNew(
    {
      onSuccess: () => {
        queryClient.setQueryData(
          ["risk", ccid],
          (formData: ProductionNotificationFormV3 | undefined) => {
            if (!formData) {
              return undefined as unknown as ProductionNotificationFormV3;
            }
            enqueueSnackbar("Form successfully autosaved!", {
              variant: "success",
            });
            /* 
          TODO: this is a bit of a hack. 
          when the BE is refactored to add `lastUpdated` to the top-level of the form data, we should do the same here:
          return {
            ...formData,
            lastUpdated: new Date().toISOString(),
          }; 
          `getLatestDate()` will probably also need to be amended,
          or a new function created to get the autosave date for this form
          */
            return {
              ...formData,
              basicInfo: {
                ...formData.basicInfo,
                lastUpdated: new Date().toISOString(),
              },
            } as ProductionNotificationFormV3;
          },
        );
      },
      onError: () => {
        enqueueSnackbar(
          "There was an error autosaving the form. Please try again and contact us if this persists",
          {
            variant: "error",
            action: SnackbarDismiss,
            persist: true,
          },
        );
      },
    },
  );

  const { mutate: importAnswers, isLoading: isLoadingImportAnswers } =
    useUpdateRiskFormDataWithPrevious({
      onSuccess: () => {
        enqueueSnackbar("Answers Successfully Imported", {
          variant: "success",
        });
        queryClient.invalidateQueries("risk");
        setSelectedImportCcid("");
        setIsUsePreviousDialogOpen(false);
      },
      onError: () => {
        enqueueSnackbar(
          "There was an error autosaving the form. Please try again and contact us if this persists",
          {
            variant: "error",
            action: SnackbarDismiss,
            persist: true,
          },
        );
      },
    });

  useEffect(() => {
    if (isLoadingAutosave) setaAutosaveSpinnerDisplayed(true);
    if (!isLoadingAutosave) setaAutosaveSpinnerDisplayed(false);
  }, [isLoadingAutosave]);

  const autosaveForm = (formData: UseUpdateRiskArgsNew["formData"]) => {
    if (!isLoadingAutosave)
      return updateForm({ riskCcid: ccid as string, version: "v3", formData });
  };

  const { mutate: submitForm, isLoading: isSubmitting } = useSubmitRiskNew({
    onSuccess: () => {
      enqueueSnackbar("Form successfully submitted!", {
        variant: "success",
      });

      ModalService.getInstance()
        .setComponent(
          <RiskScoreDialogNew
            onStart={() => {
              queryClient.invalidateQueries("risk score");
              queryClient.invalidateQueries("risk");
              queryClient.invalidateQueries("risk summary");
            }}
          />,
        )
        .setShowHeader(false)
        .setShowFooter(false)
        .setContentPadding("0px")
        .setWidth("900px")
        .showModal();
    },
    onError: () => {
      enqueueSnackbar(
        "There was an error submitting the form. Please try again and contact us if this persists",
        {
          variant: "error",
          action: SnackbarDismiss,
          persist: true,
        },
      );
    },
  });

  const isLoading =
    dropdownMetadataLoading || formDataLoading || riskScoreLoading;

  if (isLoading) {
    return (
      <>
        <FormHeader
          formTitle="New Production Notification Form"
          showFormStatus
          summary={summary}
        />
        <Stack alignItems="center" m={4}>
          <CircularProgress />
        </Stack>
      </>
    );
  }

  if (!dropdownMetadata || !formData) {
    return (
      <>
        <FormHeader
          formTitle="New Production Notification Form"
          showFormStatus
          summary={summary}
        />
        <Stack alignItems="center" m={4}>
          <Alert severity="error">
            There was an error retrieving the data for this form. Please contact
            us if this persists
          </Alert>
        </Stack>
      </>
    );
  }

  const renderFormStepContent = () => {
    switch (currentStep.id) {
      case "basicInfo":
        return (
          <BasicInfoPageV3
            dropdownMetadata={dropdownMetadata}
            autosaveForm={autosaveForm}
          />
        );
      case "healthAndSafety":
        return <HealthAndSafetyPageV3 autosaveForm={autosaveForm} />;
      case "insurance":
        return <InsurancePageV3 autosaveForm={autosaveForm} />;
      case "cyberSecurity":
        return <CyberSecurityPageV3 autosaveForm={autosaveForm} />;
      case "security":
        return (
          <SecurityPageV3
            dropdownMetadata={dropdownMetadata}
            autosaveForm={autosaveForm}
          />
        );
      case "procurement":
        return <ProcurementPageV3 autosaveForm={autosaveForm} />;
      case "sustainability":
        return <SustainabilityPageV3 autosaveForm={autosaveForm} />;
      case "locationsAndTravel":
        return (
          <LocationsAndTravelPageV3
            dropdownMetadata={dropdownMetadata}
            autosaveForm={autosaveForm}
          />
        );
      case "participantWelfare":
        return <ParticipantWelfarePageV3 autosaveForm={autosaveForm} />;
      case "dataPrivacy":
        return <DataPrivacyPageV3 autosaveForm={autosaveForm} />;
      default:
        return null;
    }
  };
  const riskData: RiskData | null =
    currentStep.id === "basicInfo" || !riskScore
      ? null
      : riskScore[currentStep.id] || null;

  const autosaveDate = getLatestDate(formData);

  function FormSteps({
    currentStepIndex,
    handleStepChange,
    autosaveDate,
    onFormValidate,
  }: {
    currentStepIndex: number;
    handleStepChange: (stepIndex: number) => void;
    autosaveDate: string | undefined;
    isSubmitting: boolean;
    onFormValidate: (
      values?: NotificationFormSubmitValuesV3,
    ) => Promise<FormikErrors<NotificationFormSubmitValuesV3>>;
  }) {
    const formik = useFormikContext<NotificationFormSubmitValuesV3>();

    return (
      <Box position="sticky" top={0} padding={1}>
        {seriesCcid && (
          <Button
            onClick={() => setIsUsePreviousDialogOpen(true)}
            variant="outlined"
          >
            Import Previous Series Answers
          </Button>
        )}
        <Stack spacing={2}>
          <NotificationFormSteps
            version="v3"
            onStepChange={handleStepChange}
            riskScore={riskScore}
            flagError={errorsDisplayed}
            currentStepIndex={currentStepIndex}
          />
          <LoadingButton
            type="button"
            variant="contained"
            loading={isSubmitting}
            onClick={async () => {
              // ensures that the form attempts to submit (so that `submitCount` increments and errors get displayed)
              try {
                await formik.submitForm();
                // eslint-disable-next-line no-empty
              } catch {}

              onFormValidate().then(
                (errors: FormikErrors<NotificationFormSubmitValuesV3>) => {
                  if (Object.keys(errors).length === 0) {
                    setErrorsDisplayed(false);
                    setIsDeclarationOpen(true);
                  } else {
                    const validationErrors = Object.keys(errors).map(
                      (key) => key,
                    );
                    formik.setTouched(
                      setNestedObjectValues<
                        FormikTouched<NotificationFormSubmitValuesV3>
                      >(validationErrors, true),
                    );
                    let pageIndex = 0;
                    STEPS.forEach((val, index) => {
                      if (val.id === validationErrors[0]) pageIndex = index;
                    });
                    setErrorsDisplayed(true);
                    handleStepChange(pageIndex);
                  }
                },
              );
            }}
          >
            Submit Form
          </LoadingButton>

          <Stack spacing={1} direction="row">
            <IconButton
              aria-label="Previous step"
              disabled={currentStepIndex === 0}
              color="primary"
              onClick={() => {
                const newStepIndex = Math.max(0, currentStepIndex - 1);
                handleStepChange(newStepIndex);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => {
                const newStepIndex = Math.min(
                  STEPS.length - 1,
                  currentStepIndex + 1,
                );
                handleStepChange(newStepIndex);
              }}
              disabled={currentStepIndex === STEPS.length - 1}
              sx={{ fontWeight: "light" }}
              endIcon={<ArrowForward />}
            >
              Next Section
            </Button>
          </Stack>

          <Box>
            {autosaveDate && (
              <Typography sx={{ fontSize: "14px", fontWeight: "light" }}>
                Autosaved: {dayjs(autosaveDate).format("DD/MM/YYYY HH:mm a")}
              </Typography>
            )}
          </Box>
        </Stack>
      </Box>
    );
  }

  const Content = (
    <>
      <FormHeader
        formTitle="New Production Notification Form"
        showFormStatus
        summary={summary}
      />
      <Box sx={{ maxWidth: "100%", top: 0 }}>
        {autosaveSpinnerDisplayed ? (
          <Stack sx={{ p: 1, zIndex: 2000, position: "sticky" }}>
            <Typography variant="caption">Autosaving form...</Typography>
            <LinearProgress color="secondary" />
          </Stack>
        ) : (
          <Divider sx={{ height: "40px", zIndex: 900 }}></Divider>
        )}
      </Box>
      <Container disableGutters>
        <Formik
          enableReinitialize
          initialValues={getInitialNotificationFormValuesV3(formData, false)}
          validationSchema={notificationFormSchema}
          validateOnChange={false}
          validateOnMount={true}
          onSubmit={() => {
            setIsDeclarationOpen(true);
          }}
        >
          {({ values, validateForm, errors }) => {
            const stepId = currentStep.id;
            const errorFields = Object.keys(errors);
            return (
              <>
                <Form noValidate>
                  <Stack alignItems="left" spacing={5} direction="row">
                    <Box sx={{ maxWidth: "100%", p: 1, position: "sticky" }}>
                      <FormSteps
                        currentStepIndex={currentStepIndex}
                        handleStepChange={setCurrentStepIndex}
                        autosaveDate={autosaveDate}
                        isSubmitting={isSubmitting}
                        onFormValidate={validateForm}
                      />
                    </Box>
                    <Box width="100%">
                      <Stack spacing={2}>
                        <Typography variant="h5">
                          {`Section ${stepNumber} of ${STEPS.length}: ${currentStep.title}`}
                        </Typography>
                        {riskData && riskData.assessor ? (
                          <RiskContact
                            riskAssessor={riskData.assessor}
                            riskLevel={riskData.rating}
                            preMitgationLevel={riskData.preMitigationRating}
                            preMitgationNotes={riskData.preMitigationNotes}
                            postMitgationLevel={riskData.postMitigationRating}
                            postMitgationNotes={riskData.postMitigationNotes}
                          />
                        ) : null}

                        {currentStepIndex === 0 ? (
                          <>
                            <Typography variant="body1">
                              This form brings all elements of risk together in
                              one form, helping us review, manage, escalate and
                              report on risk across key areas of risk.
                            </Typography>
                            <Typography variant="body1">
                              You should get the form completed as soon as
                              possible - ideally before the start-up meeting -
                              to help us ensure you have the right support to
                              manage potential risks. All the information you
                              provide in the form will help us maintain a log of
                              all current and ongoing production.
                            </Typography>
                            <Typography variant="body1">
                              We are constantly updating and improving this
                              form, so if you have any questions or feedback -
                              please let us know by emailing risk@itv.com.
                            </Typography>
                          </>
                        ) : null}

                        <Typography fontStyle="italic" marginTop={2}>
                          <Typography component="span" color="error">
                            *
                          </Typography>{" "}
                          Indicates a required field
                        </Typography>
                        {errorsDisplayed &&
                        errors &&
                        errorFields.includes(stepId) ? (
                          <Alert severity="error">
                            There's questions not quite right in this section...
                          </Alert>
                        ) : (
                          <></>
                        )}
                        {renderFormStepContent()}
                      </Stack>
                    </Box>
                  </Stack>
                </Form>
                <Dialog open={isDeclarationOpen} maxWidth="xs">
                  <DialogTitle>SPOT Declaration of Truth</DialogTitle>
                  <DialogContent>
                    <Typography gutterBottom>
                      Please verify the validity of the following statement:
                    </Typography>

                    <Typography
                      textAlign="center"
                      fontWeight="bold"
                      fontStyle="italic"
                    >
                      I declare that the information provided in these forms is
                      true and accurate at this point in time, to the best of my
                      knowledge. I will endeavour to amend theses forms, when
                      additional information or new requirements arise at any
                      time within the production.
                    </Typography>
                  </DialogContent>

                  <DialogActions>
                    <Button
                      onClick={() => setIsDeclarationOpen(false)}
                      variant="outlined"
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={() => {
                        submitForm({
                          riskCcid: ccid as string,
                          formData: values,
                          version: "v3",
                        });
                        setIsDeclarationOpen(false);
                      }}
                      variant="contained"
                    >
                      Verify &amp; Submit Form
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={isUsePreviousDialogOpen} maxWidth="xs">
                  <DialogTitle>Use Data from a previous Series</DialogTitle>
                  <DialogContent>
                    <Stack alignItems="center" spacing={1}>
                      <Typography gutterBottom>
                        Please Select The series in which you wish to use the
                        data for
                      </Typography>

                      {(loadingUserSpotForms || isLoadingImportAnswers) && (
                        <CircularProgress />
                      )}
                    </Stack>
                    {!loadingUserSpotForms &&
                      !isLoadingImportAnswers &&
                      userSpotForms?.availableForms && (
                        <Autocomplete
                          id="id"
                          onChange={(_, option) => {
                            if (option) setSelectedImportCcid(option?.ccid);
                          }}
                          size="small"
                          getOptionLabel={(option) => {
                            return `Series ${option.seriesNumber}`;
                          }}
                          options={userSpotForms.availableForms || []}
                          renderInput={(params) => (
                            <TextField {...params} label="Select a series" />
                          )}
                        />
                      )}
                  </DialogContent>

                  <DialogActions>
                    <Button
                      disabled={loadingUserSpotForms || isLoadingImportAnswers}
                      onClick={() => setIsUsePreviousDialogOpen(false)}
                      variant="outlined"
                    >
                      Cancel
                    </Button>

                    {seriesCcid && (
                      <Button
                        onClick={() => {
                          importAnswers({
                            importFromCcid: selectedImportCcid,
                            riskCcid: ccid as string,
                            version: "v3",
                          });
                        }}
                        variant="contained"
                      >
                        Import Answers
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </>
            );
          }}
        </Formik>
      </Container>
    </>
  );
  return (
    <>
      {isLoading}
      {!isLoading && riskError && (
        <NotificationFormError riskError={riskError} />
      )}
      {!formDataLoading && !riskError && Content}
      <ModalWrapper />
    </>
  );
}
