import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Alert,
  Skeleton,
} from "@mui/material";
import {
  FrontendTask,
  LevelType,
  Task,
  TaskStatusPayload,
} from "../types/types";
import {
  useGetAssignedTasks,
  useTaskStatuses,
  TaskStatusQueryKey,
  useUserFullName,
} from "../hooks";
import { useParams } from "react-router-dom";
import {
  preProductionTasks,
  postProductionTasks,
  inProductionTasks,
} from "./TasksList";
import AssignedTaskItem from "./AssignedTaskItem";
import { useQueryClient } from "react-query";

const allTaskList = {
  ...preProductionTasks,
  ...inProductionTasks,
  ...postProductionTasks,
};

const AssignedTasksTile = ({ level }: { level: LevelType }): JSX.Element => {
  const { seriesCcid, titleCcid } = useParams<string>();
  const levelCcid = titleCcid || seriesCcid;

  const { data: assigneeData, isLoading: loadingAssigneeData } =
    useGetAssignedTasks(levelCcid as string);

  if (loadingAssigneeData) {
    return (
      <AssignedTasksTileContainer>
        <Skeleton variant="rounded" animation="wave" height={200} />
      </AssignedTasksTileContainer>
    );
  }

  if (!assigneeData) {
    return (
      <AssignedTasksTileContainer>
        <Alert severity="error">
          There was an error retrieving your assigned tasks for this production
        </Alert>
      </AssignedTasksTileContainer>
    );
  }

  const assignedTasks: Array<{ task: Task; taskDetails: FrontendTask }> = [];

  for (const task of assigneeData.tasks) {
    const taskDetails = allTaskList[task.taskName];

    if (!taskDetails) {
      continue;
    }

    if (taskDetails.isHidden?.({ level })) {
      continue;
    }

    assignedTasks.push({ task, taskDetails });
  }

  if (assigneeData.tasks.length === 0) {
    return (
      <AssignedTasksTileContainer>
        <Alert severity="info">You currently have no assigned tasks</Alert>
      </AssignedTasksTileContainer>
    );
  }

  return (
    <AssignedTasksTileContainer>
      <AssignedTasksList assignedTasks={assignedTasks} />
    </AssignedTasksTileContainer>
  );
};

function AssignedTasksTileContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const fullName = useUserFullName();

  const titleContent = () => {
    if (fullName) {
      return (
        <>
          Assigned Tasks for{" "}
          <Box component="span" textTransform="capitalize">
            {fullName}
          </Box>
        </>
      );
    }

    return "Assigned Tasks";
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{titleContent()}</Typography>
        <Typography variant="body2" paddingBottom="24px">
          These tasks have been assigned to you to complete
        </Typography>

        {children}
      </CardContent>
    </Card>
  );
}

type TaskWithStatus = Task & {
  completed: boolean;
};

function AssignedTasksList({
  assignedTasks,
}: {
  assignedTasks: Array<{ task: Task; taskDetails: FrontendTask }>;
}) {
  const queryClient = useQueryClient();
  const statusUrls: Array<string> = assignedTasks
    .filter(({ task }) => Boolean(task.taskStatusUrl))
    .map(({ task }) => task.taskStatusUrl as string);

  const statuses = useTaskStatuses({ statusUrls });

  const isLoadingStatuses = statuses.some((statusData) => statusData.isLoading);

  if (isLoadingStatuses) {
    return <Skeleton variant="rounded" animation="wave" height={200} />;
  }

  const tasksWithStatus: Array<{
    task: TaskWithStatus;
    taskDetails: FrontendTask;
  }> = assignedTasks.map(({ task, taskDetails }) => {
    let isCompleted = false;

    if (task.taskStatusUrl) {
      const queryKey: TaskStatusQueryKey = ["task status", task.taskStatusUrl];
      const status = queryClient.getQueryData<TaskStatusPayload>(queryKey);

      if (status?.completed === true) {
        isCompleted = true;
      }
    }

    return { task: { ...task, completed: isCompleted }, taskDetails };
  });

  // displaying completed tasks last
  const sortedTasks = tasksWithStatus.sort((taskA, taskB) => {
    if (taskA.task.completed) {
      return 1;
    }

    if (taskB.task.completed) {
      return -1;
    }

    return 0;
  });

  return (
    <Grid container spacing={2} flexWrap="wrap">
      {sortedTasks.map(({ task, taskDetails }) => {
        return (
          <Grid item xs={12} sm={6} key={task.taskName}>
            <AssignedTaskItem
              icon={taskDetails.icon}
              taskStatusUrl={task.taskStatusUrl || ""}
              description={taskDetails.description}
              taskRoute={taskDetails.taskRoute}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default AssignedTasksTile;
