import React, { FC } from "react";
import {
  Routes,
  Route,
  useNavigate,
  Outlet,
  useParams,
} from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { ErrorBoundary } from "react-error-boundary";
import { SnackbarProvider } from "notistack";
import { ProtectedRoutes } from "../../components/ProtectedRoutes";
import useGA from "../../lib/useGA";

import LoginPage from "../LoginPage";

import { StartupFormV2 } from "../Synopsis/Edit/StartupForm/StartupFormV2";
import { AccessManagement } from "../AccessManagement";
import { Billings } from "../Synopsis/Edit/Tasks/Billings";
import { BillingsNew } from "../Synopsis/Edit/Tasks/Billings/BillingsNew";
import { Homepage } from "../../pages/HomePage/Homepage";
import SearchPage from "../../pages/SearchPage/SearchPage";
import ProgrammePage from "../../pages/ProgrammePage/ProgrammePage";
import { oktaAuthClient } from "../../utils/oktaAuthClient";
import { CompassGPT } from "../../compass-gpt/CompassGPT";
import { SynopsisGroupWithData } from "../Synopsis/Edit/Synopsis/SynopsisGroup";
import { SeriesContributors } from "../Synopsis/Edit/Contributors/SeriesContributors";
import { ProductionNumbers } from "../Synopsis/Edit/ProductionNumbers/ProductionNumbers";
import { TitleVideos } from "../Synopsis/Edit/TxMasters/TitleVideos";
import Images from "../Synopsis/Edit/Images/Images";
import { TitleContributorsWithData } from "../Synopsis/Edit/Contributors/TitleContributors";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
import { Redirect } from "../../componentsV2/Redirect";
import ErrorHandler from "../../componentsV2/ErrorHandler/ErrorHandler";
import { useBulkImagesDownloadLink } from "../../hooks/queries/useBulkImagesDownloadLink";
import { BrandNameEditor } from "../../tasks/NameEditor/BrandNameEditor";
import ProgrammeTasksPage from "../../tasks/TaskPages/ProgrammeTasksPage";
import { SpecialTasksPage } from "../../tasks/TaskPages/SpecialTasksPage";
import { SeriesTasksPage } from "../../tasks/TaskPages/SeriesTasksPage";
import { TitleTasksPage } from "../../tasks/TaskPages/TitleTasksPage";
import { SeriesNameEditor } from "../../tasks/NameEditor/SeriesNameEditor";
import { TitleNameEditor } from "../../tasks/NameEditor/TitleNameEditor";
import { GoogleDrive } from "../Synopsis/Edit/Tasks/GoogleDrive";
import { MyDrive } from "../Synopsis/Edit/Tasks/MyDrive";
import { CreateSeries } from "../CreateSeries/CreateSeries";
import { AssignProductionLead } from "../AssignProductionLead/AssignProductionLead";
import { UpdateSeries } from "../UpdateSeries/UpdateSeries";
import { UpdateCcid } from "../UpdateCcid/UpdateCcid";
import { ManageEpisodes } from "../../tasks/ManageEpisodes/ManageEpisodes";
import ProductionContactsPage from "../../tasks/ProductionContacts/ProductionContactsPage";
import { OfcomForm } from "../OfcomForm/OfcomForm";
import { ProductionPrinciplesForm } from "../ProductionPrinciplesForm/ProductionPrinciplesForm";
import { AssignTasks } from "../Synopsis/Edit/Tasks/AssignTasks";
import { NotificationFormContainer } from "../NotificationForm/NotificationFormContainer";
import IconikUserManagement from "../Synopsis/Edit/Tasks/Iconik/IconikUserManagement";
import BrandedSpecialPage from "../../pages/BrandedSpecialPage";
import { CloseDownFormNew } from "../CloseDownForm/CloseDownFormNew";
import { SustainabilityForm } from "../SustainabilityForm/SustainabilityForm";
import { PreviewTimeCodes } from "../Synopsis/Edit/TxMasters/PreviewTimeCode/PreviewTimeCodes";
import FreelanceHiringPage from "../../pages/FreelanceHiringPage/FreelanceHiringPage";

const App: FC = () => {
  useGA();
  const navigate = useNavigate();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  useBulkImagesDownloadLink();

  return (
    <Security oktaAuth={oktaAuthClient} restoreOriginalUri={restoreOriginalUri}>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <SnackbarProvider maxSnack={1}>
          <Routes>
            {/* Login components */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/okta/code" element={<LoginCallback />} />
            <Route path="/login/callback" element={<LoginCallback />} />

            {/* Authenticated users only */}
            <Route element={<ProtectedRoutes />}>
              {/* Main Pages */}
              <Route path="/" element={<Homepage />} />
              <Route path="search" element={<SearchPage />} />
              <Route
                path="freelance-hiring"
                element={<FreelanceHiringPage />}
              />

              {/* Programme Tasks */}
              <Route
                path="programmes/:programmeCcid"
                element={<ProgrammePage />}
              >
                <Route path="" element={<ProgrammeTasksPage />} />
                <Route
                  path="billings"
                  element={<Redirect to="/billings/:programmeCcid" />}
                />
                <Route path="edit-title" element={<BrandNameEditor />} />
                <Route path="synopsis" element={<SynopsisGroupWithData />} />
                <Route path="google-drive" element={<GoogleDrive />} />
                <Route path="create-series" element={<CreateSeries />} />
                <Route path="update-series" element={<UpdateSeries />} />

                {/* Series Tasks */}
                <Route
                  path="series/0"
                  element={<Redirect to="/programmes/:programmeCcid" />}
                />

                <Route path="series/:seriesCcid" element={<Outlet />}>
                  <Route path="" element={<SeriesTasksPage />} />
                  <Route path="assign-tasks" element={<AssignTasks />} />
                  <Route
                    path="iconik-user-management"
                    element={<IconikUserManagement />}
                  />
                  <Route
                    path="billings"
                    element={
                      <Redirect to="/billings/:programmeCcid/:seriesCcid" />
                    }
                  />
                  <Route path="google-drive" element={<GoogleDrive />} />
                  <Route path="my-drive" element={<MyDrive />} />
                  <Route path="access-management" element={<AssignTasks />} />
                  <Route path="manage-episodes" element={<ManageEpisodes />} />
                  <Route path="edit-title" element={<SeriesNameEditor />} />
                  <Route path="synopsis" element={<SynopsisGroupWithData />} />
                  <Route path="contributors" element={<SeriesContributors />} />
                  <Route
                    path="productionNumbers"
                    element={<ProductionNumbers />}
                  />
                  <Route
                    path="productionContacts"
                    element={<ProductionContactsPage />}
                  />
                  <Route
                    path="txmaster"
                    element={<PreviewTimeCodes tag="preview" />}
                  />
                  <Route
                    path="preTitleSequence"
                    element={<PreviewTimeCodes tag="preTitleSequence" />}
                  />
                  <Route
                    path="marketingPromoClip"
                    element={<PreviewTimeCodes tag="marketingPromoClip" />}
                  />
                  <Route
                    path="logoSeries"
                    element={<Images uploadConfigKey="logoSeries" />}
                  />
                  <Route
                    path="fontSeries"
                    element={<Images uploadConfigKey="fontSeries" />}
                  />
                  <Route
                    path="iconicKeyArtSeriesGallery"
                    element={
                      <Images uploadConfigKey="iconicKeyArtSeriesGallery" />
                    }
                  />
                  <Route
                    path="iconicKeyArtSeriesPresenterContributor"
                    element={
                      <Images uploadConfigKey="iconicKeyArtSeriesPresenterContributor" />
                    }
                  />
                  <Route
                    path="iconicKeyArtSeries"
                    element={<Images uploadConfigKey="iconicKeyArtSeries" />}
                  />
                  <Route
                    path="close-down-form"
                    element={
                      <Redirect to="/close-down-form/:programmeCcid/:seriesCcid" />
                    }
                  />
                  <Route
                    path="notification-form"
                    element={
                      <Redirect to="/notification-form/:programmeCcid/:seriesCcid" />
                    }
                  />
                  <Route
                    path="notification-form-new"
                    element={
                      <Redirect to="/notification-form-new/:programmeCcid/:seriesCcid" />
                    }
                  />
                  <Route
                    path="notification-form-v2"
                    element={
                      <Redirect to="/notification-form-v2/:programmeCcid/:seriesCcid" />
                    }
                  />
                  <Route
                    path="start-up-form"
                    element={
                      <Redirect to="/start-up-form/:programmeCcid/:seriesCcid" />
                    }
                  />
                  <Route path="update-series" element={<UpdateSeries />} />
                  <Route
                    path="production-lead"
                    element={<AssignProductionLead />}
                  />
                  <Route
                    path="ofcom-form"
                    element={
                      <Redirect to="/ofcom-form/:programmeCcid/:seriesCcid" />
                    }
                  />
                  <Route
                    path="ofcom-final"
                    element={
                      <Redirect to="/ofcom-final/:programmeCcid/:seriesCcid" />
                    }
                  />
                  <Route
                    path="production-principles"
                    element={
                      <Redirect to="/production-principles/:programmeCcid/:seriesCcid" />
                    }
                  />
                  <Route
                    path="production-principles-final"
                    element={
                      <Redirect to="/production-principles-final/:programmeCcid/:seriesCcid" />
                    }
                  />
                  <Route
                    path="sustainability-form"
                    element={
                      <Redirect to="/sustainability-form/:programmeCcid/:seriesCcid" />
                    }
                  />

                  {/* Episode or Branded Special Tasks */}
                  <Route path="titles/:titleCcid" element={<Outlet />}>
                    <Route
                      path=""
                      element={<EpisodeOrBrandedSpecialTaskPage />}
                    />
                    <Route
                      path="billings"
                      element={
                        <Redirect to="/billings/:programmeCcid/:seriesCcid/:titleCcid" />
                      }
                    />
                    <Route path="edit-title" element={<TitleNameEditor />} />
                    <Route
                      path="notification-form"
                      element={<Redirect to="/notification-form/:titleCcid" />}
                    />
                    <Route
                      path="notification-form-new"
                      element={
                        <Redirect to="/notification-form-new/:titleCcid" />
                      }
                    />
                    <Route
                      path="synopsis"
                      element={<SynopsisGroupWithData />}
                    />
                    <Route path="assign-tasks" element={<AssignTasks />} />
                    <Route
                      path="contributors"
                      element={<TitleContributorsWithData />}
                    />
                    <Route
                      path="start-up-form"
                      element={<Redirect to="/start-up-form/:titleCcid" />}
                    />
                    <Route
                      path="txmaster"
                      element={<TitleVideos tag="preview" />}
                    />
                    <Route
                      path="productionNumbers"
                      element={<ProductionNumbers />}
                    />
                    <Route
                      path="productionContacts"
                      element={<ProductionContactsPage />}
                    />
                    <Route
                      path="episodeClipTimecode"
                      element={<PreviewTimeCodes tag="episodeClip" />}
                    />
                    <Route
                      path="marketingPromoClip"
                      element={<PreviewTimeCodes tag="marketingPromoClip" />}
                    />
                    <Route
                      path="scripts"
                      element={<Images uploadConfigKey="scripts" />}
                    />
                    <Route
                      path="episodic"
                      element={<Images uploadConfigKey="episodic" />}
                    />
                    <Route path="access-management" element={<AssignTasks />} />
                    <Route
                      path="episodicBehindScenes"
                      element={
                        <Images uploadConfigKey="episodicBehindScenes" />
                      }
                    />
                    <Route
                      path="production-lead"
                      element={<AssignProductionLead />}
                    />
                    <Route
                      path="iconicKeyArtSeries"
                      element={<Redirect to="../iconicKeyArtOneOff" />}
                    />
                    <Route
                      path="iconicKeyArtSeriesGallery"
                      element={<Redirect to="../iconicKeyArtOneOffGallery" />}
                    />
                    <Route
                      path="iconicKeyArtSeriesPresenterContributor"
                      element={
                        <Redirect to="../iconicKeyArtOneOffPresenterContributor" />
                      }
                    />
                    <Route
                      path="logoSeries"
                      element={<Redirect to="../logoOneOff" />}
                    />
                    <Route
                      path="fontSeries"
                      element={<Redirect to="../fontOneOff" />}
                    />
                    <Route
                      path="close-down-form"
                      element={<Redirect to="/close-down-form/:titleCcid" />}
                    />
                    <Route path="google-drive" element={<GoogleDrive />} />
                    <Route
                      path="ofcom-form"
                      element={<Redirect to="/ofcom-form/:titleCcid" />}
                    />
                    <Route
                      path="ofcom-final"
                      element={<Redirect to="/ofcom-final/:titleCcid" />}
                    />
                    <Route
                      path="production-principles"
                      element={
                        <Redirect to="/production-principles/:titleCcid" />
                      }
                    />
                    <Route
                      path="production-principles-final"
                      element={
                        <Redirect to="/production-principles-final/:titleCcid" />
                      }
                    />

                    <Route
                      path="iconik-user-management"
                      element={<IconikUserManagement />}
                    />

                    <Route
                      path="sustainability-form"
                      element={
                        <Redirect to="/sustainability-form/:titleCcid" />
                      }
                    />
                    {/* Catch one off legacy stuff - for clean up later */}
                    <Route
                      path="oneOffFeatureImage"
                      element={<Images uploadConfigKey="oneOffFeatureImage" />}
                    />
                    <Route
                      path="oneOffBehindScenes"
                      element={<Images uploadConfigKey="oneOffBehindScenes" />}
                    />
                    <Route
                      path="scripts"
                      element={<Images uploadConfigKey="scripts" />}
                    />
                    <Route
                      path="logoOneOff"
                      element={<Images uploadConfigKey="logoOneOff" />}
                    />
                    <Route
                      path="fontOneOff"
                      element={<Images uploadConfigKey="fontOneOff" />}
                    />
                    <Route
                      path="preTitleSequence"
                      element={<PreviewTimeCodes tag="preTitleSequence" />}
                    />
                    <Route
                      path="previewTimecode"
                      element={<PreviewTimeCodes tag="preview" />}
                    />
                    <Route
                      path="iconicKeyArtOneOff"
                      element={<Images uploadConfigKey="iconicKeyArtOneOff" />}
                    />
                    <Route
                      path="iconicKeyArtOneOffGallery"
                      element={
                        <Images uploadConfigKey="iconicKeyArtOneOffGallery" />
                      }
                    />
                    <Route
                      path="iconicKeyArtOneOffPresenterContributor"
                      element={
                        <Images uploadConfigKey="iconicKeyArtOneOffPresenterContributor" />
                      }
                    />
                  </Route>
                </Route>
              </Route>

              {/* Branded Special Tasks */}
              <Route
                path="programmes/brandedSpecials/:programmeCcid/special/:titleCcid"
                element={<BrandedSpecialPage />}
              >
                <Route path="" element={<SpecialTasksPage />} />
                <Route path="access-management" element={<AssignTasks />} />
                <Route path="assign-tasks" element={<AssignTasks />} />
                <Route
                  path="iconik-user-management"
                  element={<IconikUserManagement />}
                />
                <Route path="edit-title" element={<TitleNameEditor />} />
                <Route
                  path="billings"
                  element={<Redirect to="/billings/:titleCcid" />}
                />
                <Route
                  path="notification-form"
                  element={<Redirect to="/notification-form/:titleCcid" />}
                />
                <Route
                  path="notification-form-new"
                  element={<Redirect to="/notification-form-new/:titleCcid" />}
                />
                <Route
                  path="notification-form-v2"
                  element={<Redirect to="/notification-form-v2/:titleCcid" />}
                />
                <Route
                  path="start-up-form"
                  element={<Redirect to="/start-up-form/:titleCcid" />}
                />
                <Route
                  path="close-down-form"
                  element={<Redirect to="/close-down-form/:titleCcid" />}
                />
                <Route path="synopsis" element={<SynopsisGroupWithData />} />
                <Route
                  path="contributors"
                  element={<TitleContributorsWithData />}
                />
                <Route
                  path="productionNumbers"
                  element={<ProductionNumbers />}
                />
                <Route
                  path="productionContacts"
                  element={<ProductionContactsPage />}
                />
                <Route
                  path="txmaster"
                  element={<TitleVideos tag="preview" />}
                />
                <Route
                  path="preTitleSequence"
                  element={<TitleVideos tag="preTitleSequence" />}
                />
                <Route
                  path="marketingPromoClip"
                  element={<TitleVideos tag="marketingPromoClip" />}
                />
                <Route path="google-drive" element={<GoogleDrive />} />
                <Route path="update-ccid" element={<UpdateCcid />} />
                <Route
                  path="production-lead"
                  element={<AssignProductionLead />}
                />
                <Route
                  path="production-principles"
                  element={<Redirect to="/production-principles/:titleCcid" />}
                />
                <Route
                  path="production-principles-final"
                  element={
                    <Redirect to="/production-principles-final/:titleCcid" />
                  }
                />
                <Route
                  path="ofcom-form"
                  element={<Redirect to="/ofcom-form/:titleCcid" />}
                />
                <Route
                  path="ofcom-final"
                  element={<Redirect to="/ofcom-final/:titleCcid" />}
                />

                <Route
                  path="oneOffFeatureImage"
                  element={<Images uploadConfigKey="oneOffFeatureImage" />}
                />
                <Route
                  path="oneOffBehindScenes"
                  element={<Images uploadConfigKey="oneOffBehindScenes" />}
                />
                <Route
                  path="scripts"
                  element={<Images uploadConfigKey="scripts" />}
                />
                <Route
                  path="logoOneOff"
                  element={<Images uploadConfigKey="logoOneOff" />}
                />
                <Route
                  path="fontOneOff"
                  element={<Images uploadConfigKey="fontOneOff" />}
                />
                <Route
                  path="iconicKeyArtOneOff"
                  element={<Images uploadConfigKey="iconicKeyArtOneOff" />}
                />
                <Route
                  path="iconicKeyArtOneOffGallery"
                  element={
                    <Images uploadConfigKey="iconicKeyArtOneOffGallery" />
                  }
                />
                <Route
                  path="iconicKeyArtOneOffPresenterContributor"
                  element={
                    <Images uploadConfigKey="iconicKeyArtOneOffPresenterContributor" />
                  }
                />

                <Route
                  path="sustainability-form"
                  element={<Redirect to="/sustainability-form/:titleCcid" />}
                />

                {/* Dont like these redirects - need to refactor the Images component */}
                <Route
                  path="episodicBehindScenes"
                  element={
                    <Redirect to="/special/:titleCcid/oneOffBehindScenes" />
                  }
                />
                <Route
                  path="logoSeries"
                  element={<Redirect to="/special/:titleCcid/logoOneOff" />}
                />
                <Route
                  path="fontSeries"
                  element={<Redirect to="/special/:titleCcid/fontOneOff" />}
                />
                <Route
                  path="iconicKeyArtSeries"
                  element={
                    <Redirect to="/special/:titleCcid/iconicKeyArtOneOff" />
                  }
                />
                <Route
                  path="iconicKeyArtSeriesGallery"
                  element={
                    <Redirect to="/special/:titleCcid/iconicKeyArtOneOffGallery" />
                  }
                />
                <Route
                  path="iconicKeyArtSeriesPresenterContributor"
                  element={
                    <Redirect to="/special/:titleCcid/iconicKeyArtOneOffPresenterContributor" />
                  }
                />
              </Route>

              {/* Special (oneOff/film/special) Tasks */}
              <Route path="special/:titleCcid" element={<ProgrammePage />}>
                <Route path="" element={<SpecialTasksPage />} />
                <Route path="access-management" element={<AssignTasks />} />
                <Route path="assign-tasks" element={<AssignTasks />} />
                <Route
                  path="iconik-user-management"
                  element={<IconikUserManagement />}
                />
                <Route path="edit-title" element={<TitleNameEditor />} />
                <Route
                  path="billings"
                  element={<Redirect to="/billings/:titleCcid" />}
                />
                <Route
                  path="notification-form"
                  element={<Redirect to="/notification-form/:titleCcid" />}
                />
                <Route
                  path="notification-form-new"
                  element={<Redirect to="/notification-form-new/:titleCcid" />}
                />
                <Route
                  path="notification-form-v2"
                  element={<Redirect to="/notification-form-v2/:titleCcid" />}
                />
                <Route
                  path="start-up-form"
                  element={<Redirect to="/start-up-form/:titleCcid" />}
                />
                <Route
                  path="close-down-form"
                  element={<Redirect to="/close-down-form/:titleCcid" />}
                />
                <Route path="synopsis" element={<SynopsisGroupWithData />} />
                <Route
                  path="contributors"
                  element={<TitleContributorsWithData />}
                />
                <Route
                  path="productionNumbers"
                  element={<ProductionNumbers />}
                />
                <Route
                  path="productionContacts"
                  element={<ProductionContactsPage />}
                />
                <Route
                  path="txmaster"
                  element={<TitleVideos tag="preview" />}
                />
                <Route
                  path="preTitleSequence"
                  element={<TitleVideos tag="preTitleSequence" />}
                />
                <Route
                  path="marketingPromoClip"
                  element={<TitleVideos tag="marketingPromoClip" />}
                />
                <Route path="google-drive" element={<GoogleDrive />} />
                <Route path="update-ccid" element={<UpdateCcid />} />
                <Route
                  path="production-lead"
                  element={<AssignProductionLead />}
                />
                <Route
                  path="production-principles"
                  element={<Redirect to="/production-principles/:titleCcid" />}
                />
                <Route
                  path="production-principles-final"
                  element={
                    <Redirect to="/production-principles-final/:titleCcid" />
                  }
                />
                <Route
                  path="ofcom-form"
                  element={<Redirect to="/ofcom-form/:titleCcid" />}
                />
                <Route
                  path="ofcom-final"
                  element={<Redirect to="/ofcom-final/:titleCcid" />}
                />
                <Route
                  path="sustainability-form"
                  element={<Redirect to="/sustainability-form/:titleCcid" />}
                />

                <Route
                  path="oneOffFeatureImage"
                  element={<Images uploadConfigKey="oneOffFeatureImage" />}
                />
                <Route
                  path="oneOffBehindScenes"
                  element={<Images uploadConfigKey="oneOffBehindScenes" />}
                />
                <Route
                  path="scripts"
                  element={<Images uploadConfigKey="scripts" />}
                />
                <Route
                  path="logoOneOff"
                  element={<Images uploadConfigKey="logoOneOff" />}
                />
                <Route
                  path="fontOneOff"
                  element={<Images uploadConfigKey="fontOneOff" />}
                />
                <Route
                  path="iconicKeyArtOneOff"
                  element={<Images uploadConfigKey="iconicKeyArtOneOff" />}
                />
                <Route
                  path="iconicKeyArtOneOffGallery"
                  element={
                    <Images uploadConfigKey="iconicKeyArtOneOffGallery" />
                  }
                />
                <Route
                  path="iconicKeyArtOneOffPresenterContributor"
                  element={
                    <Images uploadConfigKey="iconicKeyArtOneOffPresenterContributor" />
                  }
                />

                {/* Dont like these redirects - need to refactor the Images component */}
                <Route
                  path="episodicBehindScenes"
                  element={
                    <Redirect to="/special/:titleCcid/oneOffBehindScenes" />
                  }
                />
                <Route
                  path="logoSeries"
                  element={<Redirect to="/special/:titleCcid/logoOneOff" />}
                />
                <Route
                  path="fontSeries"
                  element={<Redirect to="/special/:titleCcid/fontOneOff" />}
                />
                <Route
                  path="iconicKeyArtSeries"
                  element={
                    <Redirect to="/special/:titleCcid/iconicKeyArtOneOff" />
                  }
                />
                <Route
                  path="iconicKeyArtSeriesGallery"
                  element={
                    <Redirect to="/special/:titleCcid/iconicKeyArtOneOffGallery" />
                  }
                />
                <Route
                  path="iconicKeyArtSeriesPresenterContributor"
                  element={
                    <Redirect to="/special/:titleCcid/iconicKeyArtOneOffPresenterContributor" />
                  }
                />
              </Route>

              {/* Legacy routes */}
              <Route path="/compass" element={<CompassGPT />} />
              {/* TODO remove old compass-gpt route when we're done with it */}
              <Route path="/content/compass-gpt" element={<CompassGPT />} />

              <Route
                path="/billing/programmes/:programmeCcid/series"
                element={<Billings />}
              />
              <Route
                path="/billing/programmes/:programmeCcid/series/:seriesCcid"
                element={<Billings />}
              />
              <Route
                path="/billing/programmes/:programmeCcid/series/:seriesCcid/titles/:titleCcid"
                element={<BillingsNew />}
              />
              <Route
                path="/billing/special/:titleCcid"
                element={<BillingsNew />}
              />

              <Route path="/billings/:titleCcid" element={<BillingsNew />} />
              <Route path="/billings/:programmeCcid" element={<Billings />} />
              <Route
                path="/billings/:programmeCcid/:seriesCcid"
                element={<Billings />}
              />
              <Route
                path="/billings/:programmeCcid/:seriesCcid/:titleCcid"
                element={<BillingsNew />}
              />
              <Route
                path="/notification-form/:programmeCcid/:seriesCcid"
                element={<NotificationFormContainer />}
              />
              <Route
                path="/notification-form-new/:programmeCcid/:seriesCcid"
                element={<NotificationFormContainer />}
              />
              <Route
                path="/ofcom-form/:programmeCcid/:seriesCcid"
                element={<OfcomForm provisional />}
              />
              <Route
                path="/ofcom-final/:programmeCcid/:seriesCcid"
                element={<OfcomForm provisional={false} />}
              />
              <Route
                path="/production-principles/:programmeCcid/:seriesCcid"
                element={<ProductionPrinciplesForm provisional />}
              />
              <Route
                path="/production-principles-final/:programmeCcid/:seriesCcid"
                element={<ProductionPrinciplesForm provisional={false} />}
              />
              <Route
                path="/start-up-form/:programmeCcid/:seriesCcid"
                element={<StartupFormV2 />}
              />
              <Route
                path="/close-down-form/:programmeCcid/:seriesCcid"
                element={<CloseDownFormNew level="series" />}
              />
              <Route
                path="production-lead"
                element={<AssignProductionLead />}
              />
              <Route
                path="/notification-form/:titleCcid"
                element={<NotificationFormContainer />}
              />
              <Route
                path="/notification-form-new/:titleCcid"
                element={<NotificationFormContainer />}
              />
              <Route
                path="/ofcom-form/:titleCcid"
                element={<OfcomForm provisional />}
              />
              <Route
                path="/ofcom-final/:titleCcid"
                element={<OfcomForm provisional={false} />}
              />
              <Route
                path="/production-principles/:titleCcid"
                element={<ProductionPrinciplesForm provisional />}
              />
              <Route
                path="/production-principles-final/:titleCcid"
                element={<ProductionPrinciplesForm provisional={false} />}
              />

              <Route
                path="/start-up-form/:titleCcid"
                element={<StartupFormV2 />}
              />
              <Route
                path="/close-down-form/:titleCcid"
                element={<CloseDownFormNew level="titles" />}
              />
              <Route
                path="/access-directory/:labelId"
                element={<AccessManagement />}
              />
              <Route path="/access-directory" element={<AccessManagement />} />

              <Route
                path="/sustainability-form/:programmeCcid/:seriesCcid"
                element={<SustainabilityForm />}
              />

              <Route
                path="/sustainability-form/:titleCcid"
                element={<SustainabilityForm />}
              />

              {/* Redirects for old routes */}
              <Route path="/content" element={<Redirect to="/search" />} />
              <Route
                path="/content/programmes"
                element={<Redirect to="/search" />}
              />
              <Route
                path="/content/programmes/:programmeCcid"
                element={<Redirect to="/programmes/:programmeCcid" />}
              />
              <Route
                path="/content/programmes/:programmeCcid/series"
                element={<Redirect to="/programmes/:programmeCcid" />}
              />
              <Route
                path="/content/programmes/:programmeCcid/series/:serieCcid"
                element={
                  <Redirect to="/programmes/:programmeCcid/series/:serieCcid" />
                }
              />
              <Route
                path="/content/programmes/:programmeCcid/series/:serieCcid/titles/:titleCcid"
                element={
                  <Redirect to="/programmes/:programmeCcid/series/:serieCcid/titles/:titleCcid" />
                }
              />
              <Route
                path="/content/titles/:titleCcid"
                element={<Redirect to="/special/:titleCcid" />}
              />
              <Route
                path="/content/titles/:titleCcid/:titleName"
                element={<Redirect to="/special/:titleCcid" />}
              />
            </Route>

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </SnackbarProvider>
      </ErrorBoundary>
    </Security>
  );
};

function EpisodeOrBrandedSpecialTaskPage() {
  const { seriesCcid } = useParams();

  if (seriesCcid === "0") {
    return <SpecialTasksPage />;
  }

  return <TitleTasksPage />;
}

export default App;
