import { AxiosResponse } from "axios";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import {
  EssenceTimecodeResponsePayload,
  TimecodeResponsePayload,
  TimecodeTag,
  UpdateTimecodesPayload,
  VideoAssetsResponsePayload,
  VideoMetadataResponsePayload,
} from "../types/types";

const API_BASE = getAPI(SERVICES.browse_assets);

export const getVideoAssets = (
  titleCcid: string,
): Promise<VideoAssetsResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/titles/${titleCcid}/browseassets`)
    .then(({ data }: AxiosResponse) => data);

export const getSelectedVideoUrl = (versionCcid: string): Promise<string> =>
  handleRequest
    .get(`${API_BASE}/versions/${versionCcid}/browseassets/url`)
    .then(({ headers }) => headers.location);

export const getTitleVersions = (
  titleCcid: string,
): Promise<VideoMetadataResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/titles/${titleCcid}/versions`)
    .then(({ data }) => data);

export const getTimecodes = ({
  ccid,
  level,
  tag,
}: {
  ccid: string;
  level: "series" | "titles";
  tag: TimecodeTag;
}): Promise<TimecodeResponsePayload> => {
  const endpoint =
    level === "titles"
      ? `${API_BASE}/titles/${ccid}/timecodes?timecodeTag=${tag}`
      : `${API_BASE}/series/${ccid}/timecodes?timecodeTag=${tag}`;

  return handleRequest
    .get(endpoint)
    .then(({ data }) => data as TimecodeResponsePayload);
};

export const getEssenceTimecode = (
  versionCcid: string,
): Promise<EssenceTimecodeResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/versions/${versionCcid}/timecodes`)
    .then(({ data }) => data);

export const updateTimecodes = ({
  ccid,
  level,
  payload,
}: {
  ccid: string;
  level: "series" | "titles";
  payload: UpdateTimecodesPayload;
}): Promise<void> => {
  const endpoint =
    level === "titles"
      ? `${API_BASE}/titles/${ccid}/timecodes`
      : `${API_BASE}/series/${ccid}/timecodes`;

  return handleRequest
    .put<UpdateTimecodesPayload>(endpoint, payload)
    .then(() => undefined);
};
