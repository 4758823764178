import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  // Chip,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { FreelanceHiringTable } from "../../componentsV2/FreelanceHiringTable";
import { useGetUserProfile } from "../../hooks";
import { ExpandMore } from "@mui/icons-material";
import { useHiringVacancies } from "../../hooks/queries/useHiringVacancies";

// const getCandidateCountLabel = (numberOfCandidates: number): string => {
//   if (numberOfCandidates === 1) {
//     return "1 candidate";
//   }

//   return `${numberOfCandidates} candidates`;
// };

const FreelanceHiringPage = () => {
  const { data: vacancyData, isLoading: loadingVacancies } =
    useHiringVacancies();

  const userProfile = useGetUserProfile();

  const isLoading = loadingVacancies;

  if (isLoading) {
    return (
      <PageContainer>
        <Stack alignItems="center" paddingY={3}>
          <CircularProgress />
        </Stack>
      </PageContainer>
    );
  }

  if (!vacancyData) {
    return (
      <PageContainer>
        <Stack alignItems="center" paddingY={3}>
          <Alert severity="error">
            There was an issue retrieving your relevant vacancies. Please
            contact us if this persists
          </Alert>
        </Stack>
      </PageContainer>
    );
  }

  if (!vacancyData.vacancies.length) {
    return (
      <PageContainer>
        <Stack alignItems="center" paddingY={3}>
          <Alert severity="info">
            You do not have any vacancies assigned to you. Please contact us if
            this is incorrect.
          </Alert>
        </Stack>
      </PageContainer>
    );
  }

  if (userProfile.data?.userType === "ADMIN") {
    return (
      <PageContainer>
        <Stack direction="column" spacing={2}>
          {vacancyData.vacancies.map((vacancy) =>
            vacancy.productions.map((production) =>
              production.series.map((series) =>
                series.roles.map((role) => (
                  <Accordion
                    key={`${production.productionName}-${series.seriesNumber}-${role.jobTitle}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`panel-${production.productionName}-${series.seriesNumber}-${role.jobTitle}-content`}
                      id={`panel-${production.productionName}-${series.seriesNumber}-${role.jobTitle}-header`}
                    >
                      <Box display="flex" alignItems="center" gap="16px">
                        <Typography component="span">
                          {production.productionName}
                        </Typography>
                        {/* 
                  <Typography component="span">
                    <Chip
                      // label={getCandidateCountLabel(production.candidates.length)}
                      color="primary"
                    />
                  </Typography> */}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FreelanceHiringTable
                        candidates={role.applicants.filter((applicant) =>
                          [
                            "SHORTLISTED_YES",
                            "SUCCESSFUL_YES",
                            "SUCCESSFUL_NO",
                          ].includes(applicant.status),
                        )}
                      />
                    </AccordionDetails>
                  </Accordion>
                )),
              ),
            ),
          )}
        </Stack>
      </PageContainer>
    );
  }

  return null;
};

const PageContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container sx={{ py: 4 }}>
      <Typography component="h1" variant="h2" paddingY={2}>
        Freelance Hiring Hub
      </Typography>

      <Typography variant="subtitle1" paddingBottom={2}>
        This is where you can manage the shortlisted candidates for any
        vacancies relevant to you.
      </Typography>

      {children}
    </Container>
  );
};

export default FreelanceHiringPage;
