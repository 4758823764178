import { FC } from "react";
import { Skeleton, Link, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  useBrandMetadata,
  useSeriesMetadata,
  useTitleMetadata,
} from "../../hooks/queries";

const StudiosBreadcrumbs: FC<{
  brandedSpecial?: boolean;
}> = ({ brandedSpecial }) => {
  const { programmeCcid, seriesCcid, titleCcid } = useParams();
  const { data: brandMetaData, isLoading: brandMetaDataLoading } =
    useBrandMetadata(programmeCcid, true);
  const { data: seriesMetaData, isLoading: seriesMetaDataLoading } =
    useSeriesMetadata(seriesCcid);
  const { data: titleMetaData, isLoading: titleMetaDataLoading } =
    useTitleMetadata(titleCcid);

  function getSeriesDisplayName(
    seriesName: string | undefined,
    seriesNumber: number | undefined,
  ) {
    if (seriesName && seriesNumber)
      return ` Series ${seriesNumber} - ${seriesName}`;
    if (seriesNumber) return `Series ${seriesNumber}`;
    if (seriesName) return seriesName;
    return "Series";
  }

  function getBreadCrumbLink(
    linkType: "brand" | "series" | "episode" | "special" | "brandedSpecial",
  ) {
    switch (linkType) {
      case "brand":
        return brandMetaDataLoading ? (
          <Skeleton width={100} />
        ) : (
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/programmes/${programmeCcid}`}
          >
            {brandMetaData?.brandName}
          </Link>
        );
      case "series":
        return seriesMetaDataLoading ? (
          <Skeleton width={100} />
        ) : (
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/programmes/${programmeCcid}/series/${seriesCcid}`}
          >
            {seriesCcid === "0"
              ? "Specials & Promos"
              : getSeriesDisplayName(
                  seriesMetaData?.seriesName,
                  seriesMetaData?.seriesNumber,
                )}
          </Link>
        );
      case "episode":
        return titleMetaDataLoading ? (
          <Skeleton width={100} />
        ) : (
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/programmes/${programmeCcid}/series/${seriesCcid}/titles/${titleCcid}`}
          >
            {titleMetaData?.titleName
              ? titleMetaData?.titleName
              : `Episode ${titleMetaData?.episodeNumber}`}
          </Link>
        );
      case "special":
        return titleMetaDataLoading ? (
          <Skeleton width={100} />
        ) : (
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/special/${titleCcid}`}
          >
            {titleMetaData?.titleName
              ? titleMetaData?.titleName
              : `Episode ${titleMetaData?.episodeNumber}`}
          </Link>
        );
      case "brandedSpecial":
        return titleMetaDataLoading ? (
          <Skeleton width={100} />
        ) : titleMetaData?.titleName ? (
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/programmes/brandedSpecials/${programmeCcid}/special/${titleCcid}`}
          >
            {titleMetaData.titleName}
          </Link>
        ) : null;
      default:
        return null;
    }
  }

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link underline="hover" color="inherit" component={RouterLink} to="/">
        Home
      </Link>
      {programmeCcid ? getBreadCrumbLink("brand") : null}
      {seriesCcid ? getBreadCrumbLink("series") : null}
      {titleCcid && seriesCcid && programmeCcid
        ? getBreadCrumbLink("episode")
        : null}
      {titleCcid && !seriesCcid && !programmeCcid
        ? getBreadCrumbLink("special")
        : null}
      {titleCcid && !seriesCcid && programmeCcid
        ? getBreadCrumbLink("brandedSpecial")
        : null}
    </Breadcrumbs>
  );
};

export default StudiosBreadcrumbs;
