import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Link,
  Box,
  Tooltip,
  Alert,
  LinearProgress,
} from "@mui/material";
import {
  inProductionTasks,
  postProductionTasks,
  preProductionTasks,
} from "./TasksList";
import {
  FrontendTask,
  FrontendTaskList,
  LevelType,
  ProductionStage,
  Task,
} from "../types/types";
import { Link as RouterLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaskTitleIcon from "./TaskTitleIcon";
import { useTaskStatus } from "../hooks";
import { useCurrentOktaUser } from "../hooks/queries";
import AssignmentIndOutlined from "@mui/icons-material/AssignmentIndOutlined";
import theme from "../utils/theme";

interface TaskSectionProps {
  tasks: Array<Task>;
  productionStage: ProductionStage;
  level: LevelType;
}

const sectionTitleMap: Record<ProductionStage, string> = {
  preProduction: "Pre-Production",
  inProduction: "In-Production",
  postProduction: "Post-Production",
};

const taskListMap: Record<ProductionStage, FrontendTaskList> = {
  preProduction: preProductionTasks,
  inProduction: inProductionTasks,
  postProduction: postProductionTasks,
};

export function TaskSection({
  tasks,
  productionStage,
  level,
}: TaskSectionProps) {
  const tasksInStage: Array<{ task: Task; taskDetails: FrontendTask }> = [];

  for (const task of tasks) {
    const taskDetails = taskListMap[productionStage][task.taskName];

    if (!taskDetails) {
      continue;
    }

    if (taskDetails.isHidden?.({ level })) {
      continue;
    }

    tasksInStage.push({ task, taskDetails });
  }

  return (
    <Accordion defaultExpanded={true} elevation={0} sx={{ width: "100%" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="subtitle1" fontSize="1rem" lineHeight={1.5}>
            {sectionTitleMap[productionStage]}
          </Typography>

          <Typography variant="caption">
            {tasksInStage.length} checkpoints
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {tasksInStage.length === 0 ? (
          <Alert severity="info">There are no tasks in this section</Alert>
        ) : (
          <List sx={{ borderLeft: `2px solid ${theme.palette.text.disabled}` }}>
            {tasksInStage.map((task, index) => {
              return (
                <TaskCard
                  key={index}
                  task={task.task}
                  taskDetails={task.taskDetails}
                />
              );
            })}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

interface TaskCardProps {
  task: Task;
  taskDetails: FrontendTask;
}

export function TaskCard({ task, taskDetails }: TaskCardProps) {
  const { data: taskStatus, isLoading } = useTaskStatus(task.taskStatusUrl);
  const { data: user } = useCurrentOktaUser();

  const isTaskAssignedToCurrentUser = user?.email
    ? task.assignees.includes(user.email)
    : false;

  return (
    <ListItem
      sx={{ display: "flex", alignItems: "center", gap: "16px", padding: 0 }}
    >
      <Box flex="0 0 40px">
        {isTaskAssignedToCurrentUser ? (
          <Tooltip title="This task is assigned to you" arrow={true}>
            <AssignmentIndOutlined
              aria-label="This task is assigned to you"
              sx={{ fontSize: "24px", marginLeft: "16px" }}
            />
          </Tooltip>
        ) : null}
      </Box>
      <Box
        width="100%"
        borderRadius="4px"
        padding="16px"
        marginBottom="8px"
        boxShadow="0px 1px 3px 0px #0000001F"
        bgcolor={taskStatus?.completed ? "rgba(46, 125, 50, 0.12)" : "#fff"}
      >
        <Link
          component={RouterLink}
          to={taskDetails.taskRoute}
          sx={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
          }}
        >
          <TaskTitleIcon taskStatus={taskStatus} icon={taskDetails.icon} />
          <Typography variant="subtitle1" component="span">
            {taskDetails.description}
          </Typography>
        </Link>
        {isLoading ? <LinearProgress /> : <></>}
      </Box>
    </ListItem>
  );
}
