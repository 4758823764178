import { ListItemIcon } from "@mui/material";
import { CheckCircleOutlined } from "@mui/icons-material";
import { TaskStatusPayload } from "../types/types";

const TaskTitleIcon = ({
  icon,
  taskStatus,
}: {
  icon: JSX.Element;
  taskStatus: TaskStatusPayload | undefined;
}): JSX.Element => {
  return taskStatus?.completed ? (
    <ListItemIcon>
      <CheckCircleOutlined sx={{ fontSize: "40px" }} color="success" />
    </ListItemIcon>
  ) : (
    <ListItemIcon>{icon}</ListItemIcon>
  );
};

export default TaskTitleIcon;
