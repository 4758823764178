/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import {
  Typography,
  Stack,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useFormikContext } from "formik";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { startUpFormSchema } from "./StartupFormV2";

export type StartUpFormFormValues = Yup.InferType<typeof startUpFormSchema>;

const formTabNames: string[] = [
  "Attendees",
  "Basic Info",
  "Risk and Insurance",
  "Business",
  "Policies and Sustainability",
  "Contributors and Talent",
  "Technical Production Plan",
];

export function StartUpFormSteps({
  currentStepIndex,
  handleStepChange,
  autosaveDate,
  taskPageUrl,
  steps,
}: {
  currentStepIndex: number;
  handleStepChange: (stepIndex: number) => void;
  autosaveDate: string | undefined;
  taskPageUrl: string;
  steps: string[];
}) {
  const formik = useFormikContext<StartUpFormFormValues>();
  const [open, setOpen] = useState(false);

  const iconSize = {
    width: "1rem",
    height: "1rem",
    position: "relative",
    margin: 1,
  };

  return (
    <Stack spacing={2}>
      <Timeline sx={{ width: "270px", justifyContent: "space-between" }}>
        {steps.map((step, index) => {
          const stepNumber = index + 1;
          const errorFields = Object.keys(formik.errors);
          const hasErrors = errorFields.includes(step);
          const shouldDisplayErrors = formik.submitCount > 0;
          const isLastStep = index === steps.length - 1;

          return (
            <TimelineItem key={`step-${step}`}>
              <TimelineOppositeContent sx={{ display: "none" }} />
              <TimelineSeparator>
                {index === currentStepIndex ? (
                  <CircleIcon sx={{ ...iconSize }} />
                ) : (
                  <CircleOutlinedIcon sx={{ ...iconSize }} />
                )}
                {isLastStep ? <></> : <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent sx={{ paddingTop: 0 }}>
                <Button
                  type="button"
                  onClick={() => handleStepChange(index)}
                  variant="text"
                  sx={{ textAlign: "left", borderRadius: 0, padding: "4px 0" }}
                >
                  <Typography
                    color={
                      shouldDisplayErrors && hasErrors ? "error" : "primary"
                    }
                    component="span"
                  >
                    {stepNumber}. {formTabNames[index]}
                  </Typography>
                </Button>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>

      <Stack spacing={1} direction="row">
        <IconButton
          aria-label="Previous section"
          disabled={currentStepIndex === 0}
          color="primary"
          onClick={() => {
            const newStepIndex = Math.max(0, currentStepIndex - 1);
            handleStepChange(newStepIndex);
          }}
        >
          <ArrowBack />
        </IconButton>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={() => {
            const newStepIndex = Math.min(
              steps.length - 1,
              currentStepIndex + 1,
            );
            handleStepChange(newStepIndex);
          }}
          disabled={currentStepIndex === steps.length - 1}
          sx={{ fontWeight: "light" }}
          endIcon={<ArrowForward />}
        >
          Next Section
        </Button>
      </Stack>

      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        size="large"
        color="primary"
      >
        Save Form
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {"Your Answers are Saved"} <CheckIcon />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your answers have been saved you can now continue editing or return
            to the tasks page
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            component={RouterLink}
            to={taskPageUrl}
            variant="contained"
            size="large"
            color="primary"
          >
            Return to Task Page
          </Button>
          <Button onClick={() => setOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
