import { Snackbar, Alert } from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { sendRequestAccess } from "../../../../api/portal";
import { NoAccessDialog } from "../../../../components/NoAccessDialog";
import { OnboardingDialog } from "../../../../components/OnboardingDialog";
import { ViewOnlyBanner } from "../../../../components/ViewOnlyBanner";
import {
  useBrandMetadata,
  useTasks,
  useTitleMetadata,
} from "../../../../hooks/queries";

export const UserAccessAndOnboard: FC = () => {
  const { programmeCcid, titleCcid, seriesCcid } = useParams<string>();
  const [onboardingHidden, setOnboardingHidden] = useState<boolean>(
    localStorage.getItem("onboardingHidden") === "true",
  );
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [ackedModal, setAckedModal] = useState<boolean>(false);
  const { data: brandMetaData } = useBrandMetadata(programmeCcid);
  const { data: titleMetaData } = useTitleMetadata(titleCcid);
  const { data: tasksData, isLoading: isLoadingTasks } = useTasks(
    titleCcid || seriesCcid || programmeCcid,
  );
  const tasks = tasksData?.tasks || [];
  const hasSubTasks = tasksData?.hasSubTasks ?? true;
  const isOneOff = Boolean(titleCcid && !programmeCcid && !seriesCcid);
  const metaData = isOneOff ? titleMetaData : brandMetaData;
  const ccid = titleCcid || seriesCcid || programmeCcid || "";
  const closeOnboarding = () => {
    localStorage.setItem("onboardingHidden", "true");
    setOnboardingHidden(true);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const sendAccessRequest = useCallback(
    (e: React.SyntheticEvent) => {
      if (isOneOff && (!titleMetaData || !titleCcid)) {
        return;
      }

      if (!isOneOff && (!brandMetaData?.brandName || !programmeCcid)) {
        return;
      }

      e.preventDefault();
      sendRequestAccess(ccid, {})
        .then(() => {
          setAckedModal(true);
          setSnackbarOpen(true);
        })
        .catch(() => {
          setAckedModal(true);
        });
    },
    [
      isOneOff,
      titleMetaData,
      titleCcid,
      brandMetaData?.brandName,
      programmeCcid,
      ccid,
    ],
  );

  return (
    <>
      <OnboardingDialog
        open={!onboardingHidden}
        closeOnboarding={closeOnboarding}
      />
      <NoAccessDialog
        open={
          !isLoadingTasks &&
          !ackedModal &&
          tasks?.length === 0 &&
          !hasSubTasks &&
          onboardingHidden &&
          Boolean(metaData)
        }
        viewOnlyBtnClick={() => {
          setAckedModal(true);
        }}
        requestAccessBtnClick={sendAccessRequest}
      />
      {tasks?.length === 0 && !hasSubTasks && Boolean(metaData) ? (
        <ViewOnlyBanner requestAccessBtnClick={sendAccessRequest} />
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Access has been requested!
        </Alert>
      </Snackbar>
    </>
  );
};
