import { AxiosResponse } from "axios";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import {
  BrandTitleResponsePayload,
  ProgrammeSearchPayload,
  SeriesResponsePayload,
  SeriesMetadata,
  TasksResponsePayload,
  CcidDropdownResponse,
  CcidNewBrandSeriesPayload,
  CcidNewBrandedSpecialPayload,
  CcidCreateSeriesPayload,
  CcidNewOneOffOrFilmPayload,
  CcidCreateBrandedSpecialPayload,
  SeriesVersionsResponse,
  Title,
  ProductionisePayload,
  ProductionCardResponse,
  CcidMetadata,
  TitleDetailsToUpdate,
  ActiveProductionsResponse,
  Metadata,
  BrandMetadataResponsePayload,
  TitleMetadataResponsePayload,
  TaskGroupAssignmentPayload,
  TaskGroups,
  AssignedProductionResponse,
  TaskGroupAssignmentResponse,
  Treatments,
  GetBrandsParams,
  CreatePostProductionContactRequest,
  VacanciesResponse,
  UpdateVacanciesPayload,
} from "../types/types";

const API_BASE = getAPI(SERVICES.portal);

export const getActiveProductions = (): Promise<ActiveProductionsResponse> =>
  handleRequest
    .get(`${API_BASE}/activeProductions/productionDetails`)
    .then(({ data }) => ({ ...data }));

export const getAssignedProductions = (): Promise<AssignedProductionResponse> =>
  handleRequest
    .get(`${API_BASE}/assignments`)
    .then(({ data }) => ({ ...data }));

export const getBrands = (
  value: string,
  limit?: number,
  includeOpportunity?: boolean,
  filterByUserLabels?: boolean,
): Promise<ProgrammeSearchPayload> => {
  const url = `${API_BASE}/brands`;

  const params: GetBrandsParams = {
    searchTerm: value,
  };

  if (limit !== undefined) {
    params.limit = limit;
  }

  if (includeOpportunity) {
    params.includeOpportunity = true;
  }

  if (filterByUserLabels) {
    params.filterByUserLabels = true;
  }

  return handleRequest
    .get(url, { params })
    .then(({ data }: AxiosResponse) => data);
};

export const getSeries = (
  ccid: string,
  includeOpportunity?: boolean,
): Promise<SeriesResponsePayload> => {
  const url = includeOpportunity
    ? `${API_BASE}/brands/${ccid}/series?includeOpportunity=true`
    : `${API_BASE}/brands/${ccid}/series`;
  return handleRequest
    .get(url, {
      params: { limit: "20" },
    })
    .then(({ data }: AxiosResponse) => data);
};

export const getTitles = (
  key: string,
  ccid: string,
  includeOpportunity: boolean,
): Promise<BrandTitleResponsePayload> => {
  const url = includeOpportunity
    ? `${API_BASE}/${key}/${ccid}/titles?includeOpportunity=true`
    : `${API_BASE}/${key}/${ccid}/titles`;
  return handleRequest
    .get(url, {
      params: { limit: "20" },
    })
    .then(({ data }: AxiosResponse) => data);
};

export const getBrandMetadata = (
  brandCcid: string,
  includeOpportunity?: boolean,
): Promise<BrandMetadataResponsePayload> => {
  const url = includeOpportunity
    ? `${API_BASE}/brands/${brandCcid}/metadata?includeOpportunity=true`
    : `${API_BASE}/brands/${brandCcid}/metadata`;
  return handleRequest.get(url).then(({ data }) => data);
};

export const getSeriesMetadata = (
  seriesCcid: string,
): Promise<SeriesMetadata> =>
  handleRequest
    .get(`${API_BASE}/series/${seriesCcid}/metadata`)
    .then(({ data }) => data);

export const getSeriesMetadataMultiple = (
  seriesCcids: string[],
): Promise<SeriesMetadata[]> => {
  const requests = seriesCcids.map((seriesCcid) =>
    handleRequest.get(`${API_BASE}/series/${seriesCcid}/metadata`),
  );

  return Promise.all(requests).then((responses) =>
    responses.map(({ data }) => data),
  );
};

export const getTitleMetadata = (
  titleCcid: string,
): Promise<TitleMetadataResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/titles/${titleCcid}/metadata?includeOpportunity=true`)
    .then(({ data }) => data);

export const getMetadata = (ccid: string): Promise<Metadata> =>
  handleRequest
    .get(`${API_BASE}/ccid/${ccid}/metadata`)
    .then(({ data }) => ({ ...data, ccid }));

export const getMetadataIncludingOpportunity = (
  ccid: string,
): Promise<Metadata> =>
  handleRequest
    .get(`${API_BASE}/ccid/${ccid}/metadata?includeOpportunity=true`)
    .then(({ data }) => ({ ...data, ccid }));

export const getCcidInfo = (ccid: string): Promise<CcidMetadata> =>
  handleRequest.get(`${API_BASE}/ccids/${ccid}`).then(({ data }) => data);

export const getSeriesVersions = (
  seriesCcid: string,
  limit: number,
  offset: number,
): Promise<SeriesVersionsResponse> =>
  handleRequest
    .get(
      `${API_BASE}/series/${seriesCcid}/versions?limit=${limit}&offset=${offset}`,
    )
    .then(({ data }) => data);

export const getTitleVersions = (
  titleCcid: string,
): Promise<SeriesVersionsResponse> =>
  handleRequest
    .get(`${API_BASE}/titles/${titleCcid}/versions`)
    .then(({ data }) => data);

export const getProductionCardDetails = (
  ccid: string,
): Promise<ProductionCardResponse> =>
  handleRequest
    .get(`${API_BASE}/${ccid}/productioncard`)
    .then(({ data }) => data);

export const sendContactMsg = (
  payload: Record<string, string>,
): Promise<AxiosResponse> =>
  handleRequest
    .post(`${API_BASE}/contact`, payload)
    .then(({ data }: AxiosResponse) => data);

export const sendRequestAccess = (
  ccid: string,
  payload: Record<string, string>,
): Promise<AxiosResponse> =>
  handleRequest
    .post(`${API_BASE}/ccids/${ccid}/request-access`, payload)
    .then(({ data }: AxiosResponse) => data);

export const getTasksForCcid = (ccid: string): Promise<TasksResponsePayload> =>
  handleRequest.get(`${API_BASE}/ccid/${ccid}/tasks`).then(({ data }) => data);

export const getProgrammeTasks = (
  programmeCcid: string | undefined,
): Promise<TasksResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/brands/${programmeCcid}/tasks`)
    .then(({ data }) => data);

export const getSeriesTasks = (
  seriesCcid: string | undefined,
): Promise<TasksResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/series/${seriesCcid}/tasks`)
    .then(({ data }) => data);

export const getTitleTasks = (
  titleCcid: string | undefined,
): Promise<TasksResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/titles/${titleCcid}/tasks`)
    .then(({ data }) => data);

export interface UpdateCatalogueItemNameRequest {
  value: string;
}

export const updateTitleName = (
  titleCcid: string,
  name: string,
): Promise<void> =>
  handleRequest
    .put<UpdateCatalogueItemNameRequest>(
      `${API_BASE}/titles/${titleCcid}/name`,
      { value: name },
    )
    .then(() => undefined);

export const updateSeriesName = (
  seriesCcid: string,
  name: string,
): Promise<void> =>
  handleRequest
    .put<UpdateCatalogueItemNameRequest>(
      `${API_BASE}/series/${seriesCcid}/name`,
      { value: name },
    )
    .then(() => undefined);

export const updateBrandName = (
  brandCcid: string,
  name: string,
): Promise<void> =>
  handleRequest
    .put<UpdateCatalogueItemNameRequest>(
      `${API_BASE}/brands/${brandCcid}/name`,
      { value: name },
    )
    .then(() => undefined);

export const getCcidMetadata = (): Promise<CcidDropdownResponse> =>
  handleRequest.get(`${API_BASE}/ccid/dropdowns`).then(({ data }) => data);

export const createBrandSeries = (
  payload: CcidNewBrandSeriesPayload,
): Promise<void> =>
  handleRequest
    .post(`${API_BASE}/brands/series`, payload)
    .then(({ data }) => data);

export const createBrandWithBrandedSpecial = (
  payload: CcidNewBrandedSpecialPayload,
): Promise<void> =>
  handleRequest
    .post(`${API_BASE}/brands/specials`, payload)
    .then(({ data }) => data);

export const createOneOff = (
  payload: CcidNewOneOffOrFilmPayload,
): Promise<void> =>
  handleRequest.post(`${API_BASE}/oneoffs`, payload).then(({ data }) => data);

export const createFilm = (
  payload: CcidNewOneOffOrFilmPayload,
): Promise<void> =>
  handleRequest.post(`${API_BASE}/films`, payload).then(({ data }) => data);

export const createBrandedSpecial = (
  brandCcid: string,
  payload: CcidCreateBrandedSpecialPayload,
): Promise<void> =>
  handleRequest
    .post(`${API_BASE}/brands/${brandCcid}/specials`, payload)
    .then(({ data }) => data);

export const createSeries = (
  brandCcid: string,
  payload: CcidCreateSeriesPayload,
): Promise<void> =>
  handleRequest
    .post(`${API_BASE}/brands/${brandCcid}/series`, payload)
    .then(({ data }) => data);

export const createEpisode = (
  seriesCcid: string,
  payload: Title,
): Promise<void> =>
  handleRequest
    .post(`${API_BASE}/series/${seriesCcid}/titles`, payload)
    .then(({ data }) => data);

export const deleteEpisode = (titleCcid: string): Promise<void> =>
  handleRequest
    .delete(`${API_BASE}/titles/${titleCcid}`)
    .then(({ data }) => data);

export const updateEpisode = (
  titleCcid: string,
  payload: TitleDetailsToUpdate,
): Promise<void> =>
  handleRequest
    .patch(`${API_BASE}/titles/${titleCcid}`, payload)
    .then(({ data }) => data);

export const checkTitleAssets = (titleCcid: string): Promise<void> =>
  handleRequest
    .get(`${API_BASE}/titles/${titleCcid}/assets`)
    .then(({ data }) => data);

export const productioniseBrand = (
  brandCcid: string,
  payload: ProductionisePayload,
): Promise<void> =>
  handleRequest
    .patch(`${API_BASE}/brands/${brandCcid}/productionise`, payload)
    .then(({ data }) => data);

export const productioniseSeries = (
  seriesCcid: string,
  payload: ProductionisePayload,
): Promise<void> =>
  handleRequest
    .patch(`${API_BASE}/series/${seriesCcid}/productionise`, payload)
    .then(({ data }) => data);

export const productioniseTitle = (
  ccid: string,
  payload: ProductionisePayload,
): Promise<void> =>
  handleRequest
    .patch(`${API_BASE}/title/${ccid}/productionise`, payload)
    .then(({ data }) => data);

export const getTaskGroupsData = (
  ccid: string,
): Promise<TaskGroupAssignmentResponse> =>
  handleRequest
    .get(`${API_BASE}/tasks/${ccid}/assignments`)
    .then(({ data }) => data);

export const getAssignedTasks = (ccid: string): Promise<TasksResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/ccid/${ccid}/assigned`)
    .then(({ data }) => data);

export const updateTaskGroup = (
  ccid: string,
  users: string[],
  taskGroup: TaskGroups,
): Promise<string[]> => {
  const requests = users.map((user) =>
    handleRequest.put<TaskGroupAssignmentPayload>(
      `${API_BASE}/ccid/${ccid}/tasks`,
      { user, taskGroup },
    ),
  );

  return Promise.all(requests).then((responses) =>
    responses.map(({ data }) => data),
  );
};

export const deleteTaskGroupUser = (
  ccid: string,
  users: string[],
  taskGroup: TaskGroups,
): Promise<string[]> => {
  const requests = users.map((user) =>
    handleRequest.delete<TaskGroupAssignmentPayload>(
      `${API_BASE}/ccid/${ccid}/tasks`,
      { user, taskGroup },
    ),
  );

  return Promise.all(requests).then((responses) =>
    responses.map(({ data }) => data),
  );
};

export const getTreatments = (ccid: string | undefined): Promise<Treatments> =>
  handleRequest
    .get(`${API_BASE}/airtable/production/${ccid}/treatment`)
    .then(({ data }) => data);

export const getHiringVacancies = (): Promise<VacanciesResponse> =>
  handleRequest.get(`${API_BASE}/vacancies`).then(({ data }) => data);

export const updateHiringVacancyStatus = (
  vacancyData: UpdateVacanciesPayload,
): Promise<""> =>
  handleRequest
    .put(`${API_BASE}/vacancies`, vacancyData)
    .then(({ data }) => data as "");

export const createPostProductionContact = ({
  ccid,
  payload,
}: {
  ccid: string;
  payload: CreatePostProductionContactRequest;
}): Promise<void> => {
  return handleRequest
    .post(`${API_BASE}/ccid/${ccid}/post-production-contact`, payload)
    .then((_) => void 0);
};
