/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Formik, Form, FormikValues, FormikErrors } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  CardContent,
  Card,
  Alert,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { enqueueSnackbar } from "notistack";
import ReactToPrint from "react-to-print";
import { FormHeader } from "../../../../components";
import { ModalWrapper } from "../../../../components/ModalWrapper";
import {
  useStartup,
  useUpdateStartup,
  useProductionDetails,
  useUpdateStartupProductionDetails,
  useMetadataDropdownNew,
} from "../../../../hooks";
import { TITLES, SERIES } from "../../../../constants";
import { NoAccess } from "../../../../components/NoAccess";
import theme from "../../../../utils/theme";
import { BasicInfoForm } from "./BasicInfoForm";
import { RiskAndInsuranceForm } from "./RiskAndInsuranceForm";
import { BusinessForm } from "./BusinessForm";
import { PoliciesAndSustainabilityForm } from "./PoliciesAndSustainabilityForm";
import { ContributorsAndTalentForm } from "./ContributorsAndTalentForm";
import { TechnicalProductionPlanForm } from "./TechnicalProductionPlanForm";
import { AttendeesForm } from "./AttendeesForm";
import { SnackbarDismiss } from "../../../../componentsV2/SnackBarDismiss";
import { StartUpFormSteps } from "./StartupFormSteps";
import dayjs from "dayjs";

const getLatestDate = (formData: any) =>
  Object.values(formData)
    .filter((item: any) => Boolean(item.lastUpdated))
    .map((item: any) => item.lastUpdated)
    .sort((date1, date2) => +dayjs(date1) - +dayjs(date2))
    .pop();

export const startUpFormSchema = Yup.object().shape({
  basicInfo: Yup.object().shape({
    productionStatus: Yup.string().required("Required"),
    productionType: Yup.string().required("Required"),
    otherProductionType: Yup.string().required("Required"),
    broadcaster: Yup.string(),
    otherBroadcaster: Yup.string().required("Required"),
    contentType: Yup.string().required("Required"),
    recordingType: Yup.string(),
    outsideLocation: Yup.boolean().required("Required"),
    productionLabel: Yup.string(),
    productionContactName: Yup.string().required("Required"),
    productionContactEmail: Yup.string()
      .email("Invalid email")
      .required("Required"),
    creativeLeadName: Yup.string().required("Required"),
    creativeLeadEmail: Yup.string().email("Invalid email").required("Required"),
  }),
  riskAndInsurances: Yup.object().shape({
    insurances: Yup.string(),
    previousSeriesIssues: Yup.string(),
    businessRisks: Yup.string(),
    businessContinuityPlan: Yup.string(),
    incidentAndCrisisManagement: Yup.string(),
    workingTimeIssues: Yup.string(),
    smartWorking: Yup.string(),
    healthAndSafety: Yup.string(),
    covid19: Yup.string(),
    additionalNotes: Yup.string(),
    // Add other fields here
  }),
});

const intialFormikFormData = {
  attendee: {
    attendees: [],
  },
  basicInfo: {
    productionStatus: "",
    productionType: "",
    otherProductionType: "",
    broadcaster: "",
    otherBroadcaster: "",
    contentType: "",
    recordingType: "",
    outsideLocation: "",
    productionLabel: "",
    productionContactName: "",
    productionContactEmail: "",
    creativeLeadName: "",
    creativeLeadEmail: "",
  },
  riskAndInsurance: {
    insurances: "",
    previousSeriesIssues: "",
    businessRisks: "",
    businessContinuityPlan: "",
    incidentAndCrisisManagement: "",
    workingTimeIssues: "",
    smartWorking: "",
    healthAndSafety: "",
    covid19: "",
    additionalNotes: "",
  },
  business: {
    coFundingOrAdditionalIncome: "",
    costFormSubmissionDate: "",
    programmeTitleClearance: "",
    bribery: "",
    sanctions: "",
    format: "",
    releaseForms: "",
    contestants: "",
    rights: "",
    broadcastClearances: "",
    datesPerEpisode: false,
    seriesDeliveryDate: "",
    deliveryDates: "",
    additionalNotes: "",
  },
  contributorsAndTalent: {
    contributors: [],
    membersOfPublic: "",
    paidAdvertising: "",
    socialMediaSourcing: "",
    formatBackground: "",
    visaOrChildLicences: "",
    talentPayments: "",
    dutyOfCare: "",
    additionalNotes: "",
  },
  policiesAndSustainability: {
    policiesShared: false,
    producersHandbookShared: false,
    albert: false,
    carbonActionPlanStarted: false,
    sustainableInitiatives: "",
    greenTeamContact: "",
    privacyAndData: "",
    additionalNotes: "",
  },
  technicalProductionPlan: {
    deliveryFormat: "",
    broadcastClearances: "",
    versions: "",
    internationalVersionsRequired: false,
    productionBibleRequired: false,
    pressAndPublicity: "",
    interactiveInvolvement: "",
    prizesAndPrtsInfo: "",
    technologyWorkflow: "",
    mediaRetention: "",
    digital: "",
    compliance: "",
    music: "",
    procurement: "",
    additionalNotes: "",
  },
};

export interface NotificationFormProps {
  formTitle?: string;
}

export const StartupFormV2: FC<NotificationFormProps> = ({
  formTitle = "Start Up form",
}) => {
  const { seriesCcid, titleCcid, programmeCcid } = useParams();
  const type = titleCcid ? TITLES : SERIES;
  const ccid = titleCcid || seriesCcid;

  const taskPageUrl =
    type === "titles"
      ? `/special/${titleCcid}`
      : `/programmes/${programmeCcid}/series/${seriesCcid}`;

  const {
    data: startupFormData,
    error: startupError,
    isLoading: loadingStartup,
  } = useStartup({ ccid, type });
  const { data: startupFormProductionData, isLoading: loadingProdDetails } =
    useProductionDetails(ccid);
  const { data: metaDataDropdown } = useMetadataDropdownNew();
  const [autoSaveAt, setAutoSaveAt] = useState<string>("");

  const setAutoSaveDate = useCallback((response: any) => {
    const latestDate = getLatestDate(response) || new Date().toISOString();
    setAutoSaveAt(latestDate);
  }, []);

  const cardRef = useRef<any>();
  const componentRef = useRef(null);
  type Tab =
    | "attendee"
    | "basicInfo"
    | "riskAndInsurance"
    | "business"
    | "policiesAndSustainability"
    | "contributorsAndTalent"
    | "technicalProductionPlan";

  const formTabs: Tab[] = useMemo(() => {
    return [
      "attendee",
      "basicInfo",
      "riskAndInsurance",
      "business",
      "policiesAndSustainability",
      "contributorsAndTalent",
      "technicalProductionPlan",
    ];
  }, []);

  const formTabNames: string[] = useMemo(() => {
    return [
      "Attendees",
      "Basic Info",
      "Risk and Insurance",
      "Business",
      "Policies and Sustainability",
      "Contributors and Talent",
      "Technical Production Plan",
    ];
  }, []);

  const [printModalOpen, setPrintModalOpen] = useState<boolean>(false);
  const [confirmationDialogOpen, SetConfirmationDialogOpen] =
    useState<boolean>(false);

  const [formData, setFormData] = useState<any>(intialFormikFormData);
  const [step, setStep] = useState<number>(0);

  const deepMerge = useCallback(
    (obj1: { [x: string]: any }, obj2: { [x: string]: any }) => {
      const result = { ...obj1 };
      for (const key in obj2) {
        if (Object.prototype.hasOwnProperty.call(obj2, key)) {
          if (typeof obj2[key] === "object" && obj1[key]) {
            result[key] = deepMerge(obj1[key], obj2[key]);
          } else {
            result[key] = obj2[key];
          }
        }
      }
      return result;
    },
    [],
  );

  const formatCamelCase = (s: string) =>
    s.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());

  const { mutate: updateStartUp } = useUpdateStartup(
    (response) => {
      setAutoSaveDate(response);
      enqueueSnackbar("Content Autosaved", { variant: "success" });
    },
    () => {
      enqueueSnackbar("There is an error on saving the form data", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  const { mutate: updateProdFormData } = useUpdateStartupProductionDetails(
    (response) => {
      setAutoSaveDate(response);
      enqueueSnackbar("Content Autosaved", { variant: "success" });
    },
    () => {
      enqueueSnackbar("There is an error on saving the form data", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  useEffect(() => {
    if (startupFormData && startupFormProductionData) {
      // Refine the Production details data
      const coreCatBasicInfo = startupFormProductionData.basicInfo
        .coreCatalogueData as any;
      const basicInfoProdData = {
        basicInfo: startupFormProductionData.basicInfo,
        productionStatus: startupFormProductionData.productionStatus || "",
        productionType: startupFormProductionData.productionType || "",
        otherProductionType:
          startupFormProductionData.otherProductionType || "",
        broadcaster: startupFormProductionData.broadcaster || "",
        otherBroadcaster: startupFormProductionData.otherBroadcaster || "",
        contentType: startupFormProductionData.contentType || "",
        recordingType: startupFormProductionData.recordingType || "",
        outsideLocation: startupFormProductionData.outsideLocation || "",
        productionLabel: coreCatBasicInfo?.productionLabels[0] || "",
        productionContactName:
          startupFormProductionData.productionContactName || "",
        productionContactEmail:
          startupFormProductionData.productionContactEmail || "",
        creativeLeadName: startupFormProductionData.creativeLeadName || "",
        creativeLeadEmail: startupFormProductionData.creativeLeadEmail || "",
      };

      const startUpWithBasicInfo = {
        attendee: startupFormData.attendee || {
          attendees: [],
        },
        basicInfo: basicInfoProdData,
        riskAndInsurance: {
          insurances: startupFormData.riskAndInsurance?.insurances,
          previousSeriesIssues:
            startupFormData.riskAndInsurance?.previousSeriesIssues,
          businessRisks: startupFormData.riskAndInsurance?.businessRisks,
          businessContinuityPlan:
            startupFormData.riskAndInsurance?.businessContinuityPlan,
          incidentAndCrisisManagement:
            startupFormData.riskAndInsurance?.incidentAndCrisisManagement,
          workingTimeIssues:
            startupFormData.riskAndInsurance?.workingTimeIssues,
          smartWorking: startupFormData.riskAndInsurance?.smartWorking,
          healthAndSafety: startupFormData.riskAndInsurance?.healthAndSafety,
          covid19: startupFormData.riskAndInsurance?.covid19,
          additionalNotes: startupFormData.riskAndInsurance?.additionalNotes,
        },
        business: {
          coFundingOrAdditionalIncome:
            startupFormData.business?.coFundingOrAdditionalIncome,
          costFormSubmissionDate:
            startupFormData.business?.costFormSubmissionDate,
          programmeTitleClearance:
            startupFormData.business?.programmeTitleClearance,
          bribery: startupFormData.business?.bribery,
          sanctions: startupFormData.business?.sanctions,
          format: startupFormData.business?.format,
          releaseForms: startupFormData.business?.releaseForms,
          contestants: startupFormData.business?.contestants,
          rights: startupFormData.business?.rights,
          broadcastClearances: startupFormData.business?.broadcastClearances,
          datesPerEpisode: startupFormData.business?.datesPerEpisode || false,
          seriesDeliveryDate: startupFormData.business?.seriesDeliveryDate,
          deliveryDates: startupFormData.business?.deliveryDates,
          additionalNotes: startupFormData.business?.additionalNotes,
        },
        contributorsAndTalent: {
          contributors:
            startupFormData.contributorsAndTalent?.contributors || [],
          membersOfPublic:
            startupFormData.contributorsAndTalent?.membersOfPublic,
          paidAdvertising:
            startupFormData.contributorsAndTalent?.paidAdvertising,
          socialMediaSourcing:
            startupFormData.contributorsAndTalent?.socialMediaSourcing,
          formatBackground:
            startupFormData.contributorsAndTalent?.formatBackground,
          visaOrChildLicences:
            startupFormData.contributorsAndTalent?.visaOrChildLicences,
          talentPayments: startupFormData.contributorsAndTalent?.talentPayments,
          dutyOfCare: startupFormData.contributorsAndTalent?.dutyOfCare,
          additionalNotes:
            startupFormData.contributorsAndTalent?.additionalNotes,
        },
        policiesAndSustainability: {
          policiesShared:
            startupFormData.policiesAndSustainability?.policiesShared || false,
          producersHandbookShared:
            startupFormData.policiesAndSustainability
              ?.producersHandbookShared || false,
          albert: startupFormData.policiesAndSustainability?.albert || false,
          carbonActionPlanStarted:
            startupFormData.policiesAndSustainability
              ?.carbonActionPlanStarted || false,
          sustainableInitiatives:
            startupFormData.policiesAndSustainability?.sustainableInitiatives,
          greenTeamContact:
            startupFormData.policiesAndSustainability?.greenTeamContact,
          privacyAndData:
            startupFormData.policiesAndSustainability?.privacyAndData,
          additionalNotes:
            startupFormData.policiesAndSustainability?.additionalNotes,
        },
        technicalProductionPlan: {
          deliveryFormat:
            startupFormData.technicalProductionPlan?.deliveryFormat,
          broadcastClearances:
            startupFormData.technicalProductionPlan?.broadcastClearances,
          versions: startupFormData.technicalProductionPlan?.versions,
          internationalVersionsRequired:
            startupFormData.technicalProductionPlan
              ?.internationalVersionsRequired || false,
          productionBibleRequired:
            startupFormData.technicalProductionPlan?.productionBibleRequired ||
            false,
          pressAndPublicity:
            startupFormData.technicalProductionPlan?.pressAndPublicity,
          interactiveInvolvement:
            startupFormData.technicalProductionPlan?.interactiveInvolvement,
          prizesAndPrtsInfo:
            startupFormData.technicalProductionPlan?.prizesAndPrtsInfo,
          technologyWorkflow:
            startupFormData.technicalProductionPlan?.technologyWorkflow,
          mediaRetention:
            startupFormData.technicalProductionPlan?.mediaRetention,
          digital: startupFormData.technicalProductionPlan?.digital,
          compliance: startupFormData.technicalProductionPlan?.compliance,
          music: startupFormData.technicalProductionPlan?.music,
          procurement: startupFormData.technicalProductionPlan?.procurement,
          additionalNotes:
            startupFormData.technicalProductionPlan?.additionalNotes,
        },
      };

      /* TODO: Can we do something better here? */
      startUpWithBasicInfo.basicInfo.outsideLocation = String(
        startUpWithBasicInfo.basicInfo.outsideLocation,
      );

      setFormData((prevFormData: { [key: string]: any }) =>
        deepMerge(prevFormData, startUpWithBasicInfo),
      );
    }
  }, [startupFormData, startupFormProductionData, deepMerge]);

  const blurHandler = useCallback(
    (id: string, newValue: any) => {
      // code to save update in the backend.

      if (formTabs[step] === "basicInfo") {
        const newId = id.split(".")[1];
        updateProdFormData({
          ccid: ccid as string,
          formData: { [newId]: newValue },
        });
      } else {
        updateStartUp({
          ccid: ccid as string,
          formData: { [id.split(".")[0]]: { [id.split(".")[1]]: newValue } },
          type,
        });
      }
    },
    [step, formTabs, ccid, updateStartUp, type, updateProdFormData],
  );

  const tabFields = (
    values: FormikValues,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<any>>,
  ): Record<Tab, JSX.Element> => ({
    attendee: (
      <AttendeesForm
        attendees={Object.values(values.attendee.attendees)}
        setFieldValue={setFieldValue}
        blurHandler={blurHandler}
      />
    ),
    basicInfo: (
      <BasicInfoForm
        values={values}
        metaDataDropdown={metaDataDropdown}
        blurHandler={blurHandler}
      />
    ),
    riskAndInsurance: (
      <RiskAndInsuranceForm
        isPrinting={printModalOpen}
        values={values}
        blurHandler={blurHandler}
        setFieldValue={setFieldValue}
      />
    ),
    business: (
      <BusinessForm
        isPrinting={printModalOpen}
        values={values}
        setFieldValue={setFieldValue}
        blurHandler={blurHandler}
      />
    ),
    policiesAndSustainability: (
      <PoliciesAndSustainabilityForm
        isPrinting={printModalOpen}
        values={values}
        blurHandler={blurHandler}
        setFieldValue={setFieldValue}
      />
    ),
    contributorsAndTalent: (
      <ContributorsAndTalentForm
        isPrinting={printModalOpen}
        values={values}
        blurHandler={blurHandler}
        setFieldValue={setFieldValue}
      />
    ),
    technicalProductionPlan: (
      <TechnicalProductionPlanForm
        isPrinting={printModalOpen}
        values={values}
        blurHandler={blurHandler}
        setFieldValue={setFieldValue}
      />
    ),
  });

  if (startupError) return <NoAccess />;

  return (
    <>
      <FormHeader formTitle={formTitle} showFormStatus />
      {loadingStartup || loadingProdDetails ? (
        <Stack alignItems="center" m={4}>
          <CircularProgress />
        </Stack>
      ) : (
        <Stack ref={cardRef}>
          <Dialog open={confirmationDialogOpen}>
            <Stack direction="column" spacing={2} sx={{ m: 2 }}>
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                Your answers have been saved
              </Alert>
              <Button
                variant="contained"
                color="primary"
                onClick={() => SetConfirmationDialogOpen(false)}
              >
                Close
              </Button>
            </Stack>
          </Dialog>
          <Formik
            initialValues={formData}
            validationSchema={startUpFormSchema}
            // eslint-disable-next-line
            onSubmit={() => {}}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Container fixed disableGutters>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <StartUpFormSteps
                      currentStepIndex={step}
                      handleStepChange={setStep}
                      autosaveDate={autoSaveAt}
                      steps={formTabs}
                      taskPageUrl={taskPageUrl}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Stack>
                      <Box paddingTop={1}>
                        <Stack
                          direction="row"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h5">
                            Section {step + 1} of {formTabNames.length}:{" "}
                            {formTabNames[step]}{" "}
                          </Typography>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setPrintModalOpen(true);
                            }}
                          >
                            Download PDF
                          </Button>
                        </Stack>
                        <Typography marginTop={2}>
                          Please fill out the following fields to the best of
                          your knowledge to prepare for the start up meeting
                        </Typography>
                        <Box marginTop="1.5rem">
                          <Form>
                            <Stack spacing={3}>
                              {tabFields(values, setFieldValue)[formTabs[step]]}
                              <div ref={componentRef}>
                                {printModalOpen ? (
                                  Object.entries(
                                    tabFields(values, setFieldValue),
                                  ).map(([key, field]) => (
                                    <div
                                      key={key}
                                      style={{ pageBreakBefore: "always" }}
                                    >
                                      <Card>
                                        <CardContent sx={{ padding: "1rem" }}>
                                          <Typography variant="h5">
                                            {formatCamelCase(key)}
                                          </Typography>

                                          {field}
                                        </CardContent>
                                      </Card>
                                    </div>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </div>
                            </Stack>
                          </Form>
                          <Typography fontStyle="italic" marginTop={2}>
                            Indicates a required field{" "}
                            <Typography
                              color={theme.palette.sunsetOrange}
                              display="inline-block"
                            >
                              *
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Formik>
        </Stack>
      )}
      <ModalWrapper />
      <Dialog open={printModalOpen}>
        <Stack direction="column" spacing={2} sx={{ m: 2 }}>
          <Typography>
            Please confirm that you would like to download the current form
            using the save to PDF print function (this may take a few seconds)
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ m: 2, justifyContent: "space-between", display: "flex" }}
          >
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary">
                  Confirm Print to PDF
                </Button>
              )}
              content={() => componentRef.current}
            />
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setPrintModalOpen(false)}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
