import { FC } from "react";
import {
  FormControl,
  Stack,
  MenuItem,
  Select,
  Typography,
  Box,
  ListSubheader,
  Divider,
  selectClasses,
  inputClasses,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { useUpdateStartupProductionDetails } from "../../hooks";
import EditIcon from "@mui/icons-material/Edit";
import TimelineDot from "@mui/lab/TimelineDot";
import { ProductionCardResponse, ProductionStatus } from "../../types/types";
import theme from "../../utils/theme";
import { SnackbarDismiss } from "../SnackBarDismiss";

const ProductionCardStatusSelect: FC<{
  productionStatus: ProductionStatus | null | undefined;
  canEdit: boolean;
  ccid: string;
  recentProductionCard?: boolean;
}> = ({ productionStatus, canEdit, ccid, recentProductionCard }) => {
  const queryClient = useQueryClient();
  const {
    mutate: updateProductionStatus,
    isLoading: loadingUpdateProductionStatus,
  } = useUpdateStartupProductionDetails(
    () => {
      queryClient.invalidateQueries(["productionCardDetails", ccid]);
      enqueueSnackbar("Production status updated", { variant: "success" });
    },
    (_, __, context) => {
      if (context && context.previousCardDetails) {
        queryClient.setQueryData(
          ["productionCardDetails", ccid],
          context.previousCardDetails,
        );
      }

      enqueueSnackbar(
        `there was an error saving the production status for this show`,
        { variant: "error", action: SnackbarDismiss },
      );
    },
    async (requestObject) => {
      await queryClient.cancelQueries(["productionCardDetails", ccid]);

      const previousCardDetails = queryClient.getQueryData([
        "productionCardDetails",
        ccid,
      ]) as ProductionCardResponse;

      if (requestObject && Object.hasOwn(requestObject, "formData")) {
        queryClient.setQueryData(["productionCardDetails", ccid], {
          ...previousCardDetails,
          ...requestObject.formData,
        });
      }

      return {
        previousCardDetails: previousCardDetails as ProductionCardResponse,
      };
    },
  );

  const handleSelectChange = (status: ProductionStatus | "") => {
    if (status !== "") {
      updateProductionStatus({
        ccid,
        formData: { productionStatus: status },
      });
    }
  };

  if (!productionStatus && !loadingUpdateProductionStatus)
    return (
      <FormControl variant="standard" size="small" fullWidth>
        <Select
          id="status"
          label="Status"
          disabled
          disableUnderline
          value="Unavailable"
          IconComponent={() => <></>}
          sx={{
            // TODO: there is probably a better way to do this
            // (maybe even just using the `select` class with `!important` is less flimsy than this)
            [`& .${selectClasses.select}.${selectClasses.standard}.${inputClasses.input}`]:
              {
                padding: 0,
                paddingRight: 0,
              },
          }}
        >
          <MenuItem value="Unavailable">
            <Stack direction="row" alignItems="center" gap="16px">
              <Box color={theme.palette.grey[400]}>
                <TimelineDot
                  color="inherit"
                  sx={{
                    fill: theme.palette.grey[400],
                    backgroundColor: undefined,
                  }}
                  variant="outlined"
                />
              </Box>
              <Typography
                variant="body1"
                sx={{ WebkitTextFillColor: theme.palette.text.primary }}
              >
                Unavailable
              </Typography>
            </Stack>
          </MenuItem>
        </Select>
      </FormControl>
    );

  const isDisabled = !canEdit || !productionStatus;

  return (
    <FormControl variant="standard" size="small" fullWidth>
      <Select
        id="status"
        label="Status"
        value={productionStatus}
        disabled={isDisabled}
        disableUnderline={isDisabled}
        IconComponent={(props) => {
          return isDisabled ? null : <EditIcon {...props} fontSize="inherit" />;
        }}
        onChange={(event) => {
          const value = event.target.value as ProductionStatus | null;
          handleSelectChange(value || "");
        }}
        sx={{
          // TODO: there is probably a better way to do this
          // (maybe even just using the `select` class with `!important` is less flimsy than this)
          [`& .${selectClasses.select}.${selectClasses.standard}.${inputClasses.input}`]:
            {
              padding: 0,
              paddingRight: 0,
            },

          // disable rotation on the icon component
          [`& .${selectClasses.icon}`]: { transform: "none" },
        }}
      >
        {renderSelectOption({
          status: "Development",
          iconVariant: "outlined",
          iconColor: theme.palette.grey[400],
        })}

        <GroupDivider />

        {renderSelectOption({
          status: "Pre Production",
          iconVariant: "outlined",
          iconColor: theme.palette.primary.main,
        })}

        {renderSelectOption({
          status: "Production",
          iconVariant: "filled",
          iconColor: theme.palette.success.main,
        })}

        {renderSelectOption({
          status: "Post Production",
          iconVariant: "filled",
          iconColor: theme.palette.primary.main,
        })}

        {renderSelectOption({
          status: "Closed",
          iconVariant: "filled",
          iconColor: theme.palette.primary.main,
        })}

        {renderSelectOption({
          status: "Cancelled",
          iconVariant: "filled",
          iconColor: theme.palette.grey[400],
        })}

        <GroupDivider />

        {renderSelectOption({
          status: "Paused",
          iconVariant: "outlined",
          iconColor: theme.palette.grey[400],
        })}
      </Select>
    </FormControl>
  );
};

// this can't be a React component because MUI doesn't support wrapping `<MenuItem>`:
// https://github.com/mui/material-ui/issues/31006
// so instead, it's just a function that returns some JSX so that `<MenuItem>`
// is still the direct child of the `<Select>`
function renderSelectOption(option: {
  status: ProductionStatus;
  iconVariant: "filled" | "outlined";
  iconColor: string;
}) {
  return (
    <MenuItem key={option.status} value={option.status}>
      <Stack
        direction="row"
        alignItems="center"
        gap="16px"
        width="100%"
        paddingX={1}
      >
        <Box color={option.iconColor}>
          <TimelineDot
            color="inherit"
            sx={{
              fill: option.iconColor,
              backgroundColor:
                option.iconVariant === "filled" ? option.iconColor : undefined,
            }}
            variant={option.iconVariant}
          />
        </Box>
        <Typography
          variant="body1"
          sx={{
            WebkitTextFillColor: theme.palette.text.primary,
          }}
        >
          {option.status}
        </Typography>
      </Stack>
    </MenuItem>
  );
}

function GroupDivider() {
  return (
    <ListSubheader sx={{ padding: 0 }}>
      <Divider />
    </ListSubheader>
  );
}

export default ProductionCardStatusSelect;
