import { useMutation, UseMutationResult } from "react-query";
import {
  createFilm,
  createOneOff,
  createBrandWithBrandedSpecial,
  createBrandSeries,
} from "../../api/portal";
import { noop } from "../../utils/appHelper";
import {
  CcidNewBrandedSpecialPayload,
  CcidNewBrandSeriesPayload,
  CcidNewOneOffOrFilmPayload,
  CreateCcidPayload,
  ProductionType,
} from "../../types/types";

export const useCreateCCID = (
  onSuccess = noop,
  onError = noop,
): UseMutationResult<
  void,
  unknown,
  { formData: CreateCcidPayload; productionType: ProductionType },
  unknown
> => {
  return useMutation(
    ({
      formData,
      productionType,
    }: {
      formData: CreateCcidPayload;
      productionType: ProductionType;
    }) => {
      if (productionType === "film")
        return createFilm(formData as CcidNewOneOffOrFilmPayload);
      if (productionType === "oneOff")
        return createOneOff(formData as CcidNewOneOffOrFilmPayload);
      if (productionType === "special" || productionType === "pilot")
        return createBrandWithBrandedSpecial(
          formData as CcidNewBrandedSpecialPayload,
        );
      return createBrandSeries(formData as CcidNewBrandSeriesPayload);
    },
    {
      onSuccess,
      onError,
    },
  );
};
