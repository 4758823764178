import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import {
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Stack,
  Select,
  MenuItem,
  FormControl,
  Box,
  Link,
} from "@mui/material";
import { useState } from "react";
import { Close } from "@mui/icons-material";
import theme from "../utils/theme";
import {
  CandidateStatus,
  UpdateVacanciesPayload,
  VacancyCandidate,
  Asset,
} from "../types/types";
import { useUpdateHiringVacancies } from "../hooks/mutations/useUpdateHiringVacancies";
import { useDownloadAsset } from "../hooks/mutations/useDownloadAsset";

interface FreelanceHiringTableProps {
  candidates: Array<VacancyCandidate>;
}

const CvAssetContainer = ({ asset }: { asset: Asset }) => {
  const { mutate: downloadAsset } = useDownloadAsset();
  if (asset.thumbnails) {
    return (
      <Link
        sx={{
          color: "rgb(13, 110, 253)",
          textDecorationColor: "rgb(13, 110, 253)",
        }}
        href={asset.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {asset.filename}
      </Link>
    );
  }
  return (
    <Box
      sx={{
        appearance: "none",
        background: "transparent",
        border: "none",
        padding: 0,
        color: "rgb(13, 110, 253)",
        textDecoration: "underline",
      }}
      component="button"
      aria-label={`download ${asset.filename}`}
      onClick={() =>
        downloadAsset({
          filename: asset.filename,
          mimeType: asset.mimeType,
          url: asset.url,
        })
      }
    >
      Download {asset.filename}
    </Box>
  );
};

export const FreelanceHiringTable = ({
  candidates,
}: FreelanceHiringTableProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<GridRowsProp<VacancyCandidate>>(candidates);

  const { mutate: updateVacancies } = useUpdateHiringVacancies();

  const handleStatusChange = (fullName: string, newStatus: CandidateStatus) => {
    const updatedRows = rows.map((row) => {
      if (row.fullName === fullName) {
        return {
          ...row,
          status: newStatus,
        };
      } else {
        return row;
      }
    });
    setRows(updatedRows);
  };

  const columns: GridColDef[] = [
    { field: "fullName", headerName: "Name", flex: 1 },
    {
      field: "cv",
      headerName: "CV",
      renderCell: (params) => {
        const assets = params.row.assets;
        if (!assets || assets.length === 0) {
          return <span>No CV Available</span>;
        }

        return (
          <Box display="flex" flexDirection="column" alignItems="start">
            {assets.map((asset: Asset, index: number) => (
              <CvAssetContainer asset={asset} key={index} />
            ))}
          </Box>
        );
      },
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",

      renderCell: (params) => (
        <FormControl fullWidth>
          <Select<CandidateStatus>
            id="status-select"
            value={params.value}
            aria-label="Status"
            onChange={(e) => {
              handleStatusChange(
                params.row.fullName,
                e.target.value as CandidateStatus,
              );
            }}
          >
            <MenuItem value="SHORTLISTED_YES">
              <Chip label="Pending Review" color="warning" size="small" />
            </MenuItem>
            <MenuItem value="SUCCESSFUL_YES">
              <Chip label="Accepted" color="success" size="small" />
            </MenuItem>
            <MenuItem value="SUCCESSFUL_NO">
              <Chip label="Unsuccessful" color="error" size="small" />
            </MenuItem>
          </Select>
        </FormControl>
      ),
      flex: 1,
    },
  ];

  const handleSubmitApprovedCandidates = () => {
    const payload: UpdateVacanciesPayload = {
      applicants: rows.map((row) => ({
        // TODO: pass in record ID
        airtableRecordId: "todo",
        status: row.status,
      })),
    };
    updateVacancies(payload);

    // notify Talent team to check airtable
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <DataGrid
        getRowId={(row) => row.fullName}
        getRowHeight={() => 100}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        sx={{
          width: "auto",
          ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
            {
              marginTop: "1em",
              marginBottom: "1em",
            },
        }}
      />
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "10px" }}
        onClick={handleClickOpen}
      >
        Notify Talent team
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <Stack
          bgcolor={theme.palette.primary.main}
          color="white"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to submit these candidates?"}
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose} color="inherit">
            <Close />
          </IconButton>
        </Stack>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Clicking submit will alert the Talent team of your selected
            candidates. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSubmitApprovedCandidates}>
            Yes
          </Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
