import { useParams } from "react-router-dom";
import { Container, Skeleton, Stack, Typography } from "@mui/material";
import { useProgrammeTasks } from "../../hooks/queries";
import { TaskSection } from "../TaskSection";

const ProgrammeTasksPage = (): JSX.Element => {
  const { programmeCcid } = useParams();
  const { data, isLoading } = useProgrammeTasks(programmeCcid);

  if (data?.tasks === undefined && !isLoading)
    return <Typography>There are no tasks to complete</Typography>;
  return (
    <Container sx={{ py: 4 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Programme Tasks</Typography>
        {isLoading || data?.tasks === undefined ? (
          <Skeleton height={298} variant="rounded" animation="wave" />
        ) : (
          <TaskSection
            tasks={data.tasks}
            productionStage="preProduction"
            level="brands"
          />
        )}
        {isLoading || data?.tasks === undefined ? (
          <Skeleton height={298} variant="rounded" animation="wave" />
        ) : (
          <>
            <TaskSection
              tasks={data.tasks}
              productionStage="postProduction"
              level="brands"
            />
          </>
        )}
      </Stack>
    </Container>
  );
};

export default ProgrammeTasksPage;
