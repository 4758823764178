import {
  ProductionNotificationFormV3,
  type ProductionNotificationForm,
} from "../../types/types";
import { type NotificationFormSubmitValues } from "./NotificationFormV2";
import { NotificationFormSubmitValuesV3 } from "./NotificationFormV3";

/**
 *This is a bit of a weird type that basically performs the following mapping:
 *
 *```typescript
 *type Before = {
 *  basicInfo: {
 *    a?: string;
 *    b: Array<string>;
 *  }
 *  compliance: {
 *    c?: boolean;
 *    d?: number;
 *  }
 *}
 *
 *type After = {
 *  basicInfo: {
 *    a: string | undefined;
 *    b: Array<string> | undefined;
 *  }
 *  compliance: {
 *    c: boolean | undefined;
 *    d: number | undefined;
 *  }
 *}
 *```
 * This gives us a type that we can use to populate the initial values of the form without defining the type manually:
 *  - The `type` -> `type | undefined` mapping allows every value to potentially be undefined. This could be the case for new/partially completed forms
 *  - The `a?:` -> `a:` mapping ensures that we don't forget to populate any initial values - TypeScript will error if we miss any
 */
export type NotificationFormValues = {
  [formId in keyof NotificationFormSubmitValues]: {
    [questionId in keyof Required<NotificationFormSubmitValues[formId]>]:
      | NotificationFormSubmitValues[formId][questionId]
      | undefined;
  };
};

/**
 * Converts data from backend into initial formik values
 */
export function getInitialNotificationFormValues(
  backendData: ProductionNotificationForm,
): NotificationFormValues {
  return {
    basicInfo: {
      narrative: backendData.basicInfo?.narrative ?? undefined,
      productionStatus: backendData.basicInfo?.productionStatus ?? undefined,
      productionType: backendData.basicInfo?.productionType ?? undefined,
      otherProductionType:
        backendData.basicInfo?.otherProductionType ?? undefined,
      broadcaster: backendData.basicInfo?.broadcaster ?? undefined,
      otherBroadcaster: backendData.basicInfo?.otherBroadcaster ?? undefined,
      disgraceCover: backendData.basicInfo?.disgraceCover ?? undefined,
      disgraceDetails: backendData.basicInfo?.disgraceDetails ?? undefined,
      contentType: backendData.basicInfo?.contentType ?? undefined,
      recordingType: backendData.basicInfo?.recordingType ?? undefined,
      outsideLocation: backendData.basicInfo?.outsideLocation ?? undefined,
      currency: backendData.basicInfo?.currency ?? undefined,
      budget: backendData.basicInfo?.budget ?? undefined,
      specialPurposeVehicle:
        backendData.basicInfo?.specialPurposeVehicle ?? undefined,
      specialPurposeVehicleName:
        backendData.basicInfo?.specialPurposeVehicleName ?? undefined,
      castEstimate: backendData.basicInfo?.castEstimate ?? undefined,
      crewEstimate: backendData.basicInfo?.crewEstimate ?? undefined,
      insuranceCover: backendData.basicInfo?.insuranceCover ?? undefined,
      otherInsuranceCover:
        backendData.basicInfo?.otherInsuranceCover ?? undefined,
      creativeLead: backendData.basicInfo?.creativeLead ?? undefined,
      creativeLeadEmail: backendData.basicInfo?.creativeLeadEmail ?? undefined,
      productionContact: backendData.basicInfo?.productionContact ?? undefined,
      productionContactEmail:
        backendData.basicInfo?.productionContactEmail ?? undefined,
      preProductionDate: backendData.basicInfo?.preProductionDate ?? undefined,
      principalStartDate:
        backendData.basicInfo?.principalStartDate ?? undefined,
      principalFinishDate:
        backendData.basicInfo?.principalFinishDate ?? undefined,
      startPostDate: backendData.basicInfo?.startPostDate ?? undefined,
      productionCloseDate:
        backendData.basicInfo?.productionCloseDate ?? undefined,
    },
    healthAndSafety: {
      activities: backendData.healthAndSafety?.activities ?? undefined,
      otherActivities:
        backendData.healthAndSafety?.otherActivities ?? undefined,
      audience: backendData.healthAndSafety?.audience ?? undefined,
      covid: backendData.healthAndSafety?.covid ?? undefined,
      covidDetails: backendData.healthAndSafety?.covidDetails ?? undefined,
      filmingLocation:
        backendData.healthAndSafety?.filmingLocation ?? undefined,
      filmingTechniques:
        backendData.healthAndSafety?.filmingTechniques ?? undefined,
      firearms: backendData.healthAndSafety?.firearms ?? undefined,
      locationType: backendData.healthAndSafety?.locationType ?? undefined,
      responsibleNone:
        backendData.healthAndSafety?.responsibleNone ?? undefined,
      responsible: backendData.healthAndSafety?.responsible ?? undefined,
      responsibleEmail:
        backendData.healthAndSafety?.responsibleEmail ?? undefined,
      setBuild: backendData.healthAndSafety?.setBuild ?? undefined,
      sfx: backendData.healthAndSafety?.sfx ?? undefined,
      stunt: backendData.healthAndSafety?.stunt ?? undefined,
      training: backendData.healthAndSafety?.training ?? undefined,
      trainingCourse: backendData.healthAndSafety?.trainingCourse ?? undefined,
      vulnerable: backendData.healthAndSafety?.vulnerable ?? undefined,
      weatherConditions:
        backendData.healthAndSafety?.weatherConditions ?? undefined,
    },
    dutyOfCare: {
      degreeOfControl: backendData.dutyOfCare?.degreeOfControl ?? undefined,
      exposure: backendData.dutyOfCare?.exposure ?? undefined,
      livingArrangements:
        backendData.dutyOfCare?.livingArrangements ?? undefined,
      mentalHealthAdvisor:
        backendData.dutyOfCare?.mentalHealthAdvisor ?? undefined,
      mentalHealthAdvisorEmail:
        backendData.dutyOfCare?.mentalHealthAdvisorEmail ?? undefined,
      participantLocation:
        backendData.dutyOfCare?.participantLocation ?? undefined,
      popularityProfile: backendData.dutyOfCare?.popularityProfile ?? undefined,
      participantDemographic:
        backendData.dutyOfCare?.participantDemographic ?? undefined,
    },
    security: {
      additionalSecurity: backendData.security?.additionalSecurity ?? undefined,
      suppliers: backendData.security?.suppliers ?? undefined,
      securityProvider: backendData.security?.securityProvider ?? undefined,
      atRiskCountries: backendData.security?.atRiskCountries ?? undefined,
      covert: backendData.security?.covert ?? undefined,
      covertSynopsis: backendData.security?.covertSynopsis ?? undefined,
      internationalTravel:
        backendData.security?.internationalTravel ?? undefined,
      provocation: backendData.security?.provocation ?? undefined,
      provocationDetail: backendData.security?.provocationDetail ?? undefined,
      risk: backendData.security?.risk ?? undefined,
      riskDetail: backendData.security?.riskDetail ?? undefined,
    },
    cyberSecurity: {
      ai: backendData.cyberSecurity?.ai ?? undefined,
      aiProducts: backendData.cyberSecurity?.aiProducts ?? undefined,
      aiGuidance: backendData.cyberSecurity?.aiGuidance ?? undefined,
      appList: backendData.cyberSecurity?.appList ?? undefined,
      appListDetail: backendData.cyberSecurity?.appListDetail ?? undefined,
      breach: backendData.cyberSecurity?.breach ?? undefined,
      breachImpact: backendData.cyberSecurity?.breachImpact ?? undefined,
      cyberResponsible:
        backendData.cyberSecurity?.cyberResponsible ?? undefined,
      cyberResponsibleEmail:
        backendData.cyberSecurity?.cyberResponsibleEmail ?? undefined,
      dataAccess: backendData.cyberSecurity?.dataAccess ?? undefined,
      dataStorageCountry:
        backendData.cyberSecurity?.dataStorageCountry ?? undefined,
      dataLink: backendData.cyberSecurity?.dataLink ?? undefined,
      dataLinkDetail: backendData.cyberSecurity?.dataLinkDetail ?? undefined,
      dataStorage: backendData.cyberSecurity?.dataStorage ?? undefined,
      dataStorageSupplier:
        backendData.cyberSecurity?.dataStorageSupplier ?? undefined,
      newTech: backendData.cyberSecurity?.newTech ?? undefined,
      newTechDetail: backendData.cyberSecurity?.newTechDetail ?? undefined,
    },
    sustainability: {
      calculator: backendData.sustainability?.calculator ?? undefined,
      calculatorDetails:
        backendData.sustainability?.calculatorDetails ?? undefined,
      country: backendData.sustainability?.country ?? undefined,
      filmloc: backendData.sustainability?.filmloc ?? undefined,
      flying: backendData.sustainability?.flying ?? undefined,
      gen: backendData.sustainability?.gen ?? undefined,
      hazard: backendData.sustainability?.hazard ?? undefined,
      naturalHazard: backendData.sustainability?.naturalHazard ?? undefined,
      lighting: backendData.sustainability?.lighting ?? undefined,
      mileage: backendData.sustainability?.mileage ?? undefined,
      setbuild: backendData.sustainability?.setbuild ?? undefined,
    },
    dataPrivacy: {
      childrenFeatured: backendData.dataPrivacy?.childrenFeatured ?? undefined,
      covertActivities: backendData.dataPrivacy?.covertActivities ?? undefined,
      journalisticAims: backendData.dataPrivacy?.journalisticAims ?? undefined,
      novelData: backendData.dataPrivacy?.novelData ?? undefined,
      personalRelationships:
        backendData.dataPrivacy?.personalRelationships ?? undefined,
      riskOfHarm: backendData.dataPrivacy?.riskOfHarm ?? undefined,
      sensitiveMaterial:
        backendData.dataPrivacy?.sensitiveMaterial ?? undefined,
      subjectsAware: backendData.dataPrivacy?.subjectsAware ?? undefined,
    },
    insurance: {
      talentLoss: backendData.insurance?.talentLoss ?? undefined,
      talentLossList: backendData.insurance?.talentLossList ?? undefined,
      shutdown: backendData.insurance?.shutdown ?? undefined,
      shutdownDetails: backendData.insurance?.shutdownDetails ?? undefined,
      age: backendData.insurance?.age ?? undefined,
      jewellery: backendData.insurance?.jewellery ?? undefined,
      personalCash: backendData.insurance?.personalCash ?? undefined,
      productionCash: backendData.insurance?.productionCash ?? undefined,
      post: backendData.insurance?.post ?? undefined,
      ob: backendData.insurance?.ob ?? undefined,
      av: backendData.insurance?.av ?? undefined,
      vehicles: backendData.insurance?.vehicles ?? undefined,
      sound: backendData.insurance?.sound ?? undefined,
      rigging: backendData.insurance?.rigging ?? undefined,
      camera: backendData.insurance?.camera ?? undefined,
      source: backendData.insurance?.source ?? undefined,
      psc: backendData.insurance?.psc ?? undefined,
      lighting: backendData.insurance?.lighting ?? undefined,
      studio: backendData.insurance?.studio ?? undefined,
      set: backendData.insurance?.set ?? undefined,
      security: backendData.insurance?.security ?? undefined,
      catering: backendData.insurance?.catering ?? undefined,
      procOther: backendData.insurance?.procOther ?? undefined,
    },
    compliance: {
      corruption: backendData.compliance?.corruption ?? undefined,
      countries: backendData.compliance?.countries ?? undefined,
      tax: backendData.compliance?.tax ?? undefined,
      vat: backendData.compliance?.vat ?? undefined,
      sanctions: backendData.compliance?.sanctions ?? undefined,
      slavery: backendData.compliance?.slavery ?? undefined,
    },
  };
}

export type NotificationFormValuesV3 = {
  [formId in keyof NotificationFormSubmitValuesV3]: {
    [questionId in keyof Required<NotificationFormSubmitValuesV3[formId]>]:
      | NotificationFormSubmitValuesV3[formId][questionId]
      | undefined;
  };
};

/**
 * Converts data from backend into initial formik values
 */
export function getInitialNotificationFormValuesV3(
  backendData: ProductionNotificationFormV3,
  excludeSpotId?: boolean,
): NotificationFormValuesV3 {
  return {
    basicInfo: {
      spotId:
        backendData.basicInfo?.spotId && !excludeSpotId
          ? backendData.basicInfo?.spotId
          : undefined,
      narrative: backendData.basicInfo?.narrative ?? undefined,
      productionStatus: backendData.basicInfo?.productionStatus ?? undefined,
      productionType: backendData.basicInfo?.productionType ?? undefined,
      otherProductionType:
        backendData.basicInfo?.otherProductionType ?? undefined,
      broadcaster: backendData.basicInfo?.broadcaster ?? undefined,
      otherBroadcaster: backendData.basicInfo?.otherBroadcaster ?? undefined,
      disgraceCover: backendData.basicInfo?.disgraceCover ?? undefined,
      disgraceDetails: backendData.basicInfo?.disgraceDetails ?? undefined,
      contentType: backendData.basicInfo?.contentType ?? undefined,
      recordingType: backendData.basicInfo?.recordingType ?? undefined,
      currency: backendData.basicInfo?.currency ?? undefined,
      budget: backendData.basicInfo?.budget ?? undefined,
      specialPurposeVehicle:
        backendData.basicInfo?.specialPurposeVehicle ?? undefined,
      specialPurposeVehicleName:
        backendData.basicInfo?.specialPurposeVehicleName ?? undefined,
      castEstimate: backendData.basicInfo?.castEstimate ?? undefined,
      crewEstimate: backendData.basicInfo?.crewEstimate ?? undefined,
      creativeLead: backendData.basicInfo?.creativeLead ?? undefined,
      creativeLeadEmail: backendData.basicInfo?.creativeLeadEmail ?? undefined,
      productionContact: backendData.basicInfo?.productionContact ?? undefined,
      productionContactEmail:
        backendData.basicInfo?.productionContactEmail ?? undefined,
      preProductionDate: backendData.basicInfo?.preProductionDate ?? undefined,
      principalStartDate:
        backendData.basicInfo?.principalStartDate ?? undefined,
      principalFinishDate:
        backendData.basicInfo?.principalFinishDate ?? undefined,
      startPostDate: backendData.basicInfo?.startPostDate ?? undefined,
      productionCloseDate:
        backendData.basicInfo?.productionCloseDate ?? undefined,
      additionalInfo: backendData.basicInfo?.additionalInfo ?? undefined,
    },
    healthAndSafety: {
      spotId:
        backendData.healthAndSafety?.spotId && !excludeSpotId
          ? backendData.healthAndSafety?.spotId
          : undefined,
      activities: backendData.healthAndSafety?.activities ?? undefined,
      otherActivities:
        backendData.healthAndSafety?.otherActivities ?? undefined,
      filmingLocation:
        backendData.healthAndSafety?.filmingLocation ?? undefined,
      responsibleNone:
        backendData.healthAndSafety?.responsibleNone ?? undefined,
      responsible: backendData.healthAndSafety?.responsible ?? undefined,
      responsibleEmail:
        backendData.healthAndSafety?.responsibleEmail ?? undefined,
      setBuild: backendData.healthAndSafety?.setBuild ?? undefined,
      training: backendData.healthAndSafety?.training ?? undefined,
      trainingCourse: backendData.healthAndSafety?.trainingCourse ?? undefined,
      vulnerable: backendData.healthAndSafety?.vulnerable ?? undefined,
      externalProvider:
        backendData.healthAndSafety?.externalProvider ?? undefined,
    },
    insurance: {
      spotId:
        backendData.insurance?.spotId && !excludeSpotId
          ? backendData.insurance?.spotId
          : undefined,
      insuranceCover: backendData.insurance?.insuranceCover ?? undefined,
      otherInsuranceCover:
        backendData.insurance?.otherInsuranceCover ?? undefined,
      talentLoss: backendData.insurance?.talentLoss ?? undefined,
      talentLossList: backendData.insurance?.talentLossList ?? undefined,
      shutdown: backendData.insurance?.shutdown ?? undefined,
      shutdownDetails: backendData.insurance?.shutdownDetails ?? undefined,
      age: backendData.insurance?.age ?? undefined,
      holdings: backendData.insurance?.holdings ?? undefined,
    },
    security: {
      spotId:
        backendData.security?.spotId && !excludeSpotId
          ? backendData.security?.spotId
          : undefined,
      additionalSecurity: backendData.security?.additionalSecurity ?? undefined,
      suppliers: backendData.security?.suppliers ?? undefined,
      securityProvider: backendData.security?.securityProvider ?? undefined,
      atRiskCountries: backendData.security?.atRiskCountries ?? undefined,
      provocation: backendData.security?.provocation ?? undefined,
      provocationDetail: backendData.security?.provocationDetail ?? undefined,
      risk: backendData.security?.risk ?? undefined,
      riskDetail: backendData.security?.riskDetail ?? undefined,
    },
    procurement: {
      spotId:
        backendData.procurement?.spotId && !excludeSpotId
          ? backendData.procurement?.spotId
          : undefined,
      spend: backendData.procurement?.spend ?? undefined,
      otherSpend: backendData.procurement?.otherSpend ?? undefined,
      supplier: backendData.procurement?.supplier ?? undefined,
      supplierDetails: backendData.procurement?.supplierDetails ?? undefined,
      intermediary: backendData.procurement?.intermediary ?? undefined,
      dayLabourers: backendData.procurement?.dayLabourers ?? undefined,
      contracting: backendData.procurement?.contracting ?? undefined,
      otherContracting: backendData.procurement?.otherContracting ?? undefined,
    },
    cyberSecurity: {
      spotId:
        backendData.cyberSecurity?.spotId && !excludeSpotId
          ? backendData.cyberSecurity?.spotId
          : undefined,
      cyberResponsible:
        backendData.cyberSecurity?.cyberResponsible ?? undefined,
      cyberResponsibleEmail:
        backendData.cyberSecurity?.cyberResponsibleEmail ?? undefined,
      appList: backendData.cyberSecurity?.appList ?? undefined,
      appListDetail: backendData.cyberSecurity?.appListDetail ?? undefined,
      dataStorage: backendData.cyberSecurity?.dataStorage ?? undefined,
      dataStorageSupplier:
        backendData.cyberSecurity?.dataStorageSupplier ?? undefined,
      dataAccess: backendData.cyberSecurity?.dataAccess ?? undefined,
      dataStorageCountry:
        backendData.cyberSecurity?.dataStorageCountry ?? undefined,
      incidentResponse:
        backendData.cyberSecurity?.incidentResponse ?? undefined,
      cyberResponseGuidelines:
        backendData.cyberSecurity?.cyberResponseGuidelines ?? undefined,
      ai: backendData.cyberSecurity?.ai ?? undefined,
      aiProducts: backendData.cyberSecurity?.aiProducts ?? undefined,
      aiTools: backendData.cyberSecurity?.aiTools ?? undefined,
      aiGuidance: backendData.cyberSecurity?.aiGuidance ?? undefined,
    },
    sustainability: {
      spotId:
        backendData.sustainability?.spotId && !excludeSpotId
          ? backendData.sustainability?.spotId
          : undefined,
      calculator: backendData.sustainability?.calculator ?? undefined,
      calculatorDetails:
        backendData.sustainability?.calculatorDetails ?? undefined,
      airTravel: backendData.sustainability?.airTravel ?? undefined,
      flying: backendData.sustainability?.flying ?? undefined,
      gen: backendData.sustainability?.gen ?? undefined,
      generatorType: backendData.sustainability?.generatorType ?? undefined,
      hazard: backendData.sustainability?.hazard ?? undefined,
      naturalHazard: backendData.sustainability?.naturalHazard ?? undefined,
    },
    locationsAndTravel: {
      spotId:
        backendData.locationsAndTravel?.spotId && !excludeSpotId
          ? backendData.locationsAndTravel?.spotId
          : undefined,
      countries: backendData.locationsAndTravel?.countries ?? undefined,
      operatingEnvironment:
        backendData.locationsAndTravel?.operatingEnvironment ?? undefined,
      travel: backendData.locationsAndTravel?.travel ?? undefined,
      travelBooking: backendData.locationsAndTravel?.travelBooking ?? undefined,
      environment: backendData.locationsAndTravel?.environment ?? undefined,
      cultural: backendData.locationsAndTravel?.cultural ?? undefined,
      disruption: backendData.locationsAndTravel?.disruption ?? undefined,
    },
    participantWelfare: {
      spotId:
        backendData.participantWelfare?.spotId && !excludeSpotId
          ? backendData.participantWelfare?.spotId
          : undefined,
      vulnerability: backendData.participantWelfare?.vulnerability ?? undefined,
      participation: backendData.participantWelfare?.participation ?? undefined,
      conditions: backendData.participantWelfare?.conditions ?? undefined,
      environment: backendData.participantWelfare?.environment ?? undefined,
      exposure: backendData.participantWelfare?.exposure ?? undefined,
      sensitiveInformation:
        backendData.participantWelfare?.sensitiveInformation ?? undefined,
      mentalHealthAdvisorEngaged:
        backendData.participantWelfare?.mentalHealthAdvisorEngaged ?? undefined,
      mentalHealthAdvisor:
        backendData.participantWelfare?.mentalHealthAdvisor ?? undefined,
      mentalHealthAdvisorEmail:
        backendData.participantWelfare?.mentalHealthAdvisorEmail ?? undefined,
    },
    dataPrivacy: {
      spotId:
        backendData.dataPrivacy?.spotId && !excludeSpotId
          ? backendData.dataPrivacy?.spotId
          : undefined,
      activities: backendData.dataPrivacy?.activities ?? undefined,
      novelData: backendData.dataPrivacy?.novelData ?? undefined,
      subjectsAware: backendData.dataPrivacy?.subjectsAware ?? undefined,
      journalisticAims: backendData.dataPrivacy?.journalisticAims ?? undefined,
    },
  };
}
