/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card,
  CardContent,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { FormikErrors, FormikValues } from "formik";
import { FC } from "react";

import { StyledSectionDescription } from "../../../../components/DynamicForm/DynamicForm.styles";
import { ContributorProps } from "../../../../types/types";
import { StartUpFormContributors } from "../../../../components/DynamicForm/components/Contributors/StartUpFormContributors";

export interface ContributorsAndTalentProps {
  isPrinting: boolean;
  values: FormikValues;
  blurHandler: (id: string, newValue: any) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<any>>;
}

function changeContributorToArrayAndAddId(
  contributors: any,
): ContributorProps[] {
  return Object.keys(contributors).map((instance) => {
    const contributorObject = contributors[instance];
    contributorObject.contributorId = instance;
    return contributorObject;
  });
}

export const ContributorsAndTalentForm: FC<ContributorsAndTalentProps> = ({
  isPrinting,
  values,
  blurHandler,
  setFieldValue,
}) => (
  <>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <Typography>Key series contributors</Typography>
        <StyledSectionDescription>
          Please indicate the key contributors that exist across this series:
        </StyledSectionDescription>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={12}>
            {/* Contributors */}
            <StartUpFormContributors
              onAddNewContributor={(newContributors) => {
                setFieldValue(
                  "contributorsAndTalent.contributors",
                  newContributors,
                );
              }}
              contributors={changeContributorToArrayAndAddId(
                values.contributorsAndTalent.contributors,
              )}
              onChange={(newContributors) => {
                setFieldValue(
                  "contributorsAndTalent.contributors",
                  newContributors,
                );
                blurHandler(
                  "contributorsAndTalent.contributors",
                  newContributors,
                );
              }}
              disabled={false}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={12}>
            <StyledSectionDescription>
              <Typography variant="h5">
                Other contributors considerations
              </Typography>
            </StyledSectionDescription>

            {/* Members of the public */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Are you looking for members of the public to participate?
            </FormLabel>
            <TextField
              value={values.contributorsAndTalent.membersOfPublic}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={isPrinting ? undefined : 8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "contributorsAndTalent.membersOfPublic",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "contributorsAndTalent.membersOfPublic",
                  event.target.value,
                );
              }}
            />

            {/* Paid advertising */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Are you using paid for advertising such as Facebook boost to
              source contributors?
            </FormLabel>
            <TextField
              value={values.contributorsAndTalent.paidAdvertising}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={isPrinting ? undefined : 8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "contributorsAndTalent.paidAdvertising",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "contributorsAndTalent.paidAdvertising",
                  event.target.value,
                );
              }}
            />

            {/* Social media sourcing */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Are you using social media to source contributors?
            </FormLabel>
            <TextField
              value={values.contributorsAndTalent.socialMediaSourcing}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={isPrinting ? undefined : 8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "contributorsAndTalent.socialMediaSourcing",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "contributorsAndTalent.socialMediaSourcing",
                  event.target.value,
                );
              }}
            />

            {/* Format background checks */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Are format background checks required?
            </FormLabel>
            <TextField
              value={values.contributorsAndTalent.formatBackground}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={isPrinting ? undefined : 8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "contributorsAndTalent.formatBackground",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "contributorsAndTalent.formatBackground",
                  event.target.value,
                );
              }}
            />

            {/* Visas or Child Licenses */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Visas or Child Licenses Required
            </FormLabel>
            <TextField
              value={values.contributorsAndTalent.visaOrChildLicences}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={isPrinting ? undefined : 8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "contributorsAndTalent.visaOrChildLicences",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "contributorsAndTalent.visaOrChildLicences",
                  event.target.value,
                );
              }}
            />

            {/* Talent payments */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Talent payments
            </FormLabel>
            <TextField
              value={values.contributorsAndTalent.talentPayments}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={isPrinting ? undefined : 8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "contributorsAndTalent.talentPayments",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "contributorsAndTalent.talentPayments",
                  event.target.value,
                );
              }}
            />

            {/* Duty of care */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Duty of care
            </FormLabel>
            <TextField
              value={values.contributorsAndTalent.dutyOfCare}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={isPrinting ? undefined : 8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "contributorsAndTalent.dutyOfCare",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "contributorsAndTalent.dutyOfCare",
                  event.target.value,
                );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={10}>
            {/* Additional notes */}
            <FormLabel sx={{ display: "block", marginBottom: "0.5rem" }}>
              Additional notes:
            </FormLabel>
            <TextField
              value={values.contributorsAndTalent.additionalNotes}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={isPrinting ? undefined : 8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "contributorsAndTalent.additionalNotes",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "contributorsAndTalent.additionalNotes",
                  event.target.value,
                );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </>
);
