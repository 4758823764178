import { useMutation } from "react-query";
import handleRequest from "../../lib/handleRequest";

interface DownloadAssetArgs {
  url: string;
  mimeType: string;
  filename: string;
}

export function useDownloadAsset() {
  return useMutation({
    mutationFn: async ({ url, mimeType, filename }: DownloadAssetArgs) => {
      // Use Blob method to download when no thumbnails are available
      const blob = await handleRequest
        .get(url, {
          responseType: "blob",
          headers: {},
        })
        .then((res) => new Blob([res.data], { type: `${mimeType}` }));
      const objectUrl = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const a = document.createElement("a");
      a.href = objectUrl;
      a.download = filename;
      a.click();

      // Revoke the Blob URL after download
      window.URL.revokeObjectURL(objectUrl);
    },
  });
}
