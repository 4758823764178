import { useMutation } from "react-query";
import { createPostProductionContact } from "../../api/portal";
import { CreatePostProductionContactRequest } from "../../types/types";

export function useCreatePostProductionContact() {
  return useMutation(
    ({
      ccid,
      payload,
    }: {
      ccid: string;
      payload: CreatePostProductionContactRequest;
    }) => {
      return createPostProductionContact({ ccid, payload });
    },
  );
}
