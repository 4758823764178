import { useParams } from "react-router-dom";
import { Container, Grid, Stack } from "@mui/material";
import AssignedTasksTile from "../AssignedTaskTile";
import { TaskListTile } from "../TaskListTile";
import { AuxActionsTile } from "../AuxActionsTile";

export function TitleTasksPage() {
  const { titleCcid } = useParams();

  return (
    <Container sx={{ py: 4 }}>
      <Grid container direction="row" spacing="16px">
        <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
          <Stack direction="column" spacing="32px">
            <AssignedTasksTile level="titles" />
            <AuxActionsTile ccid={titleCcid} />
          </Stack>
        </Grid>

        <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
          <TaskListTile ccid={titleCcid} level="titles" />
        </Grid>
      </Grid>
    </Container>
  );
}
