import { Link, Paper, Stack, Typography } from "@mui/material";
import { useTaskStatus } from "../hooks/queries";
import { CheckCircleOutlined } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

const AssignedTaskItem = ({
  icon,
  taskStatusUrl,
  description,
  taskRoute,
}: {
  icon: JSX.Element;
  taskStatusUrl: string;
  description: string;
  taskRoute: string;
}): JSX.Element => {
  const { data: taskStatus } = useTaskStatus(taskStatusUrl);
  const bgColor = !taskStatus?.completed ? "white" : "rgba(0, 128, 0, 0.1)";

  return (
    <Paper
      variant="elevation"
      sx={{
        backgroundColor: bgColor,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Link
        component={RouterLink}
        to={taskRoute}
        sx={{
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
          padding: "32px 16px",
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing="12px"
          width="100%"
          textAlign="center"
        >
          {taskStatus?.completed ? (
            <CheckCircleOutlined sx={{ fontSize: "40px" }} color="success" />
          ) : (
            icon
          )}
          <Typography variant="subtitle1" component="span">
            {description}
          </Typography>
        </Stack>
      </Link>
    </Paper>
  );
};

export default AssignedTaskItem;
