import { FC, useState } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Button, IconButton, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TaskGroups } from "../../../../../types/types";

const taskGroupDescription: Record<TaskGroups, string> = {
  [TaskGroups.NewProductionNotificationForm]:
    "New Production Notification Form",
  [TaskGroups.LegalDeliverables]: "Production Principles & Ofcom Forms",
  [TaskGroups.StartupAndCloseDownForm]: "Start Up & Close-down Forms",
  [TaskGroups.Images]: "Images",
  [TaskGroups.Billings]: "Billings & Synopsis",
  [TaskGroups.LogosAndFonts]: "Fonts & Logos",
  [TaskGroups.TimeCodes]: "Timecodes",
  [TaskGroups.Transcripts]: "Transcripts",
};

function sortTaskGroups(a: TaskGroups, b: TaskGroups): number {
  const keys = Object.keys(taskGroupDescription);
  const indexA = keys.indexOf(a);
  const indexB = keys.indexOf(b);
  return indexA - indexB;
}

interface AssignTaskSideNavProps {
  taskGroups: TaskGroups[];
  setSelectedTaskGroup: (taskgroup: TaskGroups) => void;
}

export const AssignTasksSideNav: FC<AssignTaskSideNavProps> = ({
  taskGroups,
  setSelectedTaskGroup,
}) => {
  const [step, setStep] = useState<number>(0);

  const handleNextButtonClick = () => {
    setSelectedTaskGroup(taskGroups[step + 1] as TaskGroups);
    setStep(step + 1);
  };

  return (
    <>
      <Stack spacing={1} direction="column">
        <Timeline
          sx={{
            pt: 4,
            paddingLeft: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {taskGroups.sort(sortTaskGroups).map((group, index) => (
            <Stack key={index}>
              <TimelineItem>
                <TimelineSeparator>
                  {step === index ? (
                    <TimelineDot variant="outlined" color="primary" />
                  ) : (
                    <TimelineDot variant="outlined" />
                  )}
                  {index + 1 !== taskGroups.length && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setSelectedTaskGroup(taskGroups[index] as TaskGroups);
                    setStep(index);
                  }}
                >
                  {taskGroupDescription[group]}
                </TimelineContent>
              </TimelineItem>
            </Stack>
          ))}
        </Timeline>

        <Stack spacing={1} direction="row">
          <IconButton
            disabled={step === 0}
            color="primary"
            onClick={() => {
              setSelectedTaskGroup(taskGroups[step - 1] as TaskGroups);
              setStep(step - 1);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={handleNextButtonClick}
          >
            Next Section <ArrowForwardIcon />
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
