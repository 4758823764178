import * as Yup from "yup";
import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useGetUserProfile } from "../hooks";
import { POST_HOUSES } from "../types/types";
import { Form, Formik } from "formik";
import { FormFieldLabel } from "../componentsV2/FormFieldLabel";
import { useCreatePostProductionContact } from "../hooks/mutations";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../componentsV2/SnackBarDismiss";
import getEnvironment from "../lib/getEnviroment";

const createContactSchema = Yup.object().shape({
  email: Yup.string()
    .required("This is a required field")
    .email("Invalid email address"),
  postHouse: Yup.string()
    .required("This is a required field")
    .oneOf(POST_HOUSES, "Invalid Post House"),
});

type CreateContactValues = Yup.InferType<typeof createContactSchema>;

export function PostProductionContactTile({ ccid }: { ccid: string }) {
  const { data: userProfile } = useGetUserProfile();
  const { mutate: createContact, isLoading: isCreatingContact } =
    useCreatePostProductionContact();

  if (userProfile?.userType !== "ADMIN" || getEnvironment() === "production") {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Add Post Production Contact</Typography>
        <Typography variant="body2" paddingBottom="24px">
          Some description should probably go here
        </Typography>

        <Formik<CreateContactValues>
          initialValues={{ email: "", postHouse: "ACTIVE-PIXEL" }}
          onSubmit={(values, { resetForm }) => {
            createContact(
              {
                ccid,
                payload: { email: values.email, postHouse: values.postHouse },
              },
              {
                onSuccess: () => {
                  resetForm();
                  enqueueSnackbar(
                    "Request to create contact has been successfully sent",
                    {
                      variant: "success",
                    },
                  );
                },
                onError: () => {
                  enqueueSnackbar(
                    "There was an error on creating the contact",
                    {
                      variant: "error",
                      action: SnackbarDismiss,
                      persist: true,
                    },
                  );
                },
              },
            );
          }}
          validationSchema={createContactSchema}
        >
          {({ values, handleChange, handleBlur, errors, touched }) => {
            return (
              <Form noValidate>
                <Stack direction="column" spacing={2}>
                  <FormFieldLabel
                    inputId="email"
                    label="Post Production Contact Email"
                    required={true}
                  >
                    <OutlinedInput
                      type="email"
                      id="email"
                      name="email"
                      required={true}
                      multiline={false}
                      fullWidth
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-describedby="email-error"
                      error={Boolean(touched.email && errors.email)}
                      placeholder="example@example.com"
                    />
                    {touched.email && errors.email ? (
                      <FormHelperText id="email-error" error={true}>
                        {errors.email}
                      </FormHelperText>
                    ) : null}
                  </FormFieldLabel>

                  <Button
                    disabled={isCreatingContact}
                    type="submit"
                    variant="contained"
                    sx={{ alignSelf: "flex-start" }}
                  >
                    Create Contact
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
}
