import React, { FC, useState } from "react";
import {
  Autocomplete,
  Box,
  TextField,
  InputAdornment,
  debounce,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useBrands, useLabelUserEntitlements } from "../../hooks";
import BrandCard from "../BrandCard/BrandCard";
import OneOffCard from "../OneOffCard/OneOffCard";
import { Brand, OneOff } from "../../types/types";
import { AddNewItem } from "../../components/AddNewItem";
import CreateCcid from "../../containers/CreateCcid/CreateCcid";

const LIMIT = 5;

export interface ProgrammeSearchOptions {
  Brand?: Brand;
  OneOff?: OneOff;
  AddNewItem?: boolean;
}

export const NavBarSearch: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = !isSm && !isXs;
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [isSearchopen, setIsSearchOpen] = useState<boolean>(false);
  const [isCCIDModalOpen, setCCIDModalOpen] = useState<boolean>(false);
  const { data: entitlement, isLoading: isLoadingEntitlement } =
    useLabelUserEntitlements();
  const {
    data: brandSearch,
    isLoading: isLoadingSearch,
    isFetching: isFetchingSearch,
  } = useBrands({
    searchTerm,
    limit: LIMIT,
    includeOpportunity: true,
    keepPreviousResults: true,
    filterByUserLabels: true,
  });

  const handleInputChange = (_: unknown, value: string) => {
    if (value.length > 2) {
      setSearchTerm(value);
    }
  };

  const searchBarLoading =
    isLoadingSearch || isFetchingSearch || isLoadingEntitlement;

  const getSearchResults = (): ProgrammeSearchOptions[] => {
    const searchResults = brandSearch?.results || [];

    if (entitlement && entitlement.canCreateCcid && isDesktop) {
      return [{ AddNewItem: true }, ...searchResults];
    } else {
      return searchResults;
    }
  };

  const searchResults = getSearchResults();

  const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      setIsSearchOpen(false);
      navigate({
        pathname: "/search",
        search: `?${createSearchParams({ q: searchTerm })}`,
      });
    }
  };

  return (
    <>
      <Autocomplete
        id="search-bar"
        sx={{ width: "50%", background: "white", borderRadius: 1 }}
        filterOptions={(x) => x}
        options={searchResults}
        size="small"
        open={isSearchopen}
        onOpen={() => setIsSearchOpen(true)}
        onClose={() => setIsSearchOpen(false)}
        freeSolo
        autoComplete
        loading={searchBarLoading}
        filterSelectedOptions
        blurOnSelect
        noOptionsText="Please enter a search query"
        getOptionLabel={(option) => {
          if (typeof option !== "string" && option?.OneOff) {
            return option.OneOff.titleName;
          }
          if (typeof option !== "string" && option?.Brand) {
            return option.Brand.brandName;
          }

          if (typeof option !== "string" && option?.AddNewItem) {
            return "";
          }
          return option as string;
        }}
        onChange={() => {
          setIsSearchOpen(false);
        }}
        onInputChange={debounce(handleInputChange, 300)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Search for a production..."
            onKeyDown={handleEnterPress}
            InputProps={{
              ...params.InputProps,
              startAdornment: searchBarLoading ? (
                <InputAdornment position="start">
                  <CircularProgress size={20} />
                </InputAdornment>
              ) : (
                <InputAdornment position="start">
                  <SearchIcon
                    onClick={() => {
                      if (!isDesktop && searchTerm) {
                        navigate({
                          pathname: "/search",
                          search: `?${createSearchParams({ q: searchTerm })}`,
                        });
                      }
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          if (option?.AddNewItem)
            return (
              <Box mx={1}>
                <AddNewItem onAdd={() => setCCIDModalOpen(true)} />
              </Box>
            );
          if (option?.Brand)
            return (
              <Box mx={1}>
                <BrandCard
                  key={option?.Brand.brandCcid}
                  programme={option?.Brand}
                  onClickFn={() => {
                    setSearchTerm(option?.Brand?.brandName || "");
                    setIsSearchOpen(false);
                  }}
                />
              </Box>
            );
          if (option?.OneOff)
            return (
              <Box mx={1}>
                <OneOffCard
                  key={option?.OneOff.titleCcid}
                  programme={option?.OneOff}
                  onClickFn={() => {
                    setSearchTerm(option?.OneOff?.titleName || "");
                    setIsSearchOpen(false);
                  }}
                />
              </Box>
            );
          return null;
        }}
      />
      <CreateCcid open={isCCIDModalOpen} setOpen={setCCIDModalOpen} />
    </>
  );
};
