/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card,
  CardContent,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";

import { FormikErrors, FormikValues } from "formik";
import { FC } from "react";
import dayjs from "dayjs";

import { DeliveryDate } from "../../../../components/DynamicForm/components/DiliveryDate/DeliveryDate";

export interface BusinessProps {
  isPrinting: boolean;
  values: FormikValues;
  blurHandler: (id: string, newValue: any) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<any>>;
}

export const BusinessForm: FC<BusinessProps> = ({
  isPrinting,
  values,
  blurHandler,
  setFieldValue,
}) => {
  return (
    <>
      <Card>
        <CardContent sx={{ padding: "1rem" }}>
          <Typography variant="h5">Budget</Typography>

          <Grid container rowSpacing={2} columnSpacing={2} my={0}>
            <Grid item xs={12}>
              {/* Any Co Funding or other additional income? */}
              <FormLabel sx={{ display: "block", marginBottom: "0.5rem" }}>
                Any Co Funding or other additional income?
              </FormLabel>
              <TextField
                value={values.business.coFundingOrAdditionalIncome}
                multiline
                fullWidth
                placeholder="Please type your answer here"
                maxRows={isPrinting ? undefined : 8}
                minRows={8}
                onChange={(event) => {
                  setFieldValue(
                    "business.coFundingOrAdditionalIncome",
                    event.target.value,
                  );
                }}
                onBlur={(event) => {
                  blurHandler(
                    "business.coFundingOrAdditionalIncome",
                    event.target.value,
                  );
                }}
              />

              {/* Dates for Cost form Submission */}
              <FormLabel
                sx={{
                  display: "block",
                  marginBottom: "0.5rem",
                  paddingTop: "1rem",
                }}
              >
                Dates for Cost form Submission
              </FormLabel>
              <DatePicker
                value={dayjs(values.business.costFormSubmissionDate || null)}
                onChange={(newDate) => {
                  if (dayjs(newDate).isValid() || newDate === null) {
                    const newValue = newDate
                      ? dayjs(newDate).format("YYYY-MM-DD")
                      : "";
                    setFieldValue("business.costFormSubmissionDate", newValue);
                    blurHandler("business.costFormSubmissionDate", newValue);
                  }
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent sx={{ padding: "1rem" }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              {/* Programme Title clearance */}
              <FormLabel
                sx={{
                  display: "block",
                  marginBottom: "0.5rem",
                  paddingTop: "1rem",
                }}
              >
                Programme Title clearance / trademark registration
              </FormLabel>
              <TextField
                value={values.business.programmeTitleClearance}
                multiline
                fullWidth
                placeholder="Please type your answer here"
                maxRows={isPrinting ? undefined : 8}
                minRows={8}
                onChange={(event) => {
                  setFieldValue(
                    "business.programmeTitleClearance",
                    event.target.value,
                  );
                }}
                onBlur={(event) => {
                  blurHandler(
                    "business.programmeTitleClearance",
                    event.target.value,
                  );
                }}
              />

              {/* Bribery Act */}
              <FormLabel
                sx={{
                  display: "block",
                  marginBottom: "0.5rem",
                  paddingTop: "1rem",
                }}
              >
                Bribery Act
              </FormLabel>
              <TextField
                value={values.business.bribery}
                multiline
                fullWidth
                placeholder="Please type your answer here"
                maxRows={isPrinting ? undefined : 8}
                minRows={8}
                onChange={(event) => {
                  setFieldValue("business.bribery", event.target.value);
                }}
                onBlur={(event) => {
                  blurHandler("business.bribery", event.target.value);
                }}
              />

              {/* Sanctions */}
              <FormLabel
                sx={{
                  display: "block",
                  marginBottom: "0.5rem",
                  paddingTop: "1rem",
                }}
              >
                Sanctions
              </FormLabel>
              <TextField
                value={values.business.sanctions}
                multiline
                fullWidth
                placeholder="Please type your answer here"
                maxRows={isPrinting ? undefined : 8}
                minRows={8}
                onChange={(event) => {
                  setFieldValue("business.sanctions", event.target.value);
                }}
                onBlur={(event) => {
                  blurHandler("business.sanctions", event.target.value);
                }}
              />

              {/* Format */}
              <FormLabel
                sx={{
                  display: "block",
                  marginBottom: "0.5rem",
                  paddingTop: "1rem",
                }}
              >
                Format
              </FormLabel>
              <TextField
                value={values.business.format}
                multiline
                fullWidth
                placeholder="Please type your answer here"
                maxRows={isPrinting ? undefined : 8}
                minRows={8}
                onChange={(event) => {
                  setFieldValue("business.format", event.target.value);
                }}
                onBlur={(event) => {
                  blurHandler("business.format", event.target.value);
                }}
              />

              {/* Release Forms */}
              <FormLabel
                sx={{
                  display: "block",
                  marginBottom: "0.5rem",
                  paddingTop: "1rem",
                }}
              >
                Release Forms
              </FormLabel>
              <TextField
                value={values.business.releaseForms}
                multiline
                fullWidth
                placeholder="Please type your answer here"
                maxRows={isPrinting ? undefined : 8}
                minRows={8}
                onChange={(event) => {
                  setFieldValue("business.releaseForms", event.target.value);
                }}
                onBlur={(event) => {
                  blurHandler("business.releaseForms", event.target.value);
                }}
              />

              {/* Contestants */}
              <FormLabel
                sx={{
                  display: "block",
                  marginBottom: "0.5rem",
                  paddingTop: "1rem",
                }}
              >
                Contestants
              </FormLabel>
              <TextField
                value={values.business.contestants}
                multiline
                fullWidth
                placeholder="Please type your answer here"
                maxRows={isPrinting ? undefined : 8}
                minRows={8}
                onChange={(event) => {
                  setFieldValue("business.contestants", event.target.value);
                }}
                onBlur={(event) => {
                  blurHandler("business.contestants", event.target.value);
                }}
              />

              {/* Rights */}
              <FormLabel
                sx={{
                  display: "block",
                  marginBottom: "0.5rem",
                  paddingTop: "1rem",
                }}
              >
                Rights
              </FormLabel>
              <TextField
                value={values.business.rights}
                multiline
                fullWidth
                placeholder="Please type your answer here"
                maxRows={isPrinting ? undefined : 8}
                minRows={8}
                onChange={(event) => {
                  setFieldValue("business.rights", event.target.value);
                }}
                onBlur={(event) => {
                  blurHandler("business.rights", event.target.value);
                }}
              />

              {/* Broadcast Clearances */}
              <FormLabel
                sx={{
                  display: "block",
                  marginBottom: "0.5rem",
                  paddingTop: "1rem",
                }}
              >
                Broadcast Clearances
              </FormLabel>
              <TextField
                value={values.business.broadcastClearances}
                multiline
                fullWidth
                placeholder="Please type your answer here"
                maxRows={isPrinting ? undefined : 8}
                minRows={8}
                onChange={(event) => {
                  setFieldValue(
                    "business.broadcastClearances",
                    event.target.value,
                  );
                }}
                onBlur={(event) => {
                  blurHandler(
                    "business.broadcastClearances",
                    event.target.value,
                  );
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent sx={{ padding: "1rem" }}>
          <Typography variant="h5">Contracted delivery dates</Typography>

          <Grid container rowSpacing={2} columnSpacing={2} my={0}>
            <Grid item xs={12}>
              {/* Dates per Episode Switch */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      name="business.datesPerEpisode"
                      onChange={(event) => {
                        const newValue = event.target.checked;
                        setFieldValue("business.datesPerEpisode", newValue);
                        blurHandler("business.datesPerEpisode", newValue);
                      }}
                      checked={values.business.datesPerEpisode}
                      inputProps={{ "aria-label": `Dates per Episode Switch` }}
                    />
                  }
                  label="Dates per Episode"
                  name="business.datesPerEpisode"
                  value={values.business.datesPerEpisode}
                />
              </Grid>

              {/* Series Delivery Date */}
              <Grid item xs={6} paddingTop={5}>
                {!values.business.datesPerEpisode && (
                  <DatePicker
                    value={dayjs(values.business.seriesDeliveryDate || null)}
                    onChange={(newDate) => {
                      if (dayjs(newDate).isValid() || newDate === null) {
                        const newValue = newDate
                          ? dayjs(newDate).format("YYYY-MM-DD")
                          : "";
                        blurHandler("business.seriesDeliveryDate", newValue);
                      }
                    }}
                  />
                )}
              </Grid>

              {/* Episode Delivery Date */}
              <Grid item xs={12}>
                {values.business.datesPerEpisode && (
                  <DeliveryDate
                    value={values.business.deliveryDates}
                    onChange={(newValue) => {
                      setFieldValue("business.deliveryDates", newValue);
                      blurHandler("business.deliveryDates", newValue);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent sx={{ padding: "1rem" }}>
          {/* Additional Information */}
          <FormLabel sx={{ display: "block", marginBottom: "0.5rem" }}>
            Additional Information:
          </FormLabel>
          <TextField
            value={values.business.additionalNotes}
            multiline
            fullWidth
            placeholder="Please type your answer here"
            maxRows={isPrinting ? undefined : 8}
            minRows={8}
            onChange={(event) => {
              setFieldValue("business.additionalNotes", event.target.value);
            }}
            onBlur={(event) => {
              blurHandler("business.additionalNotes", event.target.value);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};
