import { useParams } from "react-router-dom";
import { Container, Grid, Stack } from "@mui/material";
import { TaskListTile } from "../TaskListTile";
import { AuxActionsTile } from "../AuxActionsTile";
import { TreatmentTile } from "../SeriesTreatmentTile";
import AssignedTasksTile from "../AssignedTaskTile";
import { PostProductionContactTile } from "../PostProductionContactTile";

export function SeriesTasksPage() {
  const { seriesCcid } = useParams();

  return (
    <Container sx={{ py: 4 }}>
      <Grid container direction="row" spacing="16px">
        <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
          <Stack direction="column" spacing="32px">
            <AssignedTasksTile level="series" />
            <TreatmentTile ccid={seriesCcid} level="series" />
            <AuxActionsTile ccid={seriesCcid} />
            <PostProductionContactTile ccid={seriesCcid as string} />
          </Stack>
        </Grid>

        <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
          <TaskListTile ccid={seriesCcid} level="series" />
        </Grid>
      </Grid>
    </Container>
  );
}
