/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback } from "react";
import { FormikErrors } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ModalWrapper } from "../../../../components";
import { Attendee } from "../../../../types/types";
import { AttendeeRow } from "./AttendeeRow";
import { roleToDisplayName } from "../Contributors/contributor.helper";

const attendeeRoles: string[] = [
  "EXECUTIVE_PRODUCER",
  "SERIES_EDITOR",
  "CONTENT_PRODUCER",
  "ASSISTANT_PRODUCER",
  "LINE_PRODUCER",
  "PRODUCTION_EXECUTIVE",
  "PRODUCTION_MANAGER",
  "PRODUCTION_SECRETARY",
  "PRODUCTION_ACCOUNTANT",
  "INSURANCE",
  "H&S",
  "BUSINESS_AFFAIRS",
  "GE_FORMATS",
  "NON-SCRIPTED_RIGHTS",
  "DOCUMENT_ARCHIVE",
  "DATA_PRIVACY",
  "TAX_TEAM",
  "TALENT_PAYMENTS",
  "DUTY_OF_CARE_TEAM",
  "TALENT_TEAM",
  "INCLUSION_TEAM",
  "DIVERSITY_TEAM",
  "LABEL_TECHNICAL_MANAGER",
  "POST_PRODUCTION",
  "COMPLIANCE",
  "MUSIC",
  "PROCUREMENT",
  "CYBER_SECURITY",
];

export interface AttendeesFormProps {
  attendees: Attendee[];
  blurHandler: (id: any, newValue: any) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<any>>;
}

export const AttendeesForm: FC<AttendeesFormProps> = ({
  attendees,
  blurHandler,
  setFieldValue,
}) => {
  const handleOnAdd = useCallback(
    (role?: any) => {
      const newAttendee: Attendee = {
        forenames: "",
        surname: "",
        role: role || "",
        email: "",
      };
      setFieldValue("attendee.attendees", [...attendees, newAttendee]);
    },
    [setFieldValue, attendees],
  );

  return (
    <Card>
      <CardContent>
        <Typography>Attendees</Typography>
        <FormLabel>
          Who is the core team and label working on this production?
        </FormLabel>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <FormLabel>Recommended Roles to include: </FormLabel>
            </Grid>

            <Grid item xs={12} paddingTop="1rem">
              {attendeeRoles.map((role) => (
                <Box key={role} padding="0.5rem" display="inline-block">
                  <Button
                    color="inherit"
                    variant="contained"
                    style={{
                      backgroundColor: attendees.some(
                        (attendee) => attendee.role === role,
                      )
                        ? "#0b56b3"
                        : "transparent",
                      color: attendees.some(
                        (attendee) => attendee.role === role,
                      )
                        ? "#ffffff"
                        : "inherit",
                    }}
                    onClick={() => {
                      handleOnAdd(role);
                    }}
                  >
                    <Typography fontSize="1rem">
                      {roleToDisplayName(role)}
                    </Typography>
                  </Button>
                </Box>
              ))}
            </Grid>

            <Grid item xs={12} paddingTop="2rem">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => handleOnAdd()}
                >
                  Add a new start-up collaborator
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} paddingTop="2rem">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Forename</TableCell>
                      <TableCell>Surname</TableCell>
                      <TableCell className="role-column">Role</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell className="delete">Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ height: "1rem" }} /> {/* Empty row */}
                    {attendees.map((innerAttendee, index) => (
                      <AttendeeRow
                        key={index}
                        attendee={innerAttendee}
                        attendeeRoles={attendeeRoles}
                        onDelete={(attendeeToDelete) => {
                          const updatedAttendees = attendees.filter(
                            (attendee) => attendee !== attendeeToDelete,
                          );
                          setFieldValue("attendee.attendees", updatedAttendees);
                          blurHandler("attendee.attendees", updatedAttendees);
                        }}
                        onInternalChange={(updatedAttendee) => {
                          setFieldValue(
                            `attendee.attendees[${index}]`,
                            updatedAttendee,
                          );
                        }}
                        blurTriggered={() =>
                          blurHandler("attendee.attendees", attendees)
                        }
                      />
                    ))}
                    <TableRow style={{ height: "1rem" }} /> {/* Empty row */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <ModalWrapper msgPrefix="attendee" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
