import { Card, CardContent, Typography, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import theme from "../utils/theme";
import { useTasks } from "../hooks/queries";
import { TaskCard } from "./TaskSection";
interface AuxiliaryActionProps {
  ccid: string | undefined;
}
interface AuxiliaryActionItem {
  icon: React.ReactNode;
  title: string;
  navUrl: string;
  id: string;
}

const items: Array<AuxiliaryActionItem> = [
  {
    id: "MANAGE-EPISODES",
    icon: <AddIcon />,
    title: "Manage Episodes",
    navUrl: "./manage-episodes",
  },
  {
    id: "UPDATE-NAME",
    icon: <EditIcon />,
    title: "Edit Name",
    navUrl: "./edit-title",
  },
  {
    id: "ASSIGN-PRODUCTION-LEAD",
    icon: <PersonIcon />,
    title: "Re/Assign Production Lead",
    navUrl: "./production-lead",
  },
];

export function AuxActionsTile({ ccid }: AuxiliaryActionProps) {
  const { data: taskData, isLoading } = useTasks(ccid);
  return isLoading ? (
    <></>
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h5">Auxiliary Actions</Typography>

        <Typography
          variant="body2"
          paddingBottom="24px"
          color={theme.palette.text.secondary}
        >
          These actions are not required but aid in configuring production
          details.
        </Typography>

        <Stack direction="column" spacing={1}>
          {items.map((item) => {
            const taskList = taskData?.tasks || [];
            const matchedTask = taskList.find((task) => {
              return task.taskName === item.id;
            });
            if (matchedTask)
              return (
                <TaskCard
                  key={item.id}
                  task={matchedTask}
                  taskDetails={{
                    description: item.title,
                    icon: (item.icon as JSX.Element) || <></>,
                    taskRoute: item.navUrl,
                    taskStatusUrl: null,
                  }}
                />
              );

            return null;
          })}
        </Stack>
      </CardContent>
    </Card>
  );
}
