import * as React from "react";
import * as Yup from "yup";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useTimecodes,
  useTitles,
  useTitleVersions,
  useUpdateTimecodes,
} from "../../../../../hooks";
import {
  Timecode,
  TimecodePreview,
  TimecodeTag,
  VideoMetadataResponsePayload,
} from "../../../../../types/types";
import { VideoMetaDataPayload } from "../../../Video";
import { VisuallyHidden } from "../../../../../componentsV2/VisuallyHidden";
import {
  FieldArray,
  Form,
  Formik,
  FormikHandlers,
  FormikHelpers,
} from "formik";
import { LoadingButton } from "@mui/lab";
import { AddCircle } from "@mui/icons-material";
import { FRAME_RATE, isValidTimecode } from "./util";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";
import theme from "../../../../../utils/theme";
import {
  TimeCodeVideoPlayer,
  TimecodeVideoPlayerError,
} from "./TimeCodeVideoPlayer";
import TimecodeConstructor from "smpte-timecode";
import axios from "axios";

interface PreviewTimeCodesProps {
  tag: TimecodeTag;
}

interface TitleSelectOption {
  ccid: string;
  label: string;
}

type VersionToTimecodeMap = Record<string, Array<Timecode>>;

const timecodeConfig: Record<
  TimecodeTag,
  { title: string; description: string }
> = {
  preview: {
    title: "Preview Timecodes",
    description:
      "Please provide the best timecodes for auto-playing previews on streaming platforms.",
  },
  preTitleSequence: {
    title: "Pre Title Sequence Timecodes",
    description: "Please provide the best timecodes for pre title sequence",
  },
  episodeClip: {
    title: "Episode Clip",
    description:
      "Please provide timecode for an extract clip from this episode",
  },
  marketingPromoClip: {
    title: "Marketing Promo Clip",
    description: "Please provide the best timecodes for a marketing promo clip",
  },
};

export function PreviewTimeCodes({ tag }: PreviewTimeCodesProps) {
  const { seriesCcid, titleCcid } = useParams();

  const ccid = seriesCcid || titleCcid;
  const level = seriesCcid ? "series" : "titles";

  const [selectedTitle, setSelectedTitle] =
    React.useState<TitleSelectOption | null>(null);

  const { data: titles, isLoading: loadingTitles } = useTitles(
    seriesCcid,
    "series",
  );

  const {
    data: timecodes,
    isLoading: loadingTimecodes,
    error: timecodesError,
  } = useTimecodes({
    ccid,
    level,
    tag,
  });

  const isLoading = loadingTitles || loadingTimecodes;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" padding={8}>
        <CircularProgress />
      </Box>
    );
  }

  if (!timecodes) {
    if (
      !axios.isAxiosError(timecodesError) ||
      !timecodesError.response ||
      timecodesError.response.status !== 404
    ) {
      return (
        <PageError>
          There was an issue retrieving the timecodes for this production.
          Please contact us if this persists
        </PageError>
      );
    }
  }

  if (level === "series" && !titles) {
    return (
      <PageError>
        There was an issue retrieving the episodes for this series. Please
        contact us if this persists
      </PageError>
    );
  }

  const showTXMastersNotice = tag !== "episodeClip" && level === "series";

  const config = timecodeConfig[tag];

  const timecodePreviews = timecodes ? timecodes.previews : [];

  const versionCcidToTimecode: VersionToTimecodeMap = timecodePreviews.reduce(
    (previous, current) => {
      if (!previous[current.versionCcid]) {
        previous[current.versionCcid] = [];
      }

      previous[current.versionCcid].push(current.timecode);
      return previous;
    },
    {} as VersionToTimecodeMap,
  );

  /**
   * for a series, this will be the CCID of the episode chosen using the dropdown
   * for a special, this will be the CCID of the special taken from the URL
   */
  const currentTitleCcid = selectedTitle ? selectedTitle.ccid : titleCcid;

  return (
    <Container sx={{ py: 2, marginBottom: "8rem" }}>
      <Stack direction="column" spacing={2}>
        {showTXMastersNotice ? (
          <Alert severity="info">
            If you'd like to see TX Masters for this series, please select a
            specific episode.
          </Alert>
        ) : null}

        <Box py={2}>
          <Typography variant="h5">{config.title}</Typography>
          <Typography variant="subtitle1">{config.description}</Typography>
        </Box>

        {level === "series" && tag !== "episodeClip" ? (
          <TitleSelect
            selectedTitle={selectedTitle}
            onChange={setSelectedTitle}
            options={
              titles
                ? titles.titleDetails
                    .sort((a, b) => a.episodeNumber - b.episodeNumber)
                    .map((title) => ({
                      label: title.name
                        ? `Episode ${title.episodeNumber} - ${title.name}`
                        : `Episode ${title.episodeNumber}`,
                      ccid: title.ccid,
                    }))
                : []
            }
          />
        ) : null}

        {currentTitleCcid ? (
          <EditTimecodesContainer
            titleCcid={currentTitleCcid}
            versionCcidToTimecode={versionCcidToTimecode}
            allTimecodes={timecodePreviews}
            tag={tag}
            ccid={ccid as string}
            level={level}
          />
        ) : null}
      </Stack>
    </Container>
  );
}

function TitleSelect({
  selectedTitle,
  onChange,
  options,
}: {
  selectedTitle: TitleSelectOption | null;
  onChange: (option: TitleSelectOption | null) => void;
  options: Array<TitleSelectOption>;
}) {
  return (
    <Autocomplete
      id="episode-select"
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      options={options}
      value={selectedTitle}
      isOptionEqualToValue={(option, value) => {
        return option.ccid === value.ccid;
      }}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => <TextField {...params} label="Select Episode" />}
      sx={{ width: "300px" }}
    />
  );
}

function EditTimecodesContainer({
  titleCcid,
  versionCcidToTimecode,
  allTimecodes,
  tag,
  ccid,
  level,
}: {
  titleCcid: string;
  versionCcidToTimecode: VersionToTimecodeMap;
  allTimecodes: Array<TimecodePreview>;
  tag: TimecodeTag;
  /**
   * The CCID that will be used in the save endpoint
   */
  ccid: string;
  level: "series" | "titles";
}) {
  const { data: versionsData, isLoading } = useTitleVersions(titleCcid);

  if (isLoading) {
    return <CircularProgress size="24px" />;
  }

  if (!versionsData) {
    return (
      <Box display="flex">
        <Alert severity="error">
          There was an issue retrieving the versions for this title. Please
          contact us if this persists
        </Alert>
      </Box>
    );
  }

  return (
    <EditTimecodes
      key={titleCcid}
      versionCcidToTimecode={versionCcidToTimecode}
      versionsData={versionsData}
      titleCcid={titleCcid}
      allTimecodes={allTimecodes}
      tag={tag}
      ccid={ccid}
      level={level}
    />
  );
}

function EditTimecodes({
  versionCcidToTimecode,
  versionsData,
  allTimecodes,
  tag,
  ccid,
  level,
  titleCcid,
}: {
  versionsData: VideoMetadataResponsePayload;
  titleCcid: string;
  versionCcidToTimecode: VersionToTimecodeMap;
  allTimecodes: Array<TimecodePreview>;
  tag: TimecodeTag;
  /**
   * The CCID that will be used in the save endpoint
   */
  ccid: string;
  level: "series" | "titles";
}) {
  const [selectedVersionCcid, setSelectedVersionCcid] = React.useState<
    string | null
  >(
    versionsData.versions.length > 0
      ? versionsData.versions[0].versionCcid
      : null,
  );

  const versionRadioGroupId = "version-select";

  return (
    <>
      <FormControl>
        <Box display="inline-flex" alignItems="center">
          <FormLabel id={`${versionRadioGroupId}-label`}>
            <VisuallyHidden>Select version</VisuallyHidden>
          </FormLabel>
        </Box>

        <RadioGroup
          id={versionRadioGroupId}
          name={versionRadioGroupId}
          value={selectedVersionCcid || ""}
          aria-labelledby={`${versionRadioGroupId}-label`}
        >
          {versionsData.versions.map((version) => (
            <EditVersionTimecodes
              key={version.versionCcid}
              version={version}
              isSelected={version.versionCcid === selectedVersionCcid}
              onChange={setSelectedVersionCcid}
              versionTimecodes={
                versionCcidToTimecode[version.versionCcid] || []
              }
              allTimecodes={allTimecodes}
              titleCcid={titleCcid}
              tag={tag}
              ccid={ccid}
              level={level}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}

function EditVersionTimecodes({
  isSelected,
  version,
  onChange,
  allTimecodes,
  titleCcid,
  versionTimecodes,
  tag,
  ccid,
  level,
}: {
  isSelected: boolean;
  version: VideoMetaDataPayload;
  onChange: (versionCcid: string) => void;
  versionTimecodes: Array<Timecode>;
  allTimecodes: Array<TimecodePreview>;
  titleCcid: string;
  tag: TimecodeTag;
  /**
   * The CCID that will be used in the save endpoint
   */
  ccid: string;
  level: "series" | "titles";
}) {
  const hasFixedTimecodeLength = tag === "marketingPromoClip";

  return (
    <>
      <Stack
        bgcolor={isSelected ? "#EEF4F4" : undefined}
        paddingY={1}
        paddingX={2}
        direction="column"
        spacing={2}
      >
        <FormControlLabel
          key={version.versionCcid}
          label={
            <>
              <Typography component="span" fontSize="1.1rem" fontWeight="500">
                Production ID:{" "}
              </Typography>
              <Typography component="span" fontSize="1.1rem">
                {version.productionId || "N/A"}
              </Typography>
            </>
          }
          value={version.versionCcid}
          control={
            <Radio
              onChange={() => {
                onChange(version.versionCcid);
              }}
            />
          }
        />

        <Box>
          <Typography fontWeight="500">Edit Reason(s):</Typography>
          <Typography>
            {version.editReasons.length > 0
              ? version.editReasons.join(", ")
              : "-"}
          </Typography>
        </Box>

        {isSelected ? (
          <TimecodeForm
            initialValues={{
              timecodes: versionTimecodes.map((timecode) => ({
                start: timecode.start,
                end: timecode.end,
                isNew: false,
                fixedTimecodeLength: hasFixedTimecodeLength
                  ? FIXED_TIMECODE_LENGTHS.find(({ value }) => {
                      return isMatchingTimecode({ seconds: value, timecode });
                    })?.value || null
                  : null,
              })),
            }}
            allTimecodes={allTimecodes}
            titleCcid={titleCcid}
            versionCcid={version.versionCcid}
            tag={tag}
            ccid={ccid}
            level={level}
            hasFixedTimecodeLength={hasFixedTimecodeLength}
          />
        ) : null}
      </Stack>

      <Divider
        sx={{
          my: 3,
          borderColor: theme.palette.primary.main,
          borderBottomWidth: "1px",
          opacity: 1,
        }}
      />
    </>
  );
}

const timecodeSchema = Yup.object().shape({
  start: Yup.string()
    .required("Start time is required")
    .test("valid-timecode", "Required format: HH:MM:SS:FF", (value: string) => {
      return isValidTimecode(value);
    }),
  end: Yup.string()
    .required("End time is required")
    .test("valid-timecode", "Required format: HH:MM:SS:FF", (value: string) => {
      return isValidTimecode(value);
    }),
  // internal value used for specific behaviours for new timecode rows
  isNew: Yup.boolean().required(),
  fixedTimecodeLength: Yup.number().nullable().defined(),
});

type FormTimecode = Yup.InferType<typeof timecodeSchema>;

const timecodesFormSchema = Yup.object().shape({
  timecodes: Yup.array().of(timecodeSchema).required(),
});

type TimecodeFormValues = Yup.InferType<typeof timecodesFormSchema>;

function TimecodeForm({
  initialValues,
  allTimecodes,
  titleCcid,
  versionCcid,
  tag,
  ccid,
  level,
  hasFixedTimecodeLength,
}: {
  initialValues: TimecodeFormValues;
  allTimecodes: Array<TimecodePreview>;
  titleCcid: string;
  versionCcid: string;
  tag: TimecodeTag;
  /**
   * The CCID that will be used in the save endpoint
   */
  ccid: string;
  level: "series" | "titles";
  hasFixedTimecodeLength: boolean;
}) {
  const { mutate: submitTimecodes, isLoading: isSaving } = useUpdateTimecodes();

  const { refetch: refetchTimecodes } = useTimecodes({
    ccid,
    level,
    tag,
  });

  return (
    <Formik<TimecodeFormValues>
      validationSchema={timecodesFormSchema}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        const untouchedTimecodes = allTimecodes.filter(
          (timecode) => timecode.versionCcid !== versionCcid,
        );

        const newTimecodes = values.timecodes.map(
          (timecode): TimecodePreview => {
            return { versionCcid, titleCcid, timecode };
          },
        );

        const payloadTimecodes = untouchedTimecodes.concat(newTimecodes);

        submitTimecodes(
          {
            ccid,
            level,
            payload: { tag, previews: payloadTimecodes },
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Timecodes saved successfully!", {
                variant: "success",
              });

              refetchTimecodes();

              resetForm({
                values: {
                  timecodes: values.timecodes.map((timecode) => ({
                    ...timecode,
                    isNew: false,
                  })),
                },
              });
            },
            onError: () => {
              enqueueSnackbar("Failed to save timecodes. Please try again", {
                action: SnackbarDismiss,
                persist: true,
                variant: "error",
              });
            },
          },
        );
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        dirty,
        resetForm,
        setFieldValue,
      }) => {
        const getStartError = (index: number): string | null => {
          if (!errors.timecodes) {
            return null;
          }

          const timecodeError = errors.timecodes[index];

          if (!timecodeError) {
            return null;
          }

          if (typeof timecodeError === "string") {
            return timecodeError;
          }

          return timecodeError.start || null;
        };

        const getEndError = (index: number): string | null => {
          if (!errors.timecodes) {
            return null;
          }

          const timecodeError = errors.timecodes[index];

          if (!timecodeError) {
            return null;
          }

          if (typeof timecodeError === "string") {
            return timecodeError;
          }

          return timecodeError.end || null;
        };

        return (
          <Form noValidate>
            <Stack direction="column" spacing={2}>
              <FieldArray name="timecodes">
                {({ push, remove }) => {
                  return (
                    <>
                      <Stack
                        direction="row"
                        spacing={1}
                        position="sticky"
                        top="0"
                        bgcolor="#EEF4F4"
                        zIndex={10}
                      >
                        <LoadingButton
                          type="submit"
                          color="primary"
                          variant="contained"
                          loading={isSaving}
                          disabled={!dirty}
                        >
                          Save changes
                        </LoadingButton>

                        <Button
                          type="button"
                          disabled={!dirty || isSaving}
                          onClick={() => {
                            resetForm();
                          }}
                          color="primary"
                          variant="outlined"
                        >
                          Reset changes
                        </Button>
                      </Stack>
                      {values.timecodes.map((timecode, index) => {
                        const startError = getStartError(index);
                        const endError = getEndError(index);

                        const isTouched = Boolean(touched.timecodes?.[index]);

                        return (
                          <TimecodeRow
                            key={index}
                            timecode={timecode}
                            formStartError={startError}
                            formEndError={endError}
                            isTouched={isTouched}
                            index={index}
                            removeTimecode={() => {
                              remove(index);
                            }}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            isSaving={isSaving}
                            versionCcid={versionCcid}
                            hasFixedTimecodeLength={hasFixedTimecodeLength}
                            setFieldValue={setFieldValue}
                          />
                        );
                      })}

                      <Box marginRight="auto">
                        <Button
                          type="button"
                          onClick={() => {
                            push({
                              start: "",
                              end: "",
                              isNew: true,
                              fixedTimecodeLength: null,
                            });
                          }}
                          color="primary"
                          startIcon={<AddCircle />}
                          variant="contained"
                          disabled={isSaving}
                        >
                          Add new timecode
                        </Button>
                      </Box>
                    </>
                  );
                }}
              </FieldArray>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}

/**
 * The number of frames that get added on to each fixed timecode length
 */
const FIXED_FRAME_OFFSET = 14;

const FIXED_TIMECODE_LENGTHS = [
  { value: 15, label: "15 seconds and 14 frames" },
  { value: 16, label: "16 seconds and 14 frames" },
  { value: 17, label: "17 seconds and 14 frames" },
];

function TimecodeRow({
  timecode,
  isTouched,
  formStartError,
  formEndError,
  index,
  handleChange,
  handleBlur,
  removeTimecode,
  isSaving,
  versionCcid,
  hasFixedTimecodeLength,
  setFieldValue,
}: {
  timecode: FormTimecode;
  isTouched: boolean;
  formStartError: string | null;
  formEndError: string | null;
  index: number;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  removeTimecode: () => void;
  isSaving: boolean;
  versionCcid: string;
  hasFixedTimecodeLength: boolean;
  setFieldValue: FormikHelpers<TimecodeFormValues>["setFieldValue"];
}) {
  const [videoPlayerError, setVideoPlayerError] = React.useState<
    | { start: null; end: null }
    | { start: string; end: null }
    | { start: null; end: string }
  >({ start: null, end: null });

  const startError = formStartError || videoPlayerError.start;
  const endError = formEndError || videoPlayerError.end;

  const deriveAndSetEndTime = ({
    startTime,
    fixedLength,
  }: {
    startTime: string;
    fixedLength: number | null;
  }) => {
    if (isValidTimecode(startTime) && fixedLength) {
      const derivedEndTime = TimecodeConstructor(startTime, FRAME_RATE)
        .add(
          TimecodeConstructor(
            {
              hours: 0,
              minutes: 0,
              seconds: fixedLength,
              frames: FIXED_FRAME_OFFSET,
            },
            FRAME_RATE,
          ),
        )
        .toString();

      setFieldValue(`timecodes.${index}.end`, derivedEndTime);
    } else {
      setFieldValue(`timecodes.${index}.end`, "");
    }
  };

  const handleErrorChange = React.useCallback(
    (error: TimecodeVideoPlayerError | null) => {
      if (error) {
        switch (error) {
          case "start-time-negative": {
            setVideoPlayerError({
              start: "Start time can not be earlier than video start time",
              end: null,
            });
            break;
          }
          case "duration-too-large": {
            setVideoPlayerError({
              start: null,
              end: "Clip is longer than video duration",
            });
            break;
          }
          case "end-time-too-small": {
            setVideoPlayerError({
              start: null,
              end: "End time can not be earlier than start time",
            });
            break;
          }
          case "end-time-too-large": {
            setVideoPlayerError({
              start: null,
              end: "End time can not be later than video end time",
            });
            break;
          }
          default:
            return assertUnreachable(error);
        }
      } else {
        setVideoPlayerError({ start: null, end: null });
      }
    },
    [],
  );

  const handleDropdownChange = (e: SelectChangeEvent<number>) => {
    const secondsToAdd = Number(e.target.value);
    setFieldValue(`timecodes.${index}.fixedTimecodeLength`, secondsToAdd);
    deriveAndSetEndTime({
      startTime: timecode.start,
      fixedLength: secondsToAdd,
    });
  };

  const isFixedDropdownDisabled = Boolean(startError);

  return (
    <Stack direction="row" alignItems="center" spacing={3} padding="0 0.5rem">
      <Stack direction="row" spacing={3} alignItems="flex-start" width="100%">
        <TextField
          sx={{ minWidth: "200px" }}
          autoFocus={timecode.isNew}
          size="small"
          label="Start of media"
          name={`timecodes.${index}.start`}
          error={isTouched && Boolean(startError)}
          onChange={(event) => {
            handleChange(event);

            if (hasFixedTimecodeLength) {
              deriveAndSetEndTime({
                startTime: event.target.value,
                fixedLength: timecode.fixedTimecodeLength,
              });
            }
          }}
          onBlur={handleBlur}
          placeholder="HH:MM:SS:FF"
          value={timecode.start}
          helperText={isTouched && startError ? startError : " "}
          FormHelperTextProps={{
            sx: {
              marginLeft: "2px",
              marginRight: 0,
              marginTop: "2px",
            },
          }}
        />

        {hasFixedTimecodeLength ? (
          <FormControl fullWidth size="small">
            <InputLabel id="end-time-label">Length of promo clip</InputLabel>
            <Select
              value={timecode.fixedTimecodeLength || ""}
              onChange={handleDropdownChange}
              disabled={isFixedDropdownDisabled}
              label="Length of promo clip"
              error={isTouched && !isFixedDropdownDisabled && Boolean(endError)}
              fullWidth
            >
              {FIXED_TIMECODE_LENGTHS.map((fixedLength) => (
                <MenuItem key={fixedLength.value} value={fixedLength.value}>
                  {fixedLength.label}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText
              sx={{
                marginLeft: "2px",
                marginRight: 0,
                marginTop: "2px",
              }}
              error={isTouched && !isFixedDropdownDisabled && Boolean(endError)}
            >
              {isTouched && !isFixedDropdownDisabled && Boolean(endError)
                ? endError
                : `End of media: ${timecode.end || "-"}`}
            </FormHelperText>
          </FormControl>
        ) : (
          <TextField
            sx={{ minWidth: "200px" }}
            size="small"
            label="End of media"
            name={`timecodes.${index}.end`}
            error={isTouched && Boolean(endError)}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="HH:MM:SS:FF"
            value={timecode.end}
            helperText={isTouched && endError ? endError : " "}
            FormHelperTextProps={{
              sx: {
                marginLeft: "2px",
                marginRight: 0,
                marginTop: "2px",
              },
            }}
          />
        )}

        <Button
          type="button"
          onClick={removeTimecode}
          color="error"
          variant="contained"
          disabled={isSaving}
        >
          Delete
        </Button>
      </Stack>

      <div>
        <TimeCodeVideoPlayer
          timeRange={{
            startTime: timecode.start,
            endTime: timecode.end,
          }}
          selectedVersionId={versionCcid}
          onErrorChange={handleErrorChange}
        />
      </div>
    </Stack>
  );
}

// used to ensure that the switch statement is exhaustive
function assertUnreachable(_x: never): never {
  throw new Error("Unreachable");
}

/**
 * Determines whether, given a base number of seconds, the option matches the current time range after applying
 * the fixed frame offset to it
 */
function isMatchingTimecode({
  timecode,
  seconds,
}: {
  timecode: Timecode;
  seconds: number;
}): boolean {
  if (!timecode.start || !timecode.end) {
    return false;
  }

  if (!isValidTimecode(timecode.start) || !isValidTimecode(timecode.end)) {
    return false;
  }

  const offset = TimecodeConstructor(
    {
      hours: 0,
      minutes: 0,
      seconds,
      frames: FIXED_FRAME_OFFSET,
    },
    FRAME_RATE,
  );

  const start = TimecodeConstructor(timecode.start, FRAME_RATE);
  const end = TimecodeConstructor(timecode.end, FRAME_RATE);

  return end.subtract(offset).toString() === start.toString();
}

function PageError({ children }: { children: React.ReactNode }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingY={5}
    >
      <Alert severity="error">{children}</Alert>
    </Box>
  );
}
