/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from "axios";
import { Summary } from "../containers/NotificationForm/FormStatus";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import {
  RiskScore,
  DropdownMetadata,
  ProductionNotificationForm,
  ProductionNotificationFormV3,
  UserSpotForms,
} from "../types/types";
import axiosRetry from "axios-retry";
import { getInitialNotificationFormValuesV3 } from "../containers/NotificationForm/getInitialNotificationFormValues";

const API_BASE = getAPI(SERVICES.spotlight);
const client: AxiosInstance = axios.create();
axiosRetry(client, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
});

export async function getRiskFormDataNew<Version extends "v2" | "v3">({
  ccid,
  version,
}: {
  ccid: string;
  version: Version;
}): Promise<
  Version extends "v2"
    ? ProductionNotificationForm
    : ProductionNotificationFormV3
> {
  const requestUrl = `${API_BASE}/${version}/ccid/${ccid}/risk`;

  return handleRequest
    .get(requestUrl, { timeout: 60000 })
    .then(({ data }) => data);
}

export const getSummaryNew = ({ ccid }: { ccid: string }): Promise<Summary> =>
  handleRequest
    .get(`${API_BASE}/ccid/${ccid}/risk/summary`, { timeout: 60000 })
    .then(({ data }) => data);

export interface RiskFormProductionInfo {
  label: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
}

export const updateRiskFormDataNew = ({
  ccid,
  formData,
  version,
}: {
  ccid: string;
  formData: Record<string, any>;
  version: "v2" | "v3";
}): Promise<Record<string, any>> => {
  const url = `${API_BASE}/${version}/ccid/${ccid}/risk`;

  return handleRequest
    .put(url, formData, { timeout: 60000 }, client)
    .then(({ data }) => data);
};

export const updateRiskFormDataImportOnly = ({
  ccid,
  formData,
  version,
}: {
  ccid: string;
  formData: Record<string, any>;
  version: "v2" | "v3";
}): Promise<Record<string, any>> => {
  const url = `${API_BASE}/${version}/ccid/${ccid}/risk/import`;

  return handleRequest
    .put(url, formData, { timeout: 60000 }, client)
    .then(({ data }) => data);
};

export async function updateRiskFormDataWithPrevious(
  importFromCcid: string,
  ccid: string,
  version: "v2" | "v3",
) {
  try {
    const riskFormData = await getRiskFormDataNew({
      ccid: importFromCcid,
      version: "v3",
    });

    const filteredFormData = getInitialNotificationFormValuesV3(
      riskFormData,
      true,
    );

    return updateRiskFormDataImportOnly({
      ccid,
      formData: filteredFormData,
      version,
    }).then((data) => {
      return data;
    });
  } catch (error) {
    console.error("Error processing risk form data:", error);
    throw error;
  }
}

export const getUserSpotForms = ({
  ccid,
}: {
  ccid: string;
}): Promise<UserSpotForms> =>
  handleRequest
    .get(`${API_BASE}/ccid/${ccid}/forms/available`, { timeout: 60000 })
    .then(({ data }) => data);

export const submitRiskFormDataNew = ({
  riskId,
  formData,
  version,
}: {
  riskId: string;
  formData: Record<string, any>;
  version: "v2" | "v3";
}): Promise<Record<string, any>> => {
  const url = `${API_BASE}/${version}/ccid/${riskId}/risk/submit`;
  return new Promise((resolve, reject) => {
    handleRequest
      .put(url, formData, { timeout: 60000 }, client)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        reject(error?.response?.data);
      });
  });
};

export const getMetaDataDropdownNew = (): Promise<DropdownMetadata> => {
  const url = `${API_BASE}/metadata/dropdown`;
  return handleRequest.get(url, { timeout: 60000 }).then(({ data }) => data);
};

export const getRiskScoreNew = ({
  ccid,
  version,
}: {
  ccid: string;
  version: "v2" | "v3";
}): Promise<RiskScore> =>
  handleRequest
    .get(`${API_BASE}/${version}/ccid/${ccid}/score`, { timeout: 60000 })
    .then(({ data }) => data);

export const updateRiskVersion = ({
  ccid,
  version,
}: {
  ccid: string;
  version: "v2" | "v3";
}): Promise<void> =>
  handleRequest
    .put(
      `${API_BASE}/ccid/${ccid}/version`,
      { version: version.slice(1) },
      { timeout: 60000 },
    )
    .then(({ data }) => data);
