import { useState } from "react";
import { Alert, Box, Button, Paper, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { AddUserDialog } from "./AddUserDialog";
import { useCollaborators } from "../../hooks/queries/useCollaborators";
import { useRemoveCollaborator } from "../../hooks/mutations/useRemoveCollaborator";
import { ProductionType } from "../../types/types";

export const AccessManagementTask = (): JSX.Element => {
  const { seriesCcid, titleCcid } = useParams<string>();
  const productionType: ProductionType =
    seriesCcid === "0" || seriesCcid === undefined ? "special" : "series";
  const ccid = productionType === "series" ? seriesCcid : titleCcid;

  const { data: users, isLoading: loadingCollaborators } =
    useCollaborators(ccid);
  const {
    mutateAsync: removeCollaborator,
    isLoading: removingCollaborator,
    error: removeCollaboratorError,
  } = useRemoveCollaborator();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      width: 300,
      editable: false,
    },
  ];

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setSelectedUsers(newSelection as string[]);
  };

  const handleRemoveCollaborators = async () => {
    if (ccid) {
      const mutationPromises = await selectedUsers.map((userEmail) =>
        removeCollaborator({ ccid, userEmail }),
      );
      Promise.all(mutationPromises).then(() => setSelectedUsers([])); // wait for all the mutations to finish before clearing the selection of users
      // don't deal with error here - using the error from the mutation
    }
  };

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Typography variant="h5">
          {productionType === "series" ? "Series" : "Special"} Access Management
        </Typography>
        <Typography>
          The table below shows users who have access to complete tasks within
          this {productionType === "series" ? "current series" : "special"} .
          You can grant and remove access for users on this page.
        </Typography>
      </Box>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" pb={2}>
          Users with access to this{" "}
          {productionType === "series" ? "series" : "special"}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <AddUserDialog />
          <Button
            variant="contained"
            disabled={selectedUsers.length === 0 || removingCollaborator}
            onClick={handleRemoveCollaborators}
          >
            Remove selected
          </Button>
        </Stack>
        {removeCollaboratorError ? (
          <Box pt={2}>
            <Alert severity="error">
              There was an error removing collaborators from the{" "}
              {productionType === "series" ? "series" : "special"} - please try
              again.
            </Alert>
          </Box>
        ) : null}
        <Box py={2} sx={{ height: 663, width: "100%" }}>
          <DataGrid
            loading={loadingCollaborators || removingCollaborator || !users}
            rows={users || []}
            columns={columns}
            getRowId={(user) => user.email}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedUsers}
            onRowSelectionModelChange={handleSelectionChange}
            sx={{
              ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                {
                  marginTop: "1em",
                  marginBottom: "1em",
                },
            }}
          />
        </Box>
      </Paper>
    </>
  );
};
