/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Box,
  Dialog,
  Button,
  Stack,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { AddNewCollaborator } from "../../../../AccessManagement/components/AddNewCollaborator";
import { AssignTasksSideNav } from "./AssignTasksSideNav";
import {
  useGetTaskGroupsData,
  useUpdateTaskGroup,
  useDeleteTaskGroupUser,
} from "../../../../../hooks";
import { TaskGroups, assigneeDetails } from "../../../../../types/types";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";
import { AccessManagementTask } from "../../../../../tasks/AccessManagementTask/AccessManagementTask";

const capitalSnakecaseParser = (input: string) => {
  const words = input.split("-");
  const transformedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );
  return transformedWords.join(" ");
};

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const TabPanel = ({
  children,
  index,
  value,
}: {
  children: React.ReactNode;
  index: number;
  value: number;
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const AssignTasks = () => {
  const [tabValue, setTabValue] = useState(0);

  return (
    <Container sx={{ py: 4 }}>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={(_event, newValue: number) => {
              setTabValue(newValue);
            }}
            aria-label="todo"
          >
            <Tab label="Assign Tasks" {...a11yProps(0)} />
            <Tab label="Manage Access" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <TasksTab />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <AccessManagementTask />
        </TabPanel>
      </Box>
    </Container>
  );
};

export const TasksTab = () => {
  const { seriesCcid, titleCcid } = useParams();
  const queryClient = useQueryClient();
  const levelCcid = titleCcid || seriesCcid;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedTaskGroup, setSelectedTaskGroup] = useState<TaskGroups>(
    TaskGroups.NewProductionNotificationForm,
  );
  const [rowData, setRowData] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const { data: assigneeData, isLoading: dataLoading } = useGetTaskGroupsData(
    levelCcid as string,
  );
  const { mutate: addAssignees } = useUpdateTaskGroup(
    () => {
      enqueueSnackbar("Task assigned successfully!", { variant: "success" });
      queryClient.invalidateQueries("tasksGroupData");
    },
    () => {
      enqueueSnackbar("Error on saving assignee", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  const { mutate: deleteAssignee } = useDeleteTaskGroupUser(
    () => {
      enqueueSnackbar("User Removed successfully!");
      queryClient.invalidateQueries("tasksGroupData");
    },
    () => {
      enqueueSnackbar("Error on removing assigne!", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 400,
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
      },
      {
        field: "role",
        headerName: "Role",
        width: 250,
      },
    ],
    [],
  );

  useEffect(() => {
    if (assigneeData && assigneeData.groupAssignments) {
      const detailsArray: assigneeDetails[] =
        assigneeData.groupAssignments[selectedTaskGroup] ?? [];
      setRowData(
        detailsArray.map((details: assigneeDetails) => {
          return {
            name: `${details.firstName} ${details.lastName}`,
            email: details.email,
            role: details.role,
          };
        }),
      );
    }
  }, [assigneeData, selectedTaskGroup]);

  return (
    <>
      <Stack direction="row">
        <AssignTasksSideNav
          taskGroups={
            assigneeData?.groupAssignments
              ? (Object.keys(assigneeData?.groupAssignments) as TaskGroups[])
              : Object.values(TaskGroups).filter(
                  (value) => value !== TaskGroups.LegalDeliverables,
                )
          }
          setSelectedTaskGroup={setSelectedTaskGroup}
        />
        <Stack direction="column">
          <Typography variant="h5">{`Assign User to: ${capitalSnakecaseParser(selectedTaskGroup as string)}`}</Typography>
          <Box
            sx={{
              height: "536px",
              width: "100%",
              my: 2,
              backgroundColor: "white",
            }}
          >
            <DataGrid
              rows={rowData}
              loading={dataLoading}
              getRowId={(row) => row?.email}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(ids) => {
                setSelectedRows(ids as string[]);
              }}
              sx={{
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                  {
                    marginTop: "1em",
                    marginBottom: "1em",
                  },
              }}
            />
          </Box>
          <Stack sx={{ px: 1 }} direction="row" justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              Add Collaborators to Task
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={selectedRows.length < 1}
              onClick={() => {
                deleteAssignee({
                  ccid: levelCcid,
                  users: selectedRows,
                  taskGroup: selectedTaskGroup,
                });
              }}
            >
              Delete Selected Assignees
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Dialog open={dialogOpen}>
        <AddNewCollaborator
          onAdd={(users) => {
            const usermails = users.map((userInstance) => {
              return userInstance.email;
            });
            setDialogOpen(false);
            addAssignees({
              ccid: levelCcid,
              users: usermails,
              taskGroup: selectedTaskGroup,
            });
          }}
          onCancel={() => {
            setDialogOpen(false);
          }}
        />
      </Dialog>
    </>
  );
};
