/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-restricted-syntax */
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import { StepItemStatus, StepItem } from "../../components/Steps";
import { RiskScore, RiskScoreId } from "../../types/types";

const EMPTY = "EMPTY";

export const requiredFieldsCompleted = (fields: any[], formData: any) => {
  for (const filed of fields) {
    const value = formData?.[filed.id];

    if (filed.fields) {
      const completed = requiredFieldsCompleted(filed.fields, formData);

      if (!completed) {
        return false;
      }
    }

    if (
      filed.required &&
      (value === undefined ||
        value === "" ||
        value === null ||
        (value instanceof Array && !value.length))
    ) {
      return false;
    }
  }

  return true;
};

export const areFieldsCompleted = (fields: any[], formData: any) => {
  for (const filed of fields) {
    const value = formData?.[filed.id];

    if (filed.fields) {
      const completed = areFieldsCompleted(filed.fields, formData);

      if (!completed) {
        return false;
      }
    }

    if (
      !filed.excludedFromCompletion &&
      (value === undefined ||
        value === "" ||
        value === null ||
        (value instanceof Array && !value.length))
    ) {
      return false;
    }
  }

  return true;
};

export const fieldsContainError = (formFields: any[]) => {
  let hasError = false;

  for (const field of formFields) {
    if (field.error) {
      hasError = true;
      break;
    }
  }

  return hasError;
};

export const filterEmptyFields = (fields: any[]) =>
  fields.filter(({ type }) => type !== EMPTY);

export const areFieldsInComplete = (formFields: any[], formData: any) => {
  for (const field of filterEmptyFields(formFields)) {
    const parentField = field.groupBy ? formData?.[field.groupBy] : formData;

    const value = parentField?.[field.id];

    if (value !== undefined && value !== null) {
      return false;
    }
  }

  return true;
};

export const getStepsItems = (
  formData: any,
  groupMenuSetting: any[],
  currentTab: string,
  riskScore?: RiskScore,
) => {
  const stepItems: any = [];

  for (const { formConfig: newFormConfig, id, label } of groupMenuSetting) {
    let status;
    let newLabel = label;

    if (formData[id]?.submitted && riskScore?.[id as RiskScoreId]?.rating) {
      status = riskScore?.[id as RiskScoreId]?.rating;
      newLabel = (
        <>
          {newLabel} <br />
          <Typography variant="subtitle1" color="secondary" fontSize={13}>
            {riskScore?.[id as RiskScoreId]?.rating} Risk
          </Typography>
        </>
      );
    } else {
      const allFields =
        newFormConfig?.reduce(
          (acc: any[], current: any) => acc.concat(current.formFields),
          [],
        ) || [];

      if (fieldsContainError(allFields)) {
        status = StepItemStatus.error;
      } else if (areFieldsInComplete(allFields, formData[id])) {
        status = StepItemStatus.InComplete;
      } else if (requiredFieldsCompleted(allFields, formData[id])) {
        status = StepItemStatus.Complete;
      } else {
        status = StepItemStatus.PartialInComplete;
      }
    }

    stepItems.push({
      id,
      label: newLabel,
      status,
      isActive: currentTab === id,
    });
  }

  return stepItems;
};

export const getStepsItemsNoRisk = (
  formData: any,
  groupMenuSetting: any[],
  currentTab = "",
) => {
  const stepItems: StepItem[] = [];

  for (const { formConfig: newFormConfig, id, label } of groupMenuSetting) {
    let status;
    const allFields =
      newFormConfig?.reduce(
        (acc: any[], current: any) => acc.concat(current.formFields),
        [],
      ) || [];

    if (fieldsContainError(allFields)) {
      status = StepItemStatus.error;
    } else if (areFieldsInComplete(allFields, formData[id])) {
      status = StepItemStatus.InComplete;
    } else if (areFieldsCompleted(allFields, formData[id])) {
      status = StepItemStatus.Complete;
    } else {
      status = StepItemStatus.PartialInComplete;
    }
    stepItems.push({
      id,
      label,
      status,
      isActive: currentTab === id,
    });
  }

  return stepItems;
};

export const getLatestDate = (formData: any) =>
  Object.values(formData)
    .filter((item: any) => Boolean(item.lastUpdated))
    .map((item: any) => item.lastUpdated)
    .sort((date1, date2) => +dayjs(date1) - +dayjs(date2))
    .pop();

export const DEFAULT_NPNF_VERSION = "v3";
