import { useMutation } from "react-query";
import { updateTimecodes } from "../../api/browseassets";
import { UpdateTimecodesPayload } from "../../types/types";
import { noop } from "../../utils/appHelper";

interface UpdateTimecodesMutationArgs {
  ccid: string;
  level: "titles" | "series";
  payload: UpdateTimecodesPayload;
}

export function useUpdateTimecodes({
  onSuccess = noop,
  onError = noop,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) {
  return useMutation(
    ({ ccid, level, payload }: UpdateTimecodesMutationArgs) => {
      return updateTimecodes({ ccid, level, payload });
    },
    { onSuccess, onError },
  );
}
